<template>
  <div class="up-button" v-if="isAuthenticated">
    <a href @click.prevent="goUp()">
      <i
        class="fa fa-chevron-up up-icon right-bar-icon"
        :class="{ grayed: !isActive, }"
        title="Haut de la page"
        v-b-tooltip.left
      ></i>
    </a>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: 'UpButton',
  components: {},
  mixins: [],
  data() {
    return {
      pos: 0,
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated
    },
    isActive() {
      return this.pos > 50
    },
  },
  methods: {
    goUp() {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth', })
    },
    handleScroll() {
      this.pos = window.scrollY
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.up-button {
  .up-icon {
    bottom: 20px;
  }
}
</style>
