<template>
  <div class="note-items">
    <ul class="notes">
      <li><span class="empty-text" v-if="items.length === 0">Aucune note</span></li>
      <li
        class="note"
        :class="item.archived ? 'priority-archived' : ('priority-' + item.priority)"
        v-for="item of items" :key="item.id"
      >
        <div class="note-action-buttons">
          <b-row>
            <b-col>
              <span v-if="item.isRecipient">
                <a
                  href
                  @click.prevent="onMarkAsRead(item, item.isUnread)"
                  :title="item.readStatus()"
                  class="note-status"
                  :class="{active: item.isUnread}"
                >
                  <i class="fa fa-check" style="font-size: 8px;" :class="{ 'fa-transparent': item.isUnread }"></i>
                  <i class="fa fa-glasses" style="font-size: 16px;"></i>
                </a>
              </span>
              <span  v-if="item.isRecipient">
                <a
                  href @click.prevent="onPinClicked(item)"
                  class="note-status"
                  :class="{active: item.isPinned}"
                >
                  <i class="fa fa-map-pin"></i>
                </a>
              </span>
            </b-col>
            <b-col class="text-right space-links">
              <a
                href
                @click.prevent="onSendNoteByEmail(item)"
                v-if="!item.archived && sendNoteByEmail && canSendByEmail"
                title="Envoyer par e-mail"
                v-b-tooltip
              >
                <i class="fa fa-paper-plane"></i>
              </a>
              <a
                href
                @click.prevent="onEdit(item)"
                v-if="canEdit(item) && !noEdit"
                title="Modifier"
                v-b-tooltip
              >
                <i class="fa fa-edit"></i>
              </a>
              <a
                href
                @click.prevent="onDeleteClicked(item)"
                v-if="canDelete(item)"
                :title="item.archived ? 'Supprimer définitivement' : 'Archiver'"
                v-b-tooltip
              >
                <i class="fa fa-trash" v-if="!item.archived"></i>
                <i class="fa fa-trash-o" v-else></i>
              </a>
            </b-col>
          </b-row>
        </div>
        <div class="note-block">
          <div class="note-header2" @click.prevent="toggleItem($event, item)">
            <b-row>
              <b-col>
                <div>{{ item.createdOn | dateToString(dateFormat) }}</div>
              </b-col>
              <b-col class="text-right">
                <span>De {{ item.createdBy }}</span>&nbsp;
              </b-col>
            </b-row>
          </div>
          <div class="note-header" @click.prevent="toggleItem($event, item)">
            <b-row>
              <b-col>
                <b>
                  <i class="fa fa-bell" v-if="item.priority === 1"></i>
                  <i class="fa fa-info-circle" v-else-if="item.priority === 3"></i>
                  <i class="fa fa-sticky-note" v-else></i>
                  <span v-if="item.archived">[ARCHIVE]</span>
                  {{ item.title }}
                </b>
              </b-col>
            </b-row>
          </div>
          <div v-if="isExpanded(item)">
            <div class="note-action" v-if="item.page">
              <b-row>
                <b-col>
                  <a href @click.prevent="goToPage(item.page)">
                    <i :class="item.page.icon"></i> {{ item.page.name }}
                  </a>
                </b-col>
              </b-row>
            </div>
            <div class="note-separator" v-if="item.body && item.page"></div>
            <div class="note-body" v-if="item.body">
              <b-row>
                <b-col>
                  <div v-html="item.bodyHtml()"></div>
                </b-col>
              </b-row>
            </div>
            <div v-if="item.replies.length > 0" class="replies">
              <i class="fa fa-reply-all"></i> Réponses
              <div v-for="reply of item.replies" :key="reply.id" class="reply">
                <div class="reply-header">
                  <b-row>
                    <b-col>
                      {{ reply.createdOn | dateToString(dateFormat) }}
                      <span v-if="reply.modifiedOn">
                        modifiée le {{ reply.modifiedOn | dateToString(dateFormat) }}
                      </span>
                    </b-col>
                    <b-col class="text-right">
                      <span>De {{ reply.createdBy }}</span>&nbsp;
                    </b-col>
                  </b-row>
                </div>
                <div v-html="reply.bodyHtml()" class="reply-body"></div>
                <div class="text-right space-links">
                  <a href @click.prevent="editReply(item, reply)" v-if="canEditReply(reply)">
                    <i class="fa fa-edit"></i>
                  </a>
                  <a href @click.prevent="deleteReply(reply)" v-if="canDeleteReply(reply)">
                    <i class="fa fa-trash"></i>
                  </a>
                </div>
              </div>
            </div>
            <div v-if="canReply">
              <a href @click.prevent="replyNote(item)" class="small-text" v-if="selectedNote === null">
                <i class="fa fa-reply"></i> Répondre
              </a>
              <note-reply-editor
                :note="item"
                :reply="selectedReply"
                v-if="isReplyEdition(item)"
                @cancelled="selectedNote = null"
                @saved="onReplySaved"
              ></note-reply-editor>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <confirm-modal
      :name="confirmDeleteReplyModal"
      title="Supprimer une réponse"
      text="Confirmer la suppression de la réponse"
      :object="selectedReply"
      @confirmed="onDeleteReply"
    >
    </confirm-modal>
    <send-note-by-email-modal
      :modal-id="sendByEmailModal"
      :note="sendNote"
      v-if="sendNoteByEmail && canSendByEmail"
    >
    </send-note-by-email-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import NoteReplyEditor from '@/components/Notes/NoteReplyEditor.vue'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'
import SendNoteByEmailModal from '@/components/Notes/SendNoteByEmailModal.vue'
import router from '@/router'
import store from '@/store'
import { makeNote } from '@/types/notes'
import { BackendApi } from '@/utils/http'

export default {
  name: 'note-items',
  components: { SendNoteByEmailModal, ConfirmModal, NoteReplyEditor, },
  mixins: [BackendMixin],
  props: {
    items: Array,
    home: {
      type: Boolean,
      default: false,
    },
    side: {
      type: Boolean,
      default: false,
    },
    viewBody: {
      type: Boolean,
      default: true,
    },
    noEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expandedMap: new Map(),
      dateFormat: 'ddd D MMM YYYY HH:mm',
      selectedNote: null,
      selectedReply: null,
      sendNote: null,
    }
  },
  computed: {
    canReply() {
      return this.hasPerm('notes.add_notereply')
    },
    confirmDeleteReplyModal() {
      if (this.home) {
        return 'delete-note-reply-home'
      }
      if (this.side) {
        return 'delete-note-reply-side'
      }
      return 'delete-note-reply'
    },
    sendByEmailModal() {
      if (this.home) {
        return 'send-note-by-email-home'
      }
      if (this.side) {
        return 'send-note-by-email-side'
      }
      return 'send-note-by-email'
    },
    sendNoteByEmail() {
      return store.getters.config.sendNoteByEmail
    },
    canSendByEmail() {
      return this.hasPerm('notes.view_noteexternalrecipient')
    },
  },
  watch: {
    viewBody: function() {
      this.toggleAll(this.viewBody)
    },
    items: function() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    init() {
      this.toggleAll(this.viewBody)
    },
    canEdit(item) {
      if (!item.archived) {
        return this.hasPerm('notes.change_note')
      } else {
        return false
      }
    },
    canDelete(item) {
      return this.hasPerm('notes.delete_note')
    },
    onDeleteClicked(item) {
      this.$emit('item-delete', item)
    },
    onPinClicked(item) {
      this.$emit('item-pin', item)
    },
    isExpanded(item) {
      return !!this.expandedMap.get(item.id)
    },
    toggleItem(event, item) {
      const isExpanded = this.isExpanded(item)
      if (event.shiftKey) {
        // si shift -> On change toutes les notes
        for (const curItem of this.items) {
          this.expandedMap.set(curItem.id, !isExpanded)
        }
      } else {
        this.expandedMap.set(item.id, !isExpanded)
      }
      this.expandedMap = new Map(this.expandedMap)
    },
    toggleAll(isExpanded) {
      for (const curItem of this.items) {
        this.expandedMap.set(curItem.id, isExpanded)
      }
      this.expandedMap = new Map(this.expandedMap)
    },
    onEdit(item) {
      this.$emit('item-edit', item)
    },
    async onMarkAsRead(item, read) {
      if (item) {
        let url = '/notes/api/notes/' + item.id + '/read/?read=' + (read ? '1' : '0')
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          const status = read ? 'lue' : 'non-lue'
          this.$emit('update', { note: makeNote(resp.data), })
          await this.addSuccess('La note a été marquée comme ' + status)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    replyNote(note) {
      this.selectedNote = note
      this.selectedReply = null
    },
    isReplyEdition(note) {
      return this.selectedNote === note
    },
    onReplySaved(note) {
      this.$emit('update', { note: note, })
      // const index = this.items.map(elt => elt.id).indexOf(note.id)
      // if (index >= 0) {
      //   this.items[index] = note
      // }
      // // this.items = [].concat(this.items)
      // this.selectedNote = null
      // this.selectedReply = null
    },
    canEditReply(reply) {
      return reply.isAuthor || this.hasPerm('notes.change_notereply')
    },
    canDeleteReply(reply) {
      return reply.isAuthor || this.hasPerm('notes.delete_notereply')
    },
    editReply(note, reply) {
      this.selectedNote = note
      this.selectedReply = reply
    },
    deleteReply(reply) {
      this.selectedReply = reply
      this.$bvModal.show('bv-confirm-modal:' + this.confirmDeleteReplyModal)
    },
    async onDeleteReply(event) {
      let url = '/notes/api/delete-reply/' + event.object.id + '/'
      const backendApi = new BackendApi('delete', url)
      try {
        const resp = await backendApi.callApi()
        const note = makeNote(resp.data)
        this.onReplySaved(note)
        await this.addSuccess('La réponse a été supprimée')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onSendNoteByEmail(note) {
      this.sendNote = note
      this.$bvModal.show(this.sendByEmailModal)
    },
    goToPage(page) {
      const result = router.resolve(
        page.path
      )
      if (result.resolved) {
        router.push(result.resolved)
      } else {
        window.location.pathname = page.path
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
ul.notes {
  padding: 0;
  margin: 0;
  list-style: none;
}
ul.notes li.note {
  padding: 1px;
  border: solid 1px #ddd !important;
  background: #eee;
  margin-bottom: 10px;
}
ul.notes li.note .note-block {
  background: #e5faff;
  padding: 0;
  border-radius: 4px;
  font-size: 16px !important;
}
ul.notes li.note .note-block .note-header {
  background: #4d85af;
  color: #fff;
  padding: 4px;
  cursor: pointer;
}
.note-header2 {
  background: #444;
  color: #fff;
  padding: 4px;
  cursor: pointer;
}
ul.notes li.note .note-block .note-header a{
  color: #fff !important;
}
ul.notes li.note.priority-1 .note-block .note-header {
  background: #ef6469;
}
ul.notes li.note.priority-1 .note-block {
  background: #ffcbce;
}
ul.notes li.note.priority-2 .note-block .note-header {
  background: #4d85af;
}
ul.notes li.note.priority-2 .note-block {
  background: #c6e4f1;
}
ul.notes li.note.priority-3 .note-block .note-header {
  background: #a4a6ab;
}
ul.notes li.note.priority-archived .note-block {
  background: #fbffc6;
}
ul.notes li.note.priority-archived .note-block .note-header {
  background: #94945c;
}
ul.notes li.note.priority-3 .note-block {
  background: #d0d0d0;
}
ul.notes li .note-block .note-body {
  padding: 5px;
}
ul.notes li.note .note-action {
  padding: 5px;
}
ul.notes li.note .note-separator {
  border-bottom: solid 1px #ccc;
}
.note-action-buttons a{
  font-size: 15px;
  margin-left: 4px;
}
ul.notes li a {
  color: #222 !important;
}
ul.notes li a.note-status {
  color: #aaa !important;
}
ul.notes li a.note-status.active {
  color: #222 !important;
}
.fa-transparent {
  color: transparent !important;
}
.replies {
  margin: 0 5px;
  font-size: 12px;
  border-top: solid 1px #888;
}
.reply {
  padding: 5px;
  margin: 2px 0 2px 30px;
  border-bottom: solid 1px #bbb;
}
.reply:last-of-type {
  border-bottom: none;
}
.reply-header {
  padding: 1px 5px;
  background: #444;
  color: #fff;
}
.reply {
  opacity: 1;
}
.space-links a {
  margin-left: 3px;
}
</style>
