<template>
  <span class="edit-streets-group" v-if="hasPerm('people.change_streetgroup')">
    <b-modal
      dialog-class="modal-md"
      :id="modalId"
      @ok.prevent="onSave"
      :ok-disabled="formInvalid"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Changer"
      @shown="init()"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-house-chimney"></i>
          Modifier un quartier
        </b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group label="Nouveau nom" label-for="newName">
            <b-input type="text" v-model="newName" id="newName"></b-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeChoice } from '@/types/base'

export default {
  name: 'ModifyStreetsGroupModal',
  components: {},
  mixins: [BackendMixin],
  props: {
    group: Object,
  },
  data() {
    return {
      errorText: '',
      newName: '',
    }
  },
  watch: {
    group: function() {
    },
  },
  mounted() {
  },
  computed: {
    formInvalid() {
      return (this.group === null) || (!this.newName)
    },
    modalId() {
      return 'bv-modal-modify-streets-group'
    },
  },
  methods: {
    ...mapActions(['addSuccess']),
    init() {
      if (this.group) {
        this.newName = this.group.name
      } else {
        this.newName = ''
      }
    },
    async onSave() {
      this.errorText = ''
      if (!this.formInvalid) {
        let url = '/api/people/rename-streets-group/' + this.group.id + '/'
        const backendApi = new BackendApi('post', url)
        try {
          const data = {
            'name': this.newName,
          }
          const resp = await backendApi.callApi(data)
          const text = 'Le quartier a été modifié'
          await this.addSuccess(text)
          this.$bvModal.hide(this.modalId)
          this.$emit('done', { street: makeChoice(resp.data), })
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
  },
}
</script>

<style scoped>
</style>
