<template>
  <span class="payment-modal" v-if="hasPerm('payments.add_payment')">
    <b-modal
      dialog-class="modal-xl"
      :id="modalId"
      cancel-title="Annuler"
      :ok-disabled="isPayDisabled()"
      @ok.prevent="onPay"
      ok-variant="primary"
      ok-title="Payer"
      @shown="init()"
    >
      <template v-slot:modal-title>
        <div class="title-block" v-if="entity">
          <span class="family-numbers">{{ entity.id }}</span> {{ entity.name }}
        </div>
        <div class="title-block"><b>Saisie d'un paiement</b></div>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <loading-gif :loading-name="loadingName"></loading-gif>
      <b-row v-if="!isLoading(loadingName)">
        <b-col lg="3" sm="12">
          <div style="background: #eee; padding: 2px 5px;">
            <div v-if="invoices.length" class="modal-line">
              <div>
                <b>
                  <counter-label :counter="invoices.length" label="facture">
                  </counter-label>
                  : {{ totalInvoicesAmount | currency }}
                </b>
              </div>
              <check-box-select
                :choices="invoices"
                :initial-value="initialInvoices"
                :nameCallback="getInvoiceName"
                :description-callback="getInvoiceDescription"
                :style-callback="getInvoiceStyle"
                @changed="onInvoicesChanged($event)"
                id="invoice-select"
                line-bottom
              >
              </check-box-select>
            </div>
            <div v-else class="modal-line">
              <b>Aucune facture en cours.</b>
              <div class="small-text" style="background: #f0f0f0; padding: 5px; border: solid 1px #eee;">
                Il est tout de même possible de saisir un paiement en avance qui générera un avoir.
              </div>
            </div>
            <div v-if="futureCredit > 0" class="warning-line">
              <i class="fa fa-warning"></i>
              Ce paiement entrainera un crédit de {{ futureCredit | currency }}
              <div class="small-text">
                <b-form-checkbox id="assignOverpaids" v-model="assignOverpaids">
                  Affecter l'analytique
                </b-form-checkbox>
              </div>
            </div>
            <div v-if="assignOverpaids">
              <div v-for="overpaid of overpaids" :key="overpaid.id" class="overpaid-itm">
                <b-form-select
                  class="small-select"
                  v-model="overpaid.analyticAccount"
                >
                  <b-select-option
                    v-for="elt of analyticAccounts"
                    :key="elt.id"
                    :value="elt.id"
                  >
                    {{ elt.getLabel() }}
                  </b-select-option>
                </b-form-select>
                <decimal-input
                  small
                  :id="'ovpd' + overpaid.id"
                  v-model="overpaid.amount"
                >
                </decimal-input>
              </div>
              <a class="small-text" href @click.prevent="onOverpaidAdd" v-if="canAddOverpaid()">
                <i class="fa fa-plus-square"></i>
              </a>
              <div v-if="isExtraOverpaid()" class="warning-text small-text">
                La somme des affectation dépasse le montant de l'avoir
              </div>
              <div v-if="isInfraOverpaid()" class="info-text small-text">
                La somme des affectation est inférieur au montant de l'avoir.
                Le montant restant sera affecté en trop-perçu.
              </div>
            </div>
            <b-form-group
              v-if="selectedInvoices.length"
              id="amount-before-group"
              label="Reste à payer"
              label-for="amount-after"
              description="après ce paiement"
            >
              <b-form-input id="amount-after" :value="remainingAmount | currency" disabled></b-form-input>
            </b-form-group>
            <div class="other-families">
              <a href @click.prevent="toggleOtherEntities" class="small-text">
                <span v-if="!showAddEntities">Ajouter une autre famille</span>
                <span v-else>Cacher</span>
              </a>
              <div v-if="showAddEntities">
                <entity-typeahead
                  @change="onEntityChanged"
                ></entity-typeahead>
              </div>
            </div>
          </div>
        </b-col>
        <b-col lg="9" sm="12">
          <div class="payment-section" v-if="selectedInvoices.length">
            <b-row>
              <b-col>
                <b>
                  <counter-label
                    :counter="selectedInvoices.length"
                    label="facture sélectionnée"
                    label-n="factures sélectionnées"
                  >
                  </counter-label>
                  : {{ invoicesAmount | currency }}
                </b>
                <div class="help-text-ana" v-if="analytics.length > 1">
                  Cliquez sur un élément pour le payer en priorité. (La priorité 1 est la plus prioritaire)
                </div>
                <div>
                  <analytics-priority-selector
                    :analytics="analytics"
                    @priorities="onAnalyticsPriorities"
                  >
                  </analytics-priority-selector>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="payment-section" v-if="allCredits.length && selectedInvoices.length">
            <b-row>
              <b-col>
                <div v-if="credits.length">
                  <b><counter-label
                      :counter="credits.length"
                      label="avoir disponible"
                      label-n="avoirs disponibles">
                    </counter-label>
                    : {{ creditsAmount | currency }}
                  </b>
                </div>
                <div v-if="rewards.length">
                  <b>
                    <counter-label
                      :counter="rewards.length"
                      label="avoir de chantier disponible"
                      label-n="avoirs de chantiers disponibles">
                    </counter-label>
                    : {{ rewardsAmount | currency }}
                  </b>
                </div>
              </b-col>
              <b-col cols="1">
                <a href="" @click.prevent="showCredits = !showCredits">
                  <span v-if="showCredits">Cacher</span><span v-else>Voir</span>
                </a>
              </b-col>
              <b-col cols="2" class="text-right">
                <b>Avoirs pris:</b>
              </b-col>
              <b-col cols="2">
                <decimal-input
                  id="takenCredit"
                  v-model="takenCredit"
                  :max="maxCredit"
                  :disabled="selectedCredits.length === 0"
                >
                </decimal-input>
              </b-col>
            </b-row>
            <div v-if="showCredits">
              <div v-for="credit of allCredits" :key="credit.id" class="field-line">
                <b-row>
                  <b-col cols="9">
                    <b-form-checkbox
                      :id="'credit-' + credit.id"
                      :name="'credit-' + credit.id"
                      :checked="isCreditSelected(credit)"
                      @change="onSelectCredit(credit)"
                    >
                      {{ getCreditName(credit)}}
                    </b-form-checkbox>
                    <div v-if="credit.comments" class="small-text">
                      {{ credit.comments }}
                    </div>
                  </b-col>
                  <b-col cols="3">
                    <analytics-detail-view
                      :analytics="credit.fromAnalytics"
                      from
                      label="Source"
                    >
                    </analytics-detail-view>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <div class="payment-section">
            <b-row>
              <b-col>
                <b>
                  <counter-label :counter="payments.length" label="paiement"></counter-label>
                  : {{ amount | currency }}
                </b>
                <div v-if="analyticsPriorities.length && (payments.length > 1) && (rawMinimalFirstAmount > 0)">
                  <b-checkbox
                    id="prio-on-first"
                    v-model="prioOnFirst"
                  >
                    Analytiques prioritaires sur le 1er paiement
                  </b-checkbox>
                </div>
              </b-col>
              <b-col cols="1">
                <a
                  href
                  @click.prevent="showComments = !showComments"
                  v-b-tooltip="'Commentaires et émetteurs'"
                  class="show-comments btn btn-secondary"
                  :class="showComments ? 'active' : ''"
                >
                  <i class="fa fa-comments"></i>
                </a>
              </b-col>
              <b-col cols="2">
                <b-form-group
                  id="payments-count-group"
                  label-for="payments-count"
                  description="Nb de paiements"
                  style="font-size: 12px;"
                >
                  <b-form-input type="number" min="0" step="1" id="payments-count" v-model="paymentsCount">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <div v-if="payments.length === 0" class="payment-box">
              <b-row>
                <b-col><h6>Aucun paiement</h6></b-col>
                <b-col cols="3" class="text-right">
                  <a class="btn btn-secondary" href @click.prevent="paymentsCount = 1">
                    <i class="fa fa-plus"></i> Ajouter
                  </a>
                </b-col>
              </b-row>
            </div>
            <div class="payment-box" v-for="(payment, index) of payments" :key="payment.id">
              <b-row>
                <b-col lg="4" xl="2">
                  <b-form-group
                    :id="'payment-modes-group' + payment.id"
                    :label-for="'payment-modes' + payment.id"
                    description="Mode de paiement"
                  >
                    <b-form-select
                      :id="'payment-modes' + payment.id"
                      v-model="payment.paymentMode"
                      @change="paymentModeChanged(payment)"
                      required
                      class="small-select"
                    >
                      <b-form-select-option
                        :value="item"
                        v-for="item in paymentModes"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                  <div
                    style="margin-top: -10px"
                    v-if="index === 0 && payment.paymentMode && payment.paymentMode.id && payments.length"
                  >
                    <a class="small2" href @click.prevent="copyPaymentModes()">
                      Dupliquer
                    </a>
                  </div>
                </b-col>
                <b-col lg="4" xl="2">
                  <b-form-group
                    :id="'payment-date-group' + payment.id"
                    :label-for="'payment-date' + payment.id"
                    :description="'Date de retrait - ' + (-payment.id)"
                  >
                    <b-form-input
                      type="date"
                      class="small-input"
                      :id="'payment-date' + payment.id"
                      v-model="payment.paymentDate"
                    >
                    </b-form-input>
                    <div v-if="!payment.paymentDate" class="error-field">
                      La date est manquante
                    </div>
                    <div v-else-if="!checkPaymentDate(payment.paymentDate)" class="error-field">
                      Cette date semble invalide
                    </div>
                    <div v-else-if="!checkFuturePaymentDate(payment)" class="error-field">
                      Ce mode de paiement n'autorise pas la saisie d'une date future.
                    </div>
                    <div v-else-if="getPastPaymentDateWarning(payment.paymentDate)" class="warning-field">
                      {{ getPastPaymentDateWarning(payment.paymentDate) }}
                    </div>
                  </b-form-group>
                  <div style="margin-top: -10px">
                    <a class="small2" href @click.prevent="oneMonthLater(index)">
                      Un mois plus tard
                    </a>
                  </div>
                </b-col>
                <b-col lg="4" xl="2">
                  <b-form-group
                    :id="'amount-group' + payment.id"
                    :label-for="'amount' + payment.id"
                    :description="amountDescription(index)"
                    required
                  >
                    <decimal-input
                      small
                      :id="'amount' + payment.id"
                      :min="(index === 0) ? minimalFirstAmount : 0"
                      v-model="payment.amount"
                    ></decimal-input>
                  </b-form-group>
                </b-col>
                <b-col xl="6" lg="12">
                  <b-row>
                    <b-col cols="6">
                      <b-form-group
                        :id="'bank-group' + payment.id"
                        description="Banque"
                        :label-for="'bank-name' + payment.id"
                      >
                        <vue-bootstrap-typeahead
                          :id="'bank-name' + payment.id"
                          v-model="payment.bankName"
                          :data="filteredBanks(payment)"
                          :disabled="!payment.paymentMode.bank"
                          :required="payment.paymentMode.bank"
                          :ref="'bankNameTypeAhead' + payment.id"
                          @hit="onBankSelected"
                          :minMatchingChars="2"
                          :disableFiltering="true"
                          input-class="small-input"
                        >
                        </vue-bootstrap-typeahead>
                      </b-form-group>
                      <div v-if="allowMoreBanks" style="margin-top: -20px;">
                        <a href @click.prevent="showMoreBanks" class="small2">
                          Voir plus de banques
                        </a>
                      </div>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        :id="'bank-number-group' + payment.id"
                        :description="'Numéro' + (payment.paymentMode.isNumberRequired ? ' (obligatoire)' : '')"
                        :label-for="'bank-number' + payment.id"
                      >
                        <b-form-input
                          :id="'bank-number' + payment.id"
                          v-model="payment.bankNumber"
                          :required="payment.paymentMode.isNumberRequired"
                          :disabled="!payment.paymentMode.bank && !payment.paymentMode.isNumberRequired"
                          class="small-input"
                        >
                        </b-form-input>
                      </b-form-group>
                      <div style="margin-top: -10px" v-if="isNextNumberActive(index)">
                        <a class="small2" href @click.prevent="nextNumber(index)">
                          N° suivant
                        </a>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-show="showComments">
                <b-col>
                  <b-form-group
                    :id="'emitter-group' + payment.id"
                    description="Émetteur (si différent de la famille)"
                    :label-for="'emitter' + payment.id"
                    :disabled="!payment.paymentMode.showEmitter"
                  >
                    <b-form-input :id="'emitter' + payment.id" v-model="payment.emitter"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :id="'comments-group' + payment.id"
                    description="Commentaires"
                    :label-for="'comments' + payment.id"
                  >
                    <b-form-input :id="'comments' + payment.id" v-model="payment.comments"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import LoadingGif from '@/components/Controls/LoadingGif'
import { currency, dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import {
  makePayment, makePaymentMode, makeInvoice, makeCredit, sumPayments, makeAnalyticDetail, makeAnalyticAccount
} from '@/types/payments'
import { BackendApi } from '@/utils/http'
import { sum } from '@/utils/math'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import AnalyticsPrioritySelector from '@/components/Accounting/AnalyticsPrioritySelector.vue'
import EntityTypeahead from '@/components/Entity/EntityTypeahead.vue'
import AnalyticsDetailView from '@/components/Accounting/AnalyticsDetailView.vue'
import { compareNumbers } from '@/utils/sorting'

export default {
  name: 'payment-button',
  components: { AnalyticsDetailView, EntityTypeahead, CounterLabel, LoadingGif, CheckBoxSelect, DecimalInput, AnalyticsPrioritySelector, },
  mixins: [BackendMixin],
  props: {
    modalId: String,
    invoice: Object,
    entity: Object,
  },
  data() {
    return {
      banks: [],
      allBanks: [],
      allowMoreBanks: false,
      invoices: [],
      credits: [],
      rewards: [],
      allCredits: [],
      initialInvoices: [],
      selectedInvoices: [],
      paymentModes: [],
      initialCredits: [],
      selectedCredits: [],
      errorText: '',
      invoicesRemaining: new Map(),
      loadingName: 'payment-button',
      paymentsCount: 1,
      payments: [makePayment({ id: -1, payment_date: moment().format('YYYY-MM-DD'), })],
      showComments: false,
      maxCredit: 0,
      takenCredit: 0,
      payLoadingName: 'pay-button',
      analyticsMap: new Map(),
      analyticsPriorities: [],
      prioOnFirst: false,
      minimalFirstAmount: 0,
      rawMinimalFirstAmount: 0,
      showAddEntities: false,
      extraEntities: [],
      showCredits: false,
      assignOverpaids: false,
      overpaids: [],
      creditSelection: new Map(),
      analyticAccounts: [],
    }
  },
  computed: {
    amount() {
      return sumPayments(this.payments)
    },
    fullAmount() {
      let fullAmount = +this.amount + this.takenCredit
      return Math.round(fullAmount * 100) / 100
    },
    futureCredit() {
      return Math.round((this.fullAmount - this.invoicesAmount) * 100) / 100
    },
    analytics() {
      let array = []
      for (const ana of this.analyticsMap.values()) {
        array = array.concat(ana)
      }
      return array
    },
    totalInvoicesAmount() {
      let amount = 0
      for (const invoice of this.invoices) {
        amount += invoice.toBePaidPrice()
      }
      return amount
    },
    invoicesAmount() {
      let amount = 0
      for (const invoice of this.selectedInvoices) {
        amount += invoice.toBePaidPrice()
      }
      return amount
    },
    remainingAmount() {
      const remainingAmount = this.invoicesAmount - this.fullAmount
      return Math.max(remainingAmount, 0)
    },
    creditsAmount() {
      return sum(this.credits.map(elt => +elt.remainingAmount))
    },
    rewardsAmount() {
      return sum(this.rewards.map(elt => +elt.remainingAmount))
    },
    allCreditsAmount() {
      return sum(this.allCredits.map(elt => +elt.remainingAmount))
    },
    dontUseCreditsByDefault() {
      return store.getters.config.dontUseCreditsByDefault
    },
  },
  watch: {
    invoice: function() {},
    entity: function() {},
    amount() {
      this.calculateRemainingByInvoice()
    },
    assignOverpaids: function() {
      this.overpaids = []
      if (this.assignOverpaids) {
        this.loadAnalyticAccounts()
        this.addOverpaidLine(this.futureCredit)
      }
    },
    futureCredit: function() {
      this.overpaids = []
      this.assignOverpaids = false
    },
    prioOnFirst() {
      this.recalculateAmount()
    },
    paymentsCount: function() {
      if (this.paymentsCount === 0) {
        this.payments = []
      } else {
        if (this.paymentsCount > this.payments.length) {
          for (let index = this.payments.length; index < this.paymentsCount; index++) {
            const id = index + 1
            const jsonData = { id: -id, }
            this.payments.push(makePayment(jsonData))
          }
        } else if (this.paymentsCount < this.payments.length) {
          this.payments = this.payments.slice(0, this.paymentsCount)
        }
      }
      this.recalculateAmount()
    },
    selectedCredits() {
      this.calculateCreditAmount()
    },
    invoicesAmount() {
      this.calculateCreditAmount()
    },
    takenCredit() {
      this.recalculateAmount()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    showModal() {
      this.init()
    },
    async init() {
      this.startLoading(this.loadingName)
      await this.loadCurrentInvoices()
      this.allCredits = []
      await this.loadCredits()
      await this.loadRewards()
      await this.loadPaymentModes()
      this.paymentsCount = 1
      this.payments = [makePayment({ id: -1, })]
      this.calculateInitialAmount()
      this.onCreditsChanged()
      this.endLoading(this.loadingName)
    },
    calculateCreditAmount() {
      const takenCredit = Math.max(0, Math.min(this.getCreditsAmount(), this.invoicesAmount))
      this.takenCredit = Math.round(takenCredit * 100) / 100
      this.maxCredit = this.takenCredit
      if (this.maxCredit >= +this.invoicesAmount) {
        this.payments = []
        this.paymentsCount = 0
      }
    },
    getCreditsAmount() {
      let amount = 0
      for (let credit of this.selectedCredits) {
        amount += credit.remainingAmount
      }
      return amount
    },
    isSelected(invoice) {
      return this.selectedInvoices.map(elt => elt.id).indexOf(invoice.id) >= 0
    },
    isLastSelected(invoice) {
      return this.selectedInvoices.map(elt => elt.id).indexOf(invoice.id) === (this.selectedInvoices.length - 1)
    },
    getInvoiceName(invoice) {
      return invoice.getName()
    },
    getInvoiceDescription(invoice) {
      let text = ''
      if (invoice.entity.id !== this.entity.id) {
        text = ' - ' + invoice.entity.name
      }
      if (this.isSelected(invoice)) {
        let remaining = this.invoicesRemaining.get(invoice.id)
        remaining = Math.round(remaining * 100) / 100
        if (remaining) {
          text = ' - Solde après paiement: ' + currency(remaining)
        } else {
          text = ' - Payée en totalité'
        }
      }
      return dateToString(invoice.createdOn) + text
    },
    getInvoiceStyle(invoice) {
      const style = {
        padding: '2px',
      }
      if (invoice.entity.id !== this.entity.id) {
        style.background = '#bf9e9e'
      }
      if (this.isSelected(invoice)) {
        let remaining = this.invoicesRemaining.get(invoice.id)
        remaining = Math.round(remaining * 100) / 100
        if (remaining) {
          if (remaining === invoice.toBePaidPrice()) {
            // not paid
            style.color = '#888'
            if (invoice.entity.id !== this.entity.id) {
              style.color = '#ddd'
            }
          } else {
            style.color = '#951818'
          }
        } else {
          style.color = '#000'
        }
      } else {
        style.color = '#aaa'
        if (invoice.entity.id !== this.entity.id) {
          style.color = '#eee'
        }
      }
      return style
    },
    getCreditName(credit) {
      const isReward = this.rewards.map(elt => elt.id).indexOf(credit.id) >= 0
      const label = isReward ? 'Chantier' : 'Avoir'
      return label + ' de ' + currency(credit.remainingAmount) + ' du ' + dateToString(credit.createdOn, 'll')
    },
    getCreditDescription(credit) {
      return credit.comments
    },
    isCreditSelected(credit) {
      return !!this.creditSelection.get(credit.id)
    },
    onSelectCredit(credit) {
      if (this.isCreditSelected(credit)) {
        this.creditSelection.set(credit.id, false)
      } else {
        this.creditSelection.set(credit.id, true)
      }
      this.creditSelection = new Map(this.creditSelection)
      this.onCreditsChanged()
    },
    async paymentModeChanged(payment) {
      if (payment.paymentMode.noDefaultDate) {
        payment.paymentDate = null
      } else {
        if (!payment.paymentDate) {
          payment.paymentDate = moment().format('YYYY-MM-DD')
        }
      }
      if (!payment.paymentMode.showEmitter) {
        payment.emitter = ''
      }
      if (!payment.paymentMode.bank) {
        payment.bankName = ''
      }
      if (!payment.paymentMode.bank && !payment.paymentMode.isNumberRequired) {
        payment.bankNumber = ''
      }
      await this.loadBanks(payment.paymentMode)
      const that = this
      if (this.banks.length === 1) {
        payment.bankName = this.banks[0]
      }
      setTimeout(
        () => {
          const refName = 'bankNameTypeAhead' + payment.id
          that.$refs[refName][0].inputValue = payment.bankName
        }, 100
      )
    },
    isPayDisabled() {
      if (this.isLoading(this.payLoadingName)) {
        return true
      }
      if (this.isExtraOverpaid()) {
        return false
      }
      if (this.payments.length === 0) {
        return this.selectedCredits.length === 0
      }
      for (const payment of this.payments) {
        if (!payment.isValid()) {
          return true
        }
      }
      return false
    },
    async onPay() {
      this.errorText = ''
      if (!this.isLoading(this.payLoadingName)) {
        this.startLoading(this.payLoadingName)
        try {
          const url = '/api/entity/' + this.entity.id + '/create-payment/'
          const backendApi = new BackendApi('post', url)
          const payments = []
          for (let payment of this.payments) {
            payments.push(
              {
                amount: Math.round(payment.amount * 100) / 100,
                payment_mode: payment.paymentMode.id ? payment.paymentMode.id : null,
                emitter: payment.emitter,
                comments: payment.comments,
                bank_name: payment.bankName,
                bank_number: payment.bankNumber,
                payment_date: '' + payment.paymentDate + 'T00:00',
              }
            )
          }
          const data = {
            invoices: this.selectedInvoices.map(elt => elt.id),
            payments: payments,
            credits: this.selectedCredits.map(elt => elt.id),
            entities: this.extraEntities.map(elt => elt.id),
          }
          if (this.takenCredit !== this.maxCredit) {
            data.taken_credit = this.takenCredit
          }
          if (this.assignOverpaids) {
            data.overpaids = this.overpaids.map(
              line => {
                return {
                  amount: +line.amount,
                  analytic_account: line.analyticAccount,
                }
              }
            )
          }
          if (this.analyticsPriorities.length) {
            data.analytic_priorities = []
            for (const prio of this.analyticsPriorities) {
              data.analytic_priorities = data.analytic_priorities.concat(
                this.selectedInvoices.map(
                  invoice => {
                    return {
                      'invoice': invoice.id,
                      'school_year': prio.schoolYear.id,
                      'analytic_account': prio.analyticAccount ? prio.analyticAccount.id : 0,
                      'general_account': prio.generalAccount ? prio.generalAccount.id : 0,
                    }
                  }
                )
              )
            }
          }
          const resp = await backendApi.callApi(data)
          const invoices = resp.data.invoices.map(makeInvoice)
          this.$bvModal.hide(this.modalId)
          this.$emit('paid', { invoices: invoices, })
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
        this.endLoading(this.payLoadingName)
      }
    },
    async loadPaymentModes() {
      try {
        let url = '/api/payment-modes/?entity=' + this.entity.id
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.paymentModes = [makePaymentMode()].concat(resp.data.map(elt => makePaymentMode(elt)))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadCredits() {
      if (this.entity && this.entity.id) {
        try {
          let url = '/api/entity/' + this.entity.id + '/credits/?asc=1&all=1'
          let backendApi = new BackendApi('get', url)
          let resp = await backendApi.callApi()
          this.credits = resp.data.map(elt => makeCredit(elt)).filter(
            elt => elt.remainingAmount > 0
          )
          this.allCredits = this.allCredits.concat(this.credits)
          const creditSelection = new Map()
          if (!this.dontUseCreditsByDefault) {
            for (const credit of this.credits) {
              creditSelection.set(credit.id, true)
            }
          }
          this.creditSelection = creditSelection
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadRewards() {
      if (this.entity && this.entity.id) {
        try {
          let url = '/api/entity/' + this.entity.id + '/credits/?rewards=1&asc=1&all=1'
          let backendApi = new BackendApi('get', url)
          let resp = await backendApi.callApi()
          this.rewards = resp.data.map(elt => makeCredit(elt)).filter(
            elt => elt.remainingAmount > 0
          )
          this.allCredits = this.allCredits.concat(this.rewards)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadCurrentInvoices() {
      if (this.entity && this.entity.id) {
        this.initialInvoices = []
        try {
          let url = '/api/entity/' + this.entity.id + '/invoices/?only_unpaid=1'
          let backendApi = new BackendApi('get', url)
          let resp = await backendApi.callApi()
          this.invoices = resp.data.map(elt => makeInvoice(elt)).filter(elt => !elt.isCancelled)
          for (const invoice of this.invoices) {
            invoice.entity = this.entity
          }
          if (this.invoice) {
            for (const invoice of this.invoices) {
              if (this.invoice.id === invoice.id) {
                this.initialInvoices.push(invoice)
              }
            }
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.selectedInvoices = this.initialInvoices
        this.recalculateAmount()
        await this.loadAnalytics()
      }
    },
    async loadExtraInvoices(entity) {
      if (entity && entity.id) {
        try {
          let url = '/api/entity/' + entity.id + '/invoices/?only_unpaid=1'
          let backendApi = new BackendApi('get', url)
          let resp = await backendApi.callApi()
          const extraInvoices = resp.data.map(elt => makeInvoice(elt)).filter(elt => !elt.isCancelled)
          for (const invoice of extraInvoices) {
            invoice.entity = entity
          }
          this.invoices = this.invoices.concat(extraInvoices)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.recalculateAmount()
        await this.loadAnalytics()
      }
    },
    async loadBanks(paymentMode) {
      this.banks = []
      if (this.entity && paymentMode.id && paymentMode.bank) {
        try {
          let url = '/api/banks-list/' + this.entity.id + '/' + paymentMode.id + '/'
          let backendApi = new BackendApi('get', url)
          let resp = await backendApi.callApi()
          this.banks = resp.data['entity_banks']
          this.allBanks = resp.data['all_banks']
          this.allowMoreBanks = this.allBanks.length > 0
          if (this.banks.length === 0) {
            this.banks = this.allBanks
            this.allowMoreBanks = false
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadAnalytics() {
      const analyticsMap = new Map()
      if (this.selectedInvoices.length) {
        try {
          const url = '/api/entity/' + this.entity.id + '/payment-analytics/'
          const backendApi = new BackendApi('post', url)
          const data = {
            invoices: this.selectedInvoices.map(elt => elt.id),
            entities: this.extraEntities.map(elt => elt.id),
          }
          const resp = await backendApi.callApi(data)
          for (const elt of resp.data) {
            let analytics = []
            if (analyticsMap.has(elt.invoice)) {
              analytics = this.analyticsMap.get(elt.invoice)
            }
            analytics = analytics.concat(elt.detail.map(ana => makeAnalyticDetail(ana)))
            analyticsMap.set(elt.invoice, analytics)
          }
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
        this.analyticsMap = analyticsMap
      }
    },
    onBankSelected(evt) {},
    onInvoicesChanged(evt) {
      this.selectedInvoices = [].concat(evt.choices)
      this.calculateInitialAmount()
      this.recalculateAmount()
      this.loadAnalytics()
    },
    splitAmount(amount) {
      if (this.paymentsCount && this.payments.length) {
        let splitedAmount = amount
        if (this.minimalFirstAmount) {
          splitedAmount = Math.max(0, splitedAmount - this.minimalFirstAmount)
        }
        const stepAmount = Math.round((splitedAmount / this.payments.length) * 100) / 100
        let amountsSum = 0
        for (let index = 0; index < this.payments.length; index++) {
          const isFirst = index === 0
          const isLast = index === (this.payments.length - 1)
          if (isLast) {
            this.payments[index].amount = amount - amountsSum
            this.payments[index].amount = Math.round(this.payments[index].amount * 100) / 100
          } else {
            this.payments[index].amount = stepAmount
            if (isFirst) {
              this.payments[index].amount += this.minimalFirstAmount
            }
            this.payments[index].amount = Math.round(this.payments[index].amount * 100) / 100
            amountsSum += this.payments[index].amount
          }
        }
        this.payments = this.payments.concat([])
      }
    },
    calculateInitialAmount() {
      this.initialCredist = []
      this.selectedCredits = []
      let totalAmount = this.invoicesAmount
      let totalCreditAmount = 0
      for (let credit of this.credits) {
        if ((totalCreditAmount + credit.remainingAmount) <= totalAmount) {
          this.selectedCredits.push(credit)
          this.initialCredits.push(credit)
          totalCreditAmount += credit.remainingAmount
        }
      }
      const amount = Math.round((totalAmount - totalCreditAmount) * 100) / 100
      if (amount === 0) {
        this.paymentsCount = 0
      }
      this.splitAmount(amount)
    },
    getInvoiceAnalyticsInOrder() {
      let analytics = []
      for (const invoice of this.selectedInvoices) {
        let invoiceAnalytics = this.analyticsMap.get(invoice.id) || []
        for (const ana of invoiceAnalytics) {
          analytics.push(
            {
              invoice: invoice,
              analytic: ana,
            }
          )
        }
      }
      let analyticsOrder = this.analyticsPriorities.map(elt => elt.getKey())
      let invoicesOrder = this.selectedInvoices.map(elt => elt.id)
      return analytics.sort(
        (elt1, elt2) => {
          let anaIndex1 = analyticsOrder.indexOf(elt1.analytic.getKey())
          let anaIndex2 = analyticsOrder.indexOf(elt2.analytic.getKey())
          if (anaIndex1 === anaIndex2) {
            const invIndex1 = invoicesOrder.indexOf(elt1.invoice.id)
            const invIndex2 = invoicesOrder.indexOf(elt2.invoice.id)
            return compareNumbers(invIndex1, invIndex2)
          } else {
            if (anaIndex1 === -1) {
              return 1
            } else if (anaIndex2 === -1) {
              return -1
            } else {
              return compareNumbers(anaIndex1, anaIndex2)
            }
          }
        }
      )
    },
    calculateRemainingByInvoice() {
      let amount = Math.round((this.amount) * 100) / 100
      let fullAmount = this.takenCredit + amount
      const invoicesRemaining = new Map() // reste à payer après paiement pour chaque facture
      const toBePaidPrice = new Map()
      for (const invoice of this.selectedInvoices) {
        toBePaidPrice.set(invoice.id, invoice.toBePaidPrice())
      }
      const items = this.getInvoiceAnalyticsInOrder()
      for (const item of items) {
        let toBePaid = toBePaidPrice.get(item.invoice.id)
        if (item.amount < toBePaid) {
          toBePaid = item.analytic.amount
        }
        // Calcul du reste à payer de chaque facture
        if (fullAmount > 0) {
          if (fullAmount >= toBePaid) {
            invoicesRemaining.set(item.invoice.id, 0)
            fullAmount -= toBePaid
            toBePaid = 0
          } else {
            toBePaid = toBePaid - fullAmount
            invoicesRemaining.set(item.invoice.id, toBePaid)
            fullAmount = 0
          }
          toBePaidPrice.set(item.invoice.id, toBePaid)
        } else {
          invoicesRemaining.set(item.invoice.id, toBePaid)
        }
      }
      this.invoicesRemaining = invoicesRemaining
    },
    recalculateAmount() {
      let totalAmount = this.invoicesAmount
      let totalCreditAmount = this.takenCredit
      let amount = 0
      if (totalCreditAmount < totalAmount) {
        amount = totalAmount - totalCreditAmount
      }
      this.rawMinimalFirstAmount = Math.max(0, sum(
        this.analyticsPriorities.map(elt => elt.amount)
      ) - this.takenCredit)
      if (this.prioOnFirst) {
        this.minimalFirstAmount = this.rawMinimalFirstAmount
      } else {
        this.minimalFirstAmount = 0
      }
      this.splitAmount(amount)
    },
    onCreditsChanged() {
      const selectedCredits = []
      for (const credit of this.allCredits) {
        if (this.isCreditSelected(credit)) {
          selectedCredits.push(credit)
        }
      }
      this.selectedCredits = selectedCredits
      this.recalculateAmount()
      this.payments = this.payments.filter(elt => elt.amount).concat([])
      this.paymentsCount = this.payments.length
    },
    showMoreBanks() {
      this.banks = this.allBanks
      this.allowMoreBanks = false
    },
    amountDescription(index) {
      let text = 'Montant'
      if ((index === 0) && this.minimalFirstAmount) {
        text += ' mini: ' + currency(this.minimalFirstAmount)
      }
      return text
    },
    addOverpaidLine(amount) {
      this.overpaids = this.overpaids.concat(
        {
          id: this.overpaids.length,
          analyticAccount: 0,
          amount: amount,
        }
      )
    },
    onAnalyticsPriorities(event) {
      this.analyticsPriorities = event.priorities
      this.calculateRemainingByInvoice()
    },
    oneMonthLater(index) {
      if (this.isOneMonthLaterActive(index)) {
        let value = this.payments[index].paymentDate
        if (!value && (index > 0)) {
          value = this.payments[index - 1].paymentDate
        }
        if (value) {
          this.payments[index].paymentDate = moment(value).add(
            1, 'month'
          ).format('YYYY-MM-DD')
        }
      }
    },
    isOneMonthLaterActive(index) {
      if (index === 0) {
        return !!(this.payments[0].paymentDate)
      }
      if ((index > 0) && (index < this.payments.length)) {
        return !!(
          (this.payments[index - 1].paymentDate) ||
          (this.payments[index].paymentDate)
        )
      }
      return false
    },
    nextNumber(index) {
      if (this.isNextNumberActive(index)) {
        let value = this.payments[index].bankNumber
        if (!value && (index > 0)) {
          value = this.payments[index - 1].bankNumber
        }
        if (value) {
          this.payments[index].bankNumber = '' + (+value + 1)
        }
      }
    },
    isNextNumberActive(index) {
      if ((index >= 0) && (index < this.payments.length)) {
        if (this.payments[index].paymentMode && this.payments[index].paymentMode.bank) {
          if (index === 0) {
            return !!(this.payments[0].bankNumber)
          } else {
            return !!(
              (this.payments[index - 1].bankNumber) ||
              (this.payments[index].bankNumber)
            )
          }
        }
      }
      return false
    },
    filteredBanks(payment) {
      const curName = payment.bankName.toLowerCase()
      return this.banks.filter(elt => elt.toLowerCase().indexOf(curName) >= 0)
    },
    copyPaymentModes() {
      if (this.payments.length > 1) {
        const paymentMode = this.payments[0].paymentMode
        const bankName = this.payments[0].bankName
        const that = this
        for (const payment of this.payments) {
          payment.paymentMode = paymentMode
          payment.bankName = bankName
          setTimeout(
            () => {
              const refName = 'bankNameTypeAhead' + payment.id
              that.$refs[refName][0].inputValue = payment.bankName
            }, 100
          )
        }
      }
    },
    toggleOtherEntities() {
      this.showAddEntities = !this.showAddEntities
    },
    onEntityChanged(event) {
      if (event.entity) {
        this.extraEntities.push(event.entity)
        this.loadExtraInvoices(event.entity)
      }
    },
    checkPaymentDate(paymentDate) {
      let diff = moment().diff(moment(paymentDate), 'years')
      return Math.abs(diff) <= 2
    },
    checkFuturePaymentDate(payment) {
      if (payment.paymentMode && payment.paymentMode.refuseFuturePayments) {
        if (payment.paymentDate) {
          const today = moment().format('YYYY-MM-DD')
          let diff = moment(today).diff(moment(payment.paymentDate), 'days')
          return (diff >= 0)
        }
      }
      return true
    },
    getPastPaymentDateWarning(paymentDate) {
      const today = moment().format('YYYY-MM-DD')
      let diff = moment(today).diff(moment(paymentDate), 'days')
      if (diff > 0) {
        const text = (diff === 1) ? '1 jour' : ('' + diff + ' jours')
        return 'La date est passée de ' + text
      }
      return ''
    },
    async loadAnalyticAccounts() {
      if (this.analyticAccounts.length === 0) {
        let url = '/api/analytic-accounts/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.analyticAccounts = [makeAnalyticAccount()].concat(
            resp.data.map(
              elt => makeAnalyticAccount(elt)
            )
          )
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
    isExtraOverpaid() {
      if (this.assignOverpaids) {
        const existing = sum(this.overpaids.map(elt => +elt.amount))
        return (this.futureCredit < existing)
      }
      return false
    },
    isInfraOverpaid() {
      const existing = sum(this.overpaids.map(elt => +elt.amount))
      return (this.futureCredit > existing)
    },
    onOverpaidAdd() {
      const existing = sum(this.overpaids.map(elt => +elt.amount))
      const newValue = +this.futureCredit - existing
      if (newValue > 0) {
        this.addOverpaidLine(newValue)
      }
    },
    canAddOverpaid() {
      const existing = sum(this.overpaids.map(elt => +elt.amount))
      const newValue = +this.futureCredit - existing
      return (newValue > 0)
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
  .payment-button a {
    display: block;
  }
  .modal-line {
    padding-bottom: 5px;
    border-bottom: solid 1px #f0f0f0;
    margin-bottom: 5px;
  }
  .modal-line:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
  .error-field {
    padding: 2px;
    color: #cc3700;
  }
  .warning-field {
    padding: 2px;
    color: #222;
  }
  .payment-box {
    margin-bottom: 10px;
    border: solid 1px #ccc;
    border-radius: 8px;
    padding: 5px;
    font-size: 12px;
  }
  .payment-box input, .payment-box select {
    font-size: 13px;
  }
  a.show-comments, a.show-comments:focus {
    color: #888 !important;
  }
  a.show-comments.active, a.show-comments.active:focus {
    color: #000 !important;
  }
  .payment-section {
    padding: 5px;
    background: #f0f0f0;
    margin-bottom: 10px;
  }
  .help-text-ana {
    font-size: 12px;
  }
  .title-block {
    display: inline-block;
    padding-right: 20px;
  }
  .overpaid-itm {
    padding: 2px;
    border: solid 1px #444;
    margin-bottom: 2px;
  }
</style>
