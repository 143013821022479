<template>
  <div class="add-external-recipient-modal" v-if="canView">
    <b-modal
      :id="modalId"
      cancel-title="Annuler"
      :ok-disabled="!isValid"
      @ok="onAdd"
      ok-variant="primary"
      ok-title="Ajouter"
      size="md"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fa fa-plus"></i>
          Ajouter un destinataires externes
        </b>
      </template>
      <div class="form-group">
        <b-row>
          <b-col>
            <b-form-group label-for="email" label="E-mail">
              <b-form-input type="email" v-model="email"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { isEmailValid } from '@/utils/check'

export default {
  name: 'add-external-recipient-modal',
  components: {},
  mixins: [BackendMixin],
  props: {
    modalId: String,
  },
  data() {
    return {
      email: '',
    }
  },
  computed: {
    canView() {
      return this.hasPerm('notes.add_noteexternalrecipient')
    },
    isValid() {
      return isEmailValid(this.email)
    },
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    async onAdd() {
      if (this.isValid) {
        const url = '/notes/api/note-external-recipients/'
        const data = {
          email: this.email,
        }
        const backendApi = new BackendApi('post', url)
        try {
          await backendApi.callApi(data)
          await this.addSuccess('Le destinataire a été ajouté')
          this.$emit('done')
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
