<template>
  <div v-if="isAuthenticated" :class="{'top-bar-highlight': hasNotes}">
    <div class="top-bar-main"></div>
    <div class="top-bar">
      <b-row class="top-bar-inner">
        <b-col md="4" sm="6">
          <div class="top-bar-logo">
            <router-link :to="{ name: 'home', }">
              millibase.net
              <div class="version">{{ version }}</div>
            </router-link>
          </div>
          <demo-warning></demo-warning>
          <span class="backend-name">{{ getBackendName() }}</span>
        </b-col>
        <b-col md="3" sm="3" class="text-center">
          <div class="top-bar-contact-support">
            <a href @click.prevent="showContactSupport()"><i class="fa fa-user-edit"></i>
              <span class="top-bar-button-text">Support</span>
            </a>
            <a href @click.prevent="showOnlineHelp()"><i class="fa fa-info-circle"></i>
              <span class="top-bar-button-text">Aide en ligne</span>
            </a>
          </div>
        </b-col>
        <b-col md="5" sm="3" class="text-right">
          <div class="info-right">
            <div class="user-name">{{ userName }}</div>
            <div>
              <user-preferences-modal></user-preferences-modal>
            </div>
            <span class="active-school-years" v-if="showYears" @click.prevent="showYears = false">
              <span
                v-for="schoolYear of activeSchoolYears"
                :key="schoolYear.id"
                :style="schoolYearStyle(schoolYear)"
                class="active-school-year-badge"
              >
                {{ schoolYear.name }}
              </span>
            </span>
            <span v-else class="active-school-years">
              <a href @click.prevent="showYears = true" class="top-bar-button">
                <i class="fas fa-calendar-days"></i>
              </a>
            </span>
            <div class="user-logout">
              <a href v-on:click="onLogout" class="top-bar-button">
                <i class="fa fa-sign-out"></i> <span class="top-bar-button-text">Déconnexion</span>
              </a>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import DemoWarning from '@/components/Layout/DemoWarning'
import UserPreferencesModal from '@/components/Users/UserPreferencesModal'
import { BackendMixin } from '@/mixins/backend'
import { makeSchoolYear } from '@/types/schools'
import { BackendApi, getHostName } from '@/utils/http'
import { compareNumbers } from '@/utils/sorting'

export default {
  name: 'TopBar',
  components: { UserPreferencesModal, DemoWarning, },
  mixins: [BackendMixin],
  props: {
    version: String,
  },
  data() {
    return {
      showYears: true,
      disableYears: false, // utilisé pour désactiver "loadSchoolYears" pour les tests unitaires
      schoolYears: [],
    }
  },
  computed: {
    userName() {
      return store.getters.userName
    },
    userPreferences() {
      return store.getters.userPreferences
    },
    isAuthenticated() {
      return store.getters.isAuthenticated
    },
    backendName() {
      return store.getters.backendName
    },
    activeSchoolYears() {
      const prefYears = this.userPreferences.activeSchoolYears.map(year => year.id)
      if (prefYears.length === 0) {
        return this.schoolYears.filter(elt => elt.isActive)
      } else {
        return this.schoolYears.filter(year => prefYears.indexOf(year.id) >= 0)
      }
    },
    hasNotes() {
      return store.getters.notesCount > 0
    },
  },
  mounted() {
    this.showYears = !(window.localStorage.getItem('top-bar-show-years') === 'non')
    this.init()
  },
  watch: {
    userPreferences: function() {
      this.init()
    },
    isAuthenticated: function() {
      this.init()
    },
    showYears: function() {
      window.localStorage.setItem('top-bar-show-years', this.showYears ? 'oui' : 'non')
      if (this.showYears && this.schoolYears.length === 0) {
        this.init()
      }
    },
  },
  methods: {
    async init() {
      if (this.isAuthenticated && this.showYears && !this.disableYears) {
        await this.loadSchoolYears()
        this.schoolYears = this.schoolYears.sort(
          (year1, year2) => compareNumbers(year1.startYear, year2.startYear)
        )
      } else {
        this.schoolYears = []
      }
    },
    onLogout(evt) {
      evt.preventDefault()
      this.logout().then(() => {
        window.location.reload()
      })
    },
    getBackendName() {
      return getHostName(this.backendName)
    },
    showOnlineHelp() {
      this.$bvModal.show('bv-online-help')
    },
    showContactSupport() {
      this.$bvModal.show('bv-contact-support')
    },
    async loadSchoolYears() {
      const backendApi = new BackendApi('get', '/api/school-years/')
      try {
        let resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(elt => makeSchoolYear(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    schoolYearStyle(schoolYear) {
      const style = {}
      if (schoolYear.backgroundColor) {
        style.background = schoolYear.backgroundColor
      }
      if (schoolYear.textColor) {
        style.color = schoolYear.textColor
      }
      return style
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

.top-bar {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  background: #e7f3ff; // ffaad3
  color: #222;
  padding: 3px 10px 3px 10px;
  z-index: 1;
  text-align: left;
  font-weight: bold;
}

.top-bar-main {
  position: absolute;
  top:0;
  left:0;
  background: #454851;
  height: 80px;
  width: 100%;
}

.top-bar-highlight .top-bar-main {
  background: #b83375;
  color: #fff !important;
}

@media(max-width: 949px) {
  .top-bar-main {
    height: 110px;
  }
}

.top-bar-inner {
  margin: 0 10px 0 63px;
}

.top-bar a {
  color: #222 !important;
  text-decoration: underline;
}

.top-bar-logo {
  display: inline-block;
  margin-right: 20px;
  font-size: 18px;
  vertical-align: top;
}

.top-bar-logo a {
  text-decoration: none;
}

.top-bar-logo .version{
  font-size: 10px;
  font-weight: normal;
  margin-top: -8px;
  text-align: right;
}

@media(max-width: 768px) {
  .top-bar-logo {
    font-size: 12px
  }
  .top-bar-logo .version{
    margin-top: -5px;
  }
}

.info-right {
  display: inline-block;
  text-align: right;
  font-size: 12px;
  margin-right: 10px;
}

.info-right > div {
  display: inline-block;
  margin-left: 5px;
}
.padding-r0{
  padding-right: 0;
}
.backend-name {
  margin-left: 5px;
  display: inline-block;
  padding: 3px 8px;
  color: #fff;
  background: #64677d;
  border-radius: 2px;
  font-size: 12px;
}
.top-bar-contact-support {
  font-size: 12px;
  margin-top: 8px;
}
.top-bar-contact-support > a {
  margin: 0 3px;
}
.user-logout {
  padding: 0;
}
.active-school-years {
  display: inline-block;
  margin: 0 0 0 5px;
  cursor: pointer;
}
.active-school-year-badge {
  padding: 2px 4px;
  margin: 1px;
  background: #9698ab;
  text-align: center;
  display: inline-block;
}
</style>
