import { SchoolYear, makeSchoolYear } from './schools'
import { Individual, makeIndividual } from './people'
import {
  Discount, Invoice, makeDiscount, makeInvoice, calculatePrice, Sale, makeSale,
  AnalyticAccount, GeneralAccount, makeAnalyticAccount, makeGeneralAccount
} from './payments'

export const FAMILY_ADHESION: number = 1
export const INDIVIDUAL_ADHESION: number = 2

export enum AdhesionApplyTo {
  NO_ADHESION = 0,
  FAMILY_ADHESION = 1,
  INDIVIDUAL_ADHESION = 2
}

export class AdhesionTypePrice {
  constructor(
    public id: number,
    public schoolYear: SchoolYear|null,
    public civilYear: number|null,
    public price: number
  ) {
  }

  public getYear(): string {
    if (this.schoolYear && this.schoolYear.id) {
      return this.schoolYear.name
    } else {
      return '' + this.civilYear
    }
  }

  public getYearId(): number {
    if (this.schoolYear && this.schoolYear.id) {
      return this.schoolYear.id
    } else {
      return this.civilYear || 0
    }
  }

  public matchSchoolYear(schoolYear: SchoolYear): boolean {
    if (this.schoolYear && this.schoolYear.id) {
      return this.schoolYear.id === schoolYear.id
    } else {
      return (
        (this.civilYear === schoolYear.startYear) ||
        (this.civilYear === schoolYear.startYear + 1)
      )
    }
  }
}

export class AdhesionType {
  constructor(
    public id: number,
    public name: string,
    public applyTo: number,
    public prices: AdhesionTypePrice[],
    public analyticAccount: AnalyticAccount,
    public generalAccount: GeneralAccount,
    public isCivilYear: boolean
  ) {
  }

  public applyToIndividual(): boolean {
    return this.applyTo === INDIVIDUAL_ADHESION
  }

  public applyToEntity(): boolean {
    return this.applyTo === AdhesionApplyTo.FAMILY_ADHESION
  }

  public getLabel(): string {
    if (this.applyTo === AdhesionApplyTo.FAMILY_ADHESION) {
      return 'Famille'
    } else {
      return 'Individuelle'
    }
  }

  public matchSchoolYear(schoolYear: SchoolYear): boolean {
    for (const price of this.prices) {
      if (price.matchSchoolYear(schoolYear)) {
        return true
      }
    }
    return false
  }
}

export class Adhesion {
  constructor(
    public id: number,
    public cardNumber: string,
    public schoolYear: SchoolYear|null,
    public civilYear: number|null,
    public isCancelled: boolean,
    public createdOn: Date,
    public createdBy: string,
    public adhesionType: AdhesionType,
    public entity: number,
    public individual: Individual|null,
    public invoice: Invoice|null,
    public price: number|null,
    public discounts: Discount[],
    public sale: Sale|null
  ) {
  }

  public getPrice(): number|null {
    if (this.price === null) {
      return null
    } else {
      return calculatePrice(this.price || 0, this.discounts)
    }
  }

  public getSales(extraOnly = false): Sale[] {
    // pour compatibilité avec les activités
    if (this.sale && !extraOnly) {
      return [this.sale]
    }
    return []
  }

  public getYear(): string {
    if (this.schoolYear && this.schoolYear.id) {
      return this.schoolYear.name
    } else {
      return '' + this.civilYear
    }
  }

  public getHeaderStyle(): any {
    if (this.schoolYear) {
      return this.schoolYear.getHeaderStyle()
    }
    return {}
  }

  public areSameYear(price: AdhesionTypePrice): boolean {
    if (this.schoolYear && this.schoolYear.id && price.schoolYear && price.schoolYear.id) {
      return (this.schoolYear.id === price.schoolYear.id)
    }
    if (this.civilYear && price.civilYear) {
      return (this.civilYear === price.civilYear)
    }
    return false
  }
}

export function makeAdhesionTypePrice(jsonData: any = null): AdhesionTypePrice {
  if (!jsonData) {
    jsonData = {}
  }
  return new AdhesionTypePrice(
    jsonData.id || 0,
    jsonData.school_year ? makeSchoolYear(jsonData.school_year) : null,
    jsonData.civil_year || null,
    jsonData.price ? +jsonData.price : 0
  )
}

export function makeAdhesionType(jsonData: any = null): AdhesionType {
  if (!jsonData) {
    jsonData = {}
  }
  let prices = jsonData.prices || []
  return new AdhesionType(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.apply_to || AdhesionApplyTo.FAMILY_ADHESION,
    prices.map((elt: any) => makeAdhesionTypePrice(elt)),
    makeAnalyticAccount(jsonData.analytic_account) || null,
    makeGeneralAccount(jsonData.general_account) || null,
    !!jsonData.is_civil_year
  )
}

export function makeAdhesion(jsonData: any = null): Adhesion {
  if (!jsonData) {
    jsonData = {}
  }
  let discounts = jsonData.discounts || []
  return new Adhesion(
    jsonData.id || 0,
    jsonData.card_number || '',
    makeSchoolYear(jsonData.school_year),
    jsonData.civil_year || null,
    !!jsonData.is_cancelled,
    jsonData.created_on,
    jsonData.created_by,
    makeAdhesionType(jsonData.adhesion_type),
    jsonData.entity || 0,
    jsonData.individual ? makeIndividual(jsonData.individual) : null,
    jsonData.invoice ? makeInvoice(jsonData.invoice) : null,
    (jsonData.price !== null) ? +jsonData.price : null,
    discounts.map((elt: any) => makeDiscount(elt)),
    jsonData.sale ? makeSale(jsonData.sale) : null
  )
}

export class EntitiesAdhesionSummary {
  constructor(
    public id: number,
    public name: string,
    public entitiesCount: number,
    public individualsCount: number,
    public adhesionType: AdhesionApplyTo,
    public isCivilYear: boolean
  ) {
  }

  public isIndividual(): boolean {
    return (
      (this.adhesionType === AdhesionApplyTo.INDIVIDUAL_ADHESION) ||
      (this.adhesionType === AdhesionApplyTo.NO_ADHESION)
    )
  }
}

export function makeEntitiesAdhesionSummary(jsonData: any = null): EntitiesAdhesionSummary {
  if (!jsonData) {
    jsonData = {}
  }
  return new EntitiesAdhesionSummary(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.entities_count || 0,
    jsonData.individuals_count || 0,
    jsonData.adhesion_type || AdhesionApplyTo.NO_ADHESION,
    !!jsonData.is_civil_year
  )
}
