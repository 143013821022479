<template>
  <div class="send-note-by-email-modal" v-if="canView">
    <b-modal
      :id="modalId"
      cancel-title="Annuler"
      :ok-disabled="selectedRecipients.length === 0"
      @ok="onSend"
      ok-variant="primary"
      ok-title="Envoyer"
      @show="onShow()"
      size="lg"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fa fa-paper-plane"></i>
          Envoyer une note à des destinataires externes
        </b>
      </template>
      <div class="form-group">
        <div v-if="note" style="border-bottom: 5px; padding: 5px; background: #eee">
          <div>{{ note.title }}</div>
        </div>
        <b-row>
          <b-col>
            <b-form-group label-for="recipients" label="Destinataires">
              <check-box-select
                inline
                id="recipients"
                :choices="recipients"
                @changed="onRecipientsChanged"
              ></check-box-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="note && note.replies.length">
          <b-col>
            <b-form-checkbox id="includeReplies" v-model="includeReplies">
              inclure les réponses
            </b-form-checkbox>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect.vue'
import { BackendMixin } from '@/mixins/backend'
import { makeChoice } from '@/types/base'
import { BackendApi } from '@/utils/http'

export default {
  name: 'send-note-by-email-modal',
  components: { CheckBoxSelect, },
  mixins: [BackendMixin],
  props: {
    modalId: {
      type: String,
      default: 'bv-send-note-by-email-modal',
    },
    note: {
      type: Object,
    },
  },
  data() {
    return {
      recipients: [],
      selectedRecipients: [],
      includeReplies: false,
    }
  },
  computed: {
    canView() {
      return this.hasPerm('notes.change_note')
    },
  },
  mounted() {
  },
  watch: {
    note: function() {
      this.init()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    init() {
      if (this.note) {
        this.includeReplies = this.note.replies.length > 0
      }
    },
    onShow() {
      this.init()
      this.loadRecipients()
    },
    async onSend() {
      if (this.selectedRecipients.length) {
        const url = '/notes/api/send-note-by-email/' + this.note.id + '/'
        const data = {
          emails: this.selectedRecipients.map(elt => elt.name),
          include_replies: this.includeReplies,
        }
        const backendApi = new BackendApi('post', url)
        try {
          await backendApi.callApi(data)
          await this.addSuccess('La note a été envoyée')
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadRecipients() {
      this.recipients = []
      let backendApi = new BackendApi('get', '/notes/api/note-external-recipients/')
      try {
        let resp = await backendApi.callApi()
        this.recipients = resp.data.map(
          elt => {
            return makeChoice(
              {
                id: elt.id,
                name: elt.email,
              }
            )
          }
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onRecipientsChanged(event) {
      this.selectedRecipients = event.choices
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
