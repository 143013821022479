<template>
  <div class="streets-list" v-if="hasPerm('people.view_city')">
    <page-header title="Villes et rues" icon="fa fa-address" :links="getLinks()"></page-header>
    <b-row>
      <b-col cols="4" class="border-right">
        <loading-gif :loading-name="loadingName"></loading-gif>
        <div v-if="!isLoading(loadingName)">
          <b-row>
            <b-col>
              <h3>Villes</h3>
            </b-col>
          </b-row>
          <div class="filter-bar">
            <b-input-group prepend="prepend">
              <b-input id="city-filter" type="text" placeholder="filtre" v-model="cityFilter"></b-input>
              <template #prepend>
                <span class="input-group-text"><i class="fa fa-filter"></i></span>
              </template>
              <template #append>
                <b-button
                  :variant="cityFilter ? 'outline-primary': 'outline-secondary'"
                  @click.prevent="cityFilter = ''">
                  <i class="fa fa-eraser"></i>
                </b-button>
              </template>
            </b-input-group>
          </div>
          <div
            v-for="city in filteredCities"
            :key="city.id"
            class="row-line"
            :class="isCitySelected(city) ? 'selected-line' : ''"
            @click="selectCity(city)"
          >
            <b-row>
              <b-col>
                <div>
                  {{ city.name }} ({{ city.getDepartment() }})
                </div>
                <div class="small-text">
                  <counter-label :counter="city.streetsCount" label="rue"></counter-label>
                </div>
              </b-col>
              <b-col cols="4" class="text-right">
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="4" class="border-right">
        <loading-gif :loading-name="loadingName"></loading-gif>
        <div v-if="!isLoading(loadingName)">
          <b-row>
            <b-col>
              <h3>Quartiers</h3>
            </b-col>
            <b-col class="text-right" cols="2">
              <span v-if="streetGroupsCount" class="streets-round-counter">
                {{ streetGroupsCount }}
              </span>
            </b-col>
          </b-row>
          <div class="filter-bar">
            <b-input-group prepend="prepend">
              <b-input type="text" id="groupFilter" placeholder="filtre" v-model="groupFilter"></b-input>
              <template #prepend>
                <span class="input-group-text"><i class="fa fa-filter"></i></span>
              </template>
              <template #append>
                <b-button
                  :variant="groupFilter ? 'outline-primary': 'outline-secondary'"
                  @click.prevent="groupFilter = ''"
                >
                  <i class="fa fa-eraser"></i>
                </b-button>
              </template>
            </b-input-group>
          </div>
          <div
            v-for="group in filteredGroups"
            :key="group.id"
            class="row-line"
            :class="isGroupSelected(group) ? 'selected-line' : ''"
            @click="selectGroup(group)"
          >
            <b-row>
              <b-col>
                <span>
                  {{ group.name }}
                </span>
              </b-col>
              <b-col
                cols="2"
                v-if="group.id && isGroupSelected(group)"
                class="text-right"
              >
                <a href @click.prevent="showEditStreetsGroupModal(group)" class="btn btn-sm btn-secondary">
                  <i class="fa fa-pencil-alt"></i>
                </a>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="4" class="border-right">
        <loading-gif :loading-name="loadingName"></loading-gif>
        <div v-if="!isLoading(loadingName)">
          <b-row>
            <b-col>
              <h3>Rues</h3>
            </b-col>
            <b-col class="text-right" cols="2">
              <a
                href class="small-text"
                @click.prevent="selectFilteredStreets()"
                v-if="streets.length > 0"
              >
                Tout
              </a>
            </b-col>
            <b-col class="text-right" cols="2">
              <a
                href class="small-text"
                @click.prevent="unselectFilteredStreets()"
                v-if="streets.length > 0"
              >
                  Aucun
              </a>
            </b-col>
            <b-col class="text-right" cols="4">
              <span v-if="selectedStreets.length" class="streets-round-counter2">
                {{ selectedStreets.length }} / {{ streetsCount }}
              </span>
              <span v-else-if="streetsCount" class="streets-round-counter">
                {{ streetsCount }}
              </span>
            </b-col>
          </b-row>
          <div class="filter-bar">
            <b-input-group prepend="prepend">
              <b-input type="text" id="streetFilter" placeholder="filtre" v-model="streetFilter"></b-input>
              <template #prepend>
                <span class="input-group-text"><i class="fa fa-filter"></i></span>
              </template>
              <template #append>
                <b-button
                  :variant="streetFilter ? 'outline-primary': 'outline-secondary'"
                  @click.prevent="streetFilter = ''"
                >
                  <i class="fa fa-eraser"></i>
                </b-button>
              </template>
            </b-input-group>
          </div>
          <div
            v-for="street in filteredStreets"
            :key="street.id"
            class="row-line"
            :class="isStreetSelected(street) ? 'selected-line' : ''"
          >
            <b-row>
              <b-col @click="selectStreet(street)">
                <span>
                  {{ street.name }}
                </span>
              </b-col>
              <b-col
                cols="2"
                v-if="canChangeStreet && isStreetSelected(street)"
                class="text-right"
              >
                <a href @click.prevent="showEditStreetModal(street)" class="btn btn-sm btn-secondary">
                  <i class="fa fa-pencil-alt"></i>
                </a>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <move-streets-modal
      :current-group="selectedGroup"
      :streets="selectedStreets"
      :groups="streetGroups"
      @done="onMoved($event)"
    >
    </move-streets-modal>
    <merge-streets-modal
      :street1="street1"
      :street2="street2"
      @done="loadStreets()"
    >
    </merge-streets-modal>
    <modify-street-modal
      :street="selectedStreet"
      @done="loadStreets()"
    >
    </modify-street-modal>
    <modify-streets-group-modal
      :group="selectedGroup"
      @done="loadStreetGroups()"
    >
    </modify-streets-group-modal>
  </div>
</template>

<script>
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import MoveStreetsModal from '@/components/Cities/MoveStreetsModal.vue'
import MergeStreetsModal from '@/components/Cities/MergeStreetsModal.vue'
import ModifyStreetModal from '@/components/Cities/ModifyStreetModal.vue'
import ModifyStreetsGroupModal from '@/components/Cities/ModifyStreetsGroupModal.vue'
import { BackendMixin } from '@/mixins/backend'
import { makeCity } from '@/types/people'
import { makeChoice } from '@/types/base'
import { BackendApi, openDocument } from '@/utils/http'
import { slugify } from '@/utils/strings'
import { compareNumbers, compareStrings } from '@/utils/sorting'

export default {
  name: 'streets-list',
  components: {
    ModifyStreetsGroupModal,
    CounterLabel,
    MergeStreetsModal,
    MoveStreetsModal,
    PageHeader,
    LoadingGif,
    ModifyStreetModal,
  },
  mixins: [BackendMixin],
  data() {
    return {
      loadingName: 'streets-list',
      selectedCity: null,
      selectedGroup: null,
      cities: [],
      streetGroups: [],
      streets: [],
      selectedStreets: [],
      elements: [],
      selectedElements: [],
      cityFilter: '',
      groupFilter: '',
      streetFilter: '',
      selectedStreet: null,
    }
  },
  async mounted() {
    await this.loadCities()
  },
  computed: {
    street1() {
      if (this.selectedStreets.length === 2) {
        return this.selectedStreets[0]
      }
      return null
    },
    street2() {
      if (this.selectedStreets.length === 2) {
        return this.selectedStreets[1]
      }
      return null
    },
    streetGroupsCount() {
      return this.streetGroups.filter(elt => elt.id > 0).length
    },
    streetsCount() {
      return this.streets.filter(elt => elt.id > 0).length
    },
    canEdit() {
      return this.hasPerm('people.change_city')
    },
    canChangeStreet() {
      return this.hasPerm('people.change_street')
    },
    filteredCities() {
      if (this.cityFilter) {
        const filter = this.cityFilter.toLowerCase()
        return this.cities.filter(
          elt => elt.name.toLowerCase().indexOf(filter) >= 0
        )
      }
      return this.cities
    },
    filteredGroups() {
      if (this.groupFilter) {
        const filter = this.groupFilter.toLowerCase()
        return this.streetGroups.filter(
          elt => elt.name.toLowerCase().indexOf(filter) >= 0
        )
      }
      return this.streetGroups
    },
    filteredStreets() {
      if (this.streetFilter) {
        const filter = this.streetFilter.toLowerCase()
        return this.streets.filter(
          elt => elt.name.toLowerCase().indexOf(filter) >= 0
        )
      }
      return this.streets
    },
  },
  methods: {
    async loadCities() {
      let url = '/api/people/cities-ex/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.cities = resp.data.map(elt => makeCity(elt)).sort(
          (elt1, elt2) => {
            let cmp = compareNumbers(elt2.streetsCount, elt1.streetsCount)
            if (cmp === 0) {
              cmp = compareStrings(elt1.name, elt2.name)
            }
            return cmp
          }
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadStreetGroups() {
      let url = '/api/people/street-groups/' + this.selectedCity.id + '/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.streetGroups = [
          makeChoice({ id: 0, name: 'non défini', })
        ].concat(resp.data.map(elt => makeChoice(elt)))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onMoved(event) {
      if (event.new) {
        this.streetGroups.push(event.group)
      }
      this.loadStreets()
    },
    async loadStreets() {
      this.selectedStreets = []
      let url = '/api/people/group-streets/' + this.selectedCity.id + '/' + this.selectedGroup.id + '/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.streets = resp.data.map(elt => makeChoice(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    showMoveModal() {
      if (this.selectedElements) {
        this.$bvModal.show('bv-modal-move-street')
      }
    },
    showEditStreetModal(street) {
      this.selectedStreet = street
      const that = this
      this.$nextTick(() => {
        that.$bvModal.show('bv-modal-edit-street')
      })
    },
    showEditStreetsGroupModal(group) {
      this.$bvModal.show('bv-modal-modify-streets-group')
    },
    showMergeModal() {
      if (this.street1 && this.street2) {
        this.$bvModal.show('bv-modal-merge-streets')
      }
    },
    async printMe() {
      if (this.selectedSchoolYear) {
        const name = this.selectedSchoolLevel ? this.selectedSchoolLevel.fullName : 'a-definir'
        const docUrl = '/documents/standard/<key>/pdf/?landscape=1'
        const docSlug = 'villes-' + slugify(name)
        const docContent = this.$refs.printMe.innerHTML.toString()
        try {
          await openDocument(docUrl, docSlug, docContent)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    getLinks() {
      const links = []
      if (this.canEdit) {
        links.push(
          {
            id: 2,
            label: 'Pdf',
            callback: this.printMe,
            icon: 'fa fa-file-pdf',
            cssClass: 'btn-secondary',
          },
          {
            id: 3,
            label: 'Fusionner',
            callback: this.showMergeModal,
            icon: 'fa fa-code-merge',
            cssClass: (this.selectedStreets.length !== 2) ? 'btn-secondary disabled' : 'btn-warning',
          },
          {
            id: 1,
            label: 'Changement de quartier',
            callback: this.showMoveModal,
            icon: 'fa fa-house-chimney',
            cssClass: (this.selectedStreets.length === 0) ? 'btn-primary disabled' : 'btn-primary',
          }
        )
      }
      return links
    },
    isCitySelected(city) {
      return (this.selectedCity && (city.id === this.selectedCity.id))
    },
    selectCity(city) {
      if (city && city.id) {
        this.selectedCity = city
      } else {
        this.selectedCity = null
      }
      this.selectedGroup = null
      this.streets = []
      this.selectedStreets = []
      this.loadStreetGroups()
    },
    isGroupSelected(group) {
      return (this.selectedGroup && (group.id === this.selectedGroup.id))
    },
    selectGroup(group) {
      this.selectedStreets = []
      if (group) {
        this.selectedGroup = group
        this.loadStreets()
      } else {
        this.selectedGroup = null
        this.streets = []
      }
    },
    isStreetSelected(street) {
      return this.selectedStreets.map(elt => elt.id).indexOf(street.id) >= 0
    },
    selectStreet(street) {
      const index = this.selectedStreets.map(elt => elt.id).indexOf(street.id)
      if (index >= 0) {
        this.selectedStreets.splice(index, 1)
      } else {
        this.selectedStreets.push(street)
      }
      this.selectedStreets = [].concat(this.selectedStreets)
    },
    selectFilteredStreets() {
      const streetIds = this.selectedStreets.map(elt => elt.id)
      for (const street of this.filteredStreets) {
        const index = streetIds.indexOf(street.id)
        if (index < 0) {
          this.selectedStreets.push(street)
        }
      }
    },
    unselectFilteredStreets() {
      let streetIds = this.selectedStreets.map(elt => elt.id)
      for (const street of this.filteredStreets) {
        const index = streetIds.indexOf(street.id)
        if (index >= 0) {
          this.selectedStreets.splice(index, 1)
          streetIds = this.selectedStreets.map(elt => elt.id)
        }
      }
    },
  },
}

</script>

<style scoped>
.streets-list {
  .streets-round-counter, .streets-round-counter2 {
    margin: 2px;
    padding: 4px;
    color: #fff;
    background: #444;
    border-radius: 16px;
    text-align: center;
    font-size: 11px;
    min-width: 24px;
    height: 26px;
    display: inline-block;
  }

  .streets-round-counter {
    color: #444;
    background: #eee;
  }

  .row-line {
    cursor: pointer;
  }

  .selected-line {
    background: #444 !important;
    color: #fff;
  }

  .border-right {
    border-right: grey 1px solid;
  }

  .school-year-bars .badge {
    margin-right: 4px;
    cursor: pointer;
  }

  .school-year-bars, .selector-bar {
    padding: 5px;
    border-bottom: solid 1px #ccc;
  }

  .selector-bar a {
    display: inline-block;
    margin-right: 5px;
  }

  .the-title {
    padding: 5px;
    background: #ccc;
    font-weight: bold;
  }
  .filter-bar {
    margin-bottom: 2px;
  }
}
</style>
