import { Individual, makeIndividual } from '@/types/people'

export enum ContactSource {
  Activities = 0,
  YouthHomes = 1,
  Adhesions = 2,
}

export class Contact {
  constructor(
    public individual: Individual,
    public emails: string[],
    public phones: string[]
  ) {
  }
}

export function makeContact(jsonData: any = null): Contact {
  if (!jsonData) {
    jsonData = {}
  }
  return new Contact(
    makeIndividual(jsonData.individual),
    jsonData.emails || [],
    jsonData.phones || []
  )
}

export class LabelsTemplate {
  constructor(
    public id: number,
    public name: string,
    public pageSize: string,
    public orientation: string,
    public columns: number,
    public rows: number,
    public marginTop: number,
    public marginBottom: number,
    public marginLeft: number,
    public marginRight: number,
    public labelPaddingHeight: number,
    public labelPaddingWidth: number,
    public labelMarginBottom: number,
    public labelMarginRight: number,
    public labelFontSize: number,
    public labelTextAlign: string
  ) {
  }
}

export function makeLabelsTemplate(jsonData: any = null): LabelsTemplate {
  return new LabelsTemplate(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.page_size || 'A4',
    jsonData.orientation || 'portrait',
    jsonData.columns || 0,
    jsonData.rows || 0,
    jsonData.margin_top || 0,
    jsonData.margin_bottom || 0,
    jsonData.margin_left || 0,
    jsonData.margin_right || 0,
    jsonData.label_padding_height || 0,
    jsonData.label_padding_width || 0,
    jsonData.label_margin_bottom || 0,
    jsonData.label_margin_right || 0,
    jsonData.label_font_size || 0,
    jsonData.label_text_align || 'center'
  )
}
