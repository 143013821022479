import { slugify } from '@/utils/strings'

export class ActivityMenu {
  constructor(
    public id: number,
    public label: string,
    public icon: string,
    public category: number
  ) {}

  public getName() {
    return slugify(this.label)
  }
}

export function getDefaultActivityMenu(): ActivityMenu {
  return new ActivityMenu(
    1, 'Activités', 'fas fa-chess-knight', 0
  )
}

export class Config {
  constructor(
    public familyLevelsCount: number,
    public structureName: string,
    public youthHomeLabel: string,
    public youthHomeIcon: string,
    public cafApiEnabled: boolean,
    public allowEmptyLevel: boolean,
    public hasExternalCities: boolean,
    public hideAddress2: boolean,
    public showForceCity: boolean,
    public daytimeLabels: string[],
    public nursery: boolean,
    public isStoreActive: boolean,
    public isPortalActive: boolean,
    public isPublicationActive: boolean,
    public publicationTitle: string,
    public cielExport: boolean,
    public disableCheckExistingIndividuals: boolean,
    public hasCertificates: boolean,
    public canBlockWelfare: boolean,
    public dontUseCreditsByDefault: boolean,
    public reminderInfo: string,
    public hideAcmInscriptionAndInvoice: boolean,
    public sendNoteByEmail: boolean,
    public activityMenus: ActivityMenu[]
  ) {
  }

  public serialize(): string {
    const config: any = {
      family_levels_count: this.familyLevelsCount,
      structure_name: this.structureName,
      youth_home_label: this.youthHomeLabel,
      youth_home_icon: this.youthHomeIcon,
      caf_api_enabled: this.cafApiEnabled,
      allow_empty_level: this.allowEmptyLevel,
      has_external_cities: this.hasExternalCities,
      hide_address2: this.hideAddress2,
      show_force_city: this.showForceCity,
      daytime_labels: this.daytimeLabels.join(';'),
      nursery: this.nursery,
      is_store_active: this.isStoreActive,
      is_portal_active: this.isPortalActive,
      is_publication_active: this.isPublicationActive,
      publication_title: this.publicationTitle,
      ciel_export: this.cielExport,
      disable_check_existing_individuals: this.disableCheckExistingIndividuals,
      has_certificates: this.hasCertificates,
      can_block_welfare: this.canBlockWelfare,
      dont_use_credits_by_default: this.dontUseCreditsByDefault,
      activity_menus: this.activityMenus,
      reminder_info: this.reminderInfo,
      hide_acm_inscription_and_invoice: this.hideAcmInscriptionAndInvoice,
      send_note_by_email: this.sendNoteByEmail,
    }
    return JSON.stringify(config)
  }

  public deserialize(configAsString: string) {
    try {
      const obj = JSON.parse(configAsString)
      this.familyLevelsCount = obj.family_levels_count || 1
      this.structureName = obj.structure_name || ''
      this.youthHomeLabel = obj.youth_home_label || 'Accueil de loisirs'
      this.youthHomeIcon = obj.youth_home_icon || 'fa fa-child'
      this.cafApiEnabled = !!obj.caf_api_enabled
      this.allowEmptyLevel = !!obj.allow_empty_level
      this.hasExternalCities = !!obj.has_external_cities
      this.hideAddress2 = !!obj.hide_address2
      this.showForceCity = !!obj.show_force_city
      this.daytimeLabels = obj.daytime_labels.split(';')
      this.nursery = !!obj.nursery
      this.isStoreActive = !!obj.is_store_active
      this.isPortalActive = !!obj.is_portal_active
      this.isPublicationActive = !!obj.is_publication_active
      this.publicationTitle = obj.publication_title || ''
      this.cielExport = !!obj.ciel_export
      this.disableCheckExistingIndividuals = !!obj.disable_check_existing_individuals
      this.canBlockWelfare = !!obj.can_block_welfare
      this.hasCertificates = !!obj.has_certificates
      this.dontUseCreditsByDefault = !!obj.dont_use_credits_by_default
      this.reminderInfo = obj.reminder_info || ''
      this.hideAcmInscriptionAndInvoice = !!obj.hide_acm_inscription_and_invoice
      this.sendNoteByEmail = !!obj.send_note_by_email
      this.activityMenus = obj.activity_menus.map(
        (elt: any) => {
          return new ActivityMenu(
            +elt.id,
            elt.label || '',
            elt.icon || '',
            +elt.category
          )
        }
      )
    } catch (exc) {
      this.familyLevelsCount = 1
      this.structureName = ''
      this.youthHomeLabel = 'Accueil de loisirs'
      this.youthHomeIcon = 'fa fa-child'
      this.cafApiEnabled = false
      this.allowEmptyLevel = false
      this.hasExternalCities = true
      this.hideAddress2 = false
      this.showForceCity = false
      this.daytimeLabels = []
      this.nursery = false
      this.isStoreActive = false
      this.isPortalActive = false
      this.isPublicationActive = false
      this.publicationTitle = ''
      this.cielExport = false
      this.disableCheckExistingIndividuals = false
      this.hasCertificates = false
      this.dontUseCreditsByDefault = false
      this.canBlockWelfare = false
      this.reminderInfo = ''
      this.hideAcmInscriptionAndInvoice = false
      this.sendNoteByEmail = false
      this.activityMenus = []
    }
  }
}

export function makeConfig(jsonData: any = null): Config {
  if (!jsonData) {
    jsonData = {}
  }
  const menus = jsonData.activity_menus || []
  return new Config(
    jsonData.family_levels_count || 3,
    jsonData.structure_name || '',
    jsonData.youth_home_label || 'Accueil de loisirs',
    jsonData.youth_home_icon || 'fa fa-child',
    !!jsonData.caf_api_enabled,
    !!jsonData.allow_empty_level,
    !!jsonData.has_external_cities,
    !!jsonData.hide_address2,
    !!jsonData.show_force_city,
    (jsonData.daytime_labels || '').split(';'),
    !!jsonData.nursery,
    !!jsonData.is_store_active,
    !!jsonData.is_portal_active,
    !!jsonData.is_publication_active,
    jsonData.publication_title || 'Publications',
    !!jsonData.ciel_export,
    !!jsonData.disable_check_existing_individuals,
    !!jsonData.has_certificates,
    !!jsonData.can_block_welfare,
    !!jsonData.dont_use_credits_by_default,
    jsonData.reminder_info || '',
    !!jsonData.hide_acm_inscription_and_invoice,
    !!jsonData.send_note_by_email,
    menus.map(
      (elt: any) => {
        return new ActivityMenu(
          +elt.id,
          elt.label || '',
          elt.icon || '',
          +elt.category
        )
      }
    )
  )
}
