import { convertUrlsToLinks } from '@/utils/html'
import { dateToString } from '@/filters/texts'

export class NotePage {
  constructor(
    public name: string,
    public path: string,
    public icon: string
  ) {
  }
}

export function makeNotePage(jsonData: any = null): NotePage {
  if (!jsonData) {
    jsonData = {}
  }
  return new NotePage(
    jsonData.name || '',
    jsonData.path || '',
    jsonData.icon || ''
  )
}

export class NoteReply {
  constructor(
    public id: number,
    public body: string,
    public createdOn: string,
    public createdBy: string,
    public modifiedOn: string,
    public isAuthor: boolean
  ) {
  }

  public bodyHtml(): string {
    return convertUrlsToLinks(this.body)
  }
}

export function makeNoteReply(jsonData: any = null): NoteReply {
  if (!jsonData) {
    jsonData = {}
  }
  return new NoteReply(
    jsonData.id || 0,
    jsonData.body || '',
    jsonData.created_on || '',
    jsonData.created_by || '',
    jsonData.modified_on || '',
    !!jsonData.is_author
  )
}

export enum NotePriority {
  High = 1,
  Normal = 2,
  Low = 3
}

export class Note {
  constructor(
    public id: number,
    public title: string,
    public body: string,
    public priority: NotePriority,
    public createdOn: string,
    public createdBy: string,
    public modifiedOn: string,
    public modifiedBy: string,
    public recipients: number[],
    public page: NotePage|null,
    public archived: boolean,
    public isUnread: boolean,
    public isRecipient: boolean,
    public readOn: Date|null,
    public isPinned: boolean,
    public replies: NoteReply[]
  ) {
  }

  public bodyHtml(): string {
    return convertUrlsToLinks(this.body)
  }

  public readStatus(): string {
    if (this.isRecipient) {
      if (this.isUnread) {
        return 'no-lue'
      } else {
        let text = 'lue'
        if (this.readOn) {
          text += ' le ' + dateToString(this.readOn, 'DD/MM/YYYY')
        }
        return text
      }
    }
    return ''
  }
}

export function makeNote(jsonData: any = null): Note {
  if (!jsonData) {
    jsonData = {}
  }
  const replies = jsonData.replies || []
  return new Note(
    jsonData.id || 0,
    jsonData.title || '',
    jsonData.body || '',
    jsonData.priority || NotePriority.Normal,
    jsonData.created_on || '',
    jsonData.created_by || '',
    jsonData.modified_on || '',
    jsonData.modified_by || '',
    jsonData.recipients ? jsonData.recipients : [],
    jsonData.page ? makeNotePage(jsonData.page) : null,
    !!jsonData.archived,
    !!jsonData.is_unread,
    !!jsonData.is_recipient,
    jsonData.read_on ? jsonData.read_on : null,
    !!jsonData.is_pinned,
    replies.map(makeNoteReply)
  )
}
