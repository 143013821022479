<template>
  <div>
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      :ok-disabled="!isValid()"
      :ok-title="obj ? 'Enregistrer' : 'Créer'"
    >
    <template v-slot:modal-title>
      <b>
        <i class="fas fa-print"></i>&nbsp;
        <span v-if="obj">Modifier le gabarit d'étiquettes</span>
        <span v-else>Créer un nouveau gabarit d'étiquettes</span>
      </b>
    </template>
    <div>
      <div v-if="errorText" class="error-text">
        {{ errorText }}
      </div>
      <div>
        <b-row>
          <b-col cols="6">
            <b-form-group label-for="labelName" label="Modèle d'étiquettes">
              <b-form-input name="labelName" type="text" v-model="labelsTemplate.name" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label-for="pageSize" label="Format">
              <b-form-input name="pageSize" type="text" v-model="labelsTemplate.pageSize" disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label-for="orientation" label="Orientation">
              <b-form-input name="orientation" type="text" v-model="labelsTemplate.orientation" disabled></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label-for="labelColumns" label="Colonnes">
              <b-form-input name="labelColumns" type="number" v-model="labelsTemplate.columns" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label-for="labelRows" label="Lignes">
              <b-form-input name="labelRows" type="number" v-model="labelsTemplate.rows" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label-for="fontSize" label="Taille de la police">
              <b-form-select id="fontSize" v-model="labelsTemplate.labelFontSize">
                <b-select-option :value="8">8 pixels</b-select-option>
                <b-select-option :value="9">9 pixels</b-select-option>
                <b-select-option :value="10">10 pixels</b-select-option>
                <b-select-option :value="11">11 pixels</b-select-option>
                <b-select-option :value="12">12 pixels</b-select-option>
                <b-select-option :value="13">13 pixels</b-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label-for="text-align" label="Alignement">
              <b-form-select id="textAlign" v-model="labelsTemplate.labelTextAlign">
                <b-select-option value="center">Centre</b-select-option>
                <b-select-option value="left">Gauche</b-select-option>
                <b-select-option value="right">Droite</b-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <div><b>Marges de la page</b></div>
        <b-row>
          <b-col>
            <b-form-group label-for="marginTop" label="Haut">
              <b-form-input name="marginTop" type="number" v-model="labelsTemplate.marginTop" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label-for="marginBottom" label="Bas">
              <b-form-input name="marginBottom" type="number" v-model="labelsTemplate.marginBottom" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label-for="marginLeft" label="Gauche">
              <b-form-input name="marginLeft" type="number" v-model="labelsTemplate.marginLeft" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label-for="marginRight" label="Droite">
              <b-form-input name="marginRight" type="number" v-model="labelsTemplate.marginRight" required></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <div>
          <b>Étiquette</b>
        </div>
        <b-row>
          <b-col>
            <b-form-group label-for="labelPaddingHeight" label="Espace vert." description="entre le bord et le texte">
              <b-form-input name="labelPaddingHeight" type="number" v-model="labelsTemplate.labelPaddingHeight" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label-for="labelPaddingWidth" label="Espace horiz.">
              <b-form-input name="labelPaddingWidth" type="number" v-model="labelsTemplate.labelPaddingWidth" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label-for="labelMarginTop" label="Écart vert." description="entre 2 étiquettes">
              <b-form-input name="labelMarginTop" type="number" v-model="labelsTemplate.labelMarginBottom" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label-for="labelMarginRight" label="Écart horiz.">
              <b-form-input name="labelMarginRight" type="number" v-model="labelsTemplate.labelMarginRight" required></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'
import { makeLabelsTemplate } from '@/types/contacts'

export default {
  name: 'EditLabelsTemplateModal',
  components: {},
  mixins: [BackendMixin],
  props: {
    obj: Object,
    modalId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      errorText: '',
      labelsTemplate: null,
    }
  },
  watch: {
    obj: function() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    init() {
      if (this.obj !== null) {
        this.labelsTemplate = this.obj
      } else {
        this.labelsTemplate = makeLabelsTemplate(
          {
            id: 0,
            name: '',
            page_size: 'A4',
            orientation: 'portrait',
            columns: 3,
            rows: 7,
            margin_top: 0,
            margin_right: 0,
            margin_bottom: 0,
            margin_left: 0,
            label_padding_height: 5,
            label_padding_width: 2,
            label_margin_bottom: 0,
            label_margin_right: 0,
            label_font_size: 10,
            label_text_align: 'center',
          }
        )
      }
    },
    showModal() {
      this.errorText = ''
      this.addMode = false
      this.$bvModal.show(this.modalId)
    },
    isValid() {
      return (
        (this.labelsTemplate.name !== '') &&
        (this.labelsTemplate.columns > 0) &&
        (this.labelsTemplate.rows > 0) &&
        (this.labelsTemplate.labelFontSize > 8)
      )
    },
    async onSave() {
      const data = {
        'name': this.labelsTemplate.name,
        'page_size': this.labelsTemplate.pageSize,
        'orientation': this.labelsTemplate.orientation,
        'columns': this.labelsTemplate.columns,
        'rows': this.labelsTemplate.rows,
        'margin_top': this.labelsTemplate.marginTop,
        'margin_right': this.labelsTemplate.marginRight,
        'margin_bottom': this.labelsTemplate.marginBottom,
        'margin_left': this.labelsTemplate.marginLeft,
        'label_padding_height': this.labelsTemplate.labelPaddingHeight,
        'label_padding_width': this.labelsTemplate.labelPaddingWidth,
        'label_margin_top': this.labelsTemplate.labelMarginTop,
        'label_margin_right': this.labelsTemplate.labelMarginRight,
        'label_font_size': this.labelsTemplate.labelFontSize,
        'label_text_align': this.labelsTemplate.labelTextAlign,
      }
      const method = this.labelsTemplate.id ? 'patch' : 'post'
      let url = '/contact/labels-templates/'
      if (this.labelsTemplate.id) {
        url += this.labelsTemplate.id + '/'
      }
      const backend = new BackendApi(method, url)
      try {
        const resp = await backend.callApi(data)
        this.$emit('saved', resp.data)
        this.$bvModal.hide(this.modalId)
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
  },
}
</script>
