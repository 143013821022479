import { convertUrlsToLinks } from '@/utils/html'

export class Shortcut {
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public backgroundColor: string,
    public textColor: string,
    public path: string,
    public icon: string,
    public order: number,
    public block: number
  ) {
  }

  public getStyle(border: boolean = true): any {
    const borderColor = this.textColor || '#222'
    const style: any = {
      backgroundColor: this.backgroundColor,
      color: this.textColor + ' !important',
    }
    if (border) {
      style['border'] = 'solid 2px ' + borderColor
    }
    return style
  }

  public descriptionHtml(): string {
    return convertUrlsToLinks(this.description)
  }
}

export function makeShortcut(jsonData: any = null): Shortcut {
  if (jsonData === null) {
    jsonData = {}
  }
  return new Shortcut(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.description || '',
    jsonData.background_color || '',
    jsonData.text_color || '',
    jsonData.path || '',
    jsonData.icon || '',
    jsonData.order || 0,
    jsonData.block || 0
  )
}

export class ShortcutBlock {
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public backgroundColor: string,
    public textColor: string,
    public page: number,
    public column: number,
    public order: number,
    public shortcuts: Shortcut[]
  ) {
  }

  public getStyle() {
    return {
      backgroundColor: this.backgroundColor,
      color: this.textColor + ' !important',
    }
  }

  public descriptionHtml(): string {
    return convertUrlsToLinks(this.description)
  }
}

export function makeShortcutBlock(jsonData: any = null): ShortcutBlock {
  if (jsonData === null) {
    jsonData = {}
  }
  const shortcuts = jsonData.shortcuts || []
  return new ShortcutBlock(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.description || '',
    jsonData.background_color || '',
    jsonData.text_color || '',
    jsonData.page || 0,
    jsonData.column || 0,
    jsonData.order || 0,
    shortcuts.map(makeShortcut)
  )
}
