<template>
  <span class="app-logo">
    <img src="@/assets/img/millibase-blue.png" :class="size" v-if="size === 'icon-bar'" />
    <img src="@/assets/img/millibase-full.svg" :class="size" v-else />
  </span>
</template>

<script>

export default {
  name: 'AppLogo',
  props: {
    size: {
      type: String,
      default: 'normal',
    },
  },
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style scoped>
.app-logo img.large {
  width: 500px;
}
.app-logo img {
  width: 70px;
}
.app-logo img.small {
  width: 38px;
}
</style>
