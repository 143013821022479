/* eslint-disable @typescript-eslint/no-use-before-define */
import moment from 'moment'
import { Entity, makeEntity } from './people'
import { currency, dateToString } from '@/filters/texts'
import { makeSchoolYear, SchoolYear } from './schools'
import { makeChoice } from '@/types/base'
import { isNumber } from '@/utils/check'
import { sum } from '@/utils/math'
import { Field, makeField } from '@/types/fields'

export class InvoiceStatus {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export class SaleType {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export function makeSaleType(jsonData: any = null): SaleType {
  if (!jsonData) {
    jsonData = {}
  }
  return new SaleType(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

export class SaleGroup {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export function makeSaleGroup(jsonData: any = null): SaleGroup {
  if (!jsonData) {
    jsonData = {}
  }
  return new SaleGroup(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

export class Discount {
  constructor(
    public id: number,
    public amount: number,
    public percentage: number,
    public comments: string,
    public showPercentage: boolean
  ) {
  }

  public data(): any {
    return {
      id: this.id > 0 ? this.id : 0,
      amount: this.showPercentage ? 0 : this.amount,
      percentage: this.showPercentage ? this.percentage : 0,
      comments: this.comments,
    }
  }

  public clone(): Discount {
    return new Discount(
      this.id,
      this.amount,
      this.percentage,
      this.comments,
      this.showPercentage
    )
  }
}

export function makeDiscount(jsonData: any = null): Discount {
  if (!jsonData) {
    jsonData = {}
  }
  return new Discount(
    jsonData.id || 0,
    +jsonData.amount || 0,
    +jsonData.percentage || 0,
    jsonData.comments || '',
    (+jsonData.percentage || 0) !== 0
  )
}

export class ExtraSale {
  constructor(
    public id: number,
    public amount: number,
    public comments: string
  ) {
  }

  public data(): any {
    return {
      id: this.id > 0 ? this.id : 0,
      amount: this.amount,
      comments: this.comments,
    }
  }

  public clone(): ExtraSale {
    return new ExtraSale(
      this.id,
      this.amount,
      this.comments
    )
  }
}

export function makeExtraSale(jsonData: any = null): ExtraSale {
  if (!jsonData) {
    jsonData = {}
  }
  return new ExtraSale(
    jsonData.id || 0,
    +jsonData.amount || 0,
    jsonData.comments || ''
  )
}

export class AnalyticGroup {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export function makeAnalyticGroup(jsonData: any = null): AnalyticGroup {
  if (!jsonData) {
    jsonData = {}
  }
  return new AnalyticGroup(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

export enum AnalyticAccountShowYear {
  Season= 0,
  StartYear = 1,
  EndYear = 2
}

export function getAccountDisplayModeChoices() {
  return [
    makeChoice({ id: AnalyticAccountShowYear.Season, name: 'Saison', }),
    makeChoice({ id: AnalyticAccountShowYear.StartYear, name: 'Année de début de saison', }),
    makeChoice({ id: AnalyticAccountShowYear.EndYear, name: 'Année de fin de saison', })
  ]
}

export class AnalyticAccount {
  constructor(
    public id: number,
    public name: string,
    public label: string,
    public schoolYear: SchoolYear,
    public total: number | null,
    public showYear: AnalyticAccountShowYear,
    public group: AnalyticGroup | null,
    public isGroup: boolean,
    public order: number,
    public showForCash: boolean,
    public priority: number
  ) {
  }

  public getKey(): string {
    return '' + this.id + ':' + (this.schoolYear ? this.schoolYear.id : 0)
  }

  public getLabel(): string {
    let label
    if (this.id === 0) {
      label = 'Analytique manquant'
    } else {
      label = this.label || this.name
    }
    if (this.schoolYear && this.schoolYear.id) {
      label += ' ' + this.schoolYear.name
    }
    return label
  }

  public getCode(): string {
    let label
    if (this.id === 0) {
      label = 'Analytique manquant'
    } else {
      label = this.name || this.label
    }
    if (this.schoolYear && this.schoolYear.id) {
      label += ' ' + this.schoolYear.name
    }
    return label
  }

  public getDisplayMode(): string {
    switch (this.showYear) {
      case AnalyticAccountShowYear.Season:
        return 'Saison'
      case AnalyticAccountShowYear.StartYear:
        return 'Année de début de saison'
      case AnalyticAccountShowYear.EndYear:
        return 'Année de fin de saison'
    }
    return ''
  }
}

export function makeAnalyticAccount(jsonData: any = null): AnalyticAccount {
  if (!jsonData) {
    jsonData = {}
  }
  return new AnalyticAccount(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.label || '',
    makeSchoolYear(jsonData.school_year),
    isNumber(jsonData.total) ? jsonData.total : null,
    jsonData.show_year || AnalyticAccountShowYear.Season,
    jsonData.group ? makeAnalyticGroup(jsonData.group) : null,
    false,
    jsonData.order || 0,
    !!jsonData.show_for_cash,
    jsonData.priority || 0
  )
}

export class GeneralAccount {
  constructor(
    public id: number,
    public name: string,
    public label: string,
    public isExpense: boolean,
    public isPayment: boolean,
    public isTransfer: boolean,
    public isCashTransfer: boolean,
    public order: number
  ) {
  }

  public getLabel(): string {
    if (this.id === 0) {
      return 'Code manquant'
    } else {
      return this.label || this.name
    }
  }

  public getFullLabel(): string {
    if (this.id === 0) {
      return 'Code manquant'
    } else {
      return this.name + (this.label ? (' - ' + this.label) : '')
    }
  }

  public getDisplayMode() {
    return ''
  }
}

export function makeGeneralAccount(jsonData: any = null): GeneralAccount {
  if (!jsonData) {
    jsonData = {}
  }
  return new GeneralAccount(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.label || '',
    !!jsonData.is_expense,
    !!jsonData.is_payment,
    !!jsonData.is_transfer,
    !!jsonData.is_cash_transfer,
    jsonData.order || 0
  )
}

export class AnalyticItem {
  constructor(
    public analyticAccount: AnalyticAccount,
    public generalAccount: GeneralAccount,
    public schoolYear: SchoolYear
  ) {
  }

  public getKey(includeGeneral: boolean = true): string {
    const ids = [
      (this.schoolYear ? this.schoolYear.id : 0),
      (this.analyticAccount ? this.analyticAccount.id : 0)
    ]
    if (includeGeneral) {
      ids.push((this.generalAccount ? this.generalAccount.id : 0))
    }
    return ids.join(':')
  }

  public asText(): string {
    const schoolYear = this.getSchoolYearLabel()
    const general = this.getGeneralAccountLabel()
    const analytic = this.getAnalyticAccountLabel()
    return schoolYear + ' - ' + general + ' - ' + analytic
  }

  public getSchoolYearLabel(): string {
    return this.schoolYear ? this.schoolYear.name : ''
  }

  public getAnalyticAccountLabel(): string {
    return this.analyticAccount ? this.analyticAccount.getLabel() : ''
  }

  public getGeneralAccountLabel(): string {
    if (this.generalAccount) {
      return this.generalAccount.label || this.generalAccount.name
    }
    return ''
  }
}

export function makeAnalyticItem(jsonData: any = null): AnalyticItem {
  if (!jsonData) {
    jsonData = {}
  }
  return new AnalyticItem(
    makeAnalyticAccount(jsonData.analytic_account),
    makeGeneralAccount(jsonData.general_account),
    makeSchoolYear(jsonData.school_year)
  )
}

export class Sale {
  constructor(
    public id: number,
    public createdOn: Date,
    public applyOn: Date,
    public createdBy: string,
    public basePrice: number,
    public price: number,
    public label: string,
    public shortLabel: string,
    public saleType: SaleType,
    public group: SaleGroup,
    public trying: boolean,
    public cancellation: boolean,
    public cancellationConfirmed: boolean,
    public cancelledOn: Date | null,
    public cancelledAmount: number,
    public discountOn: Date | null,
    public refunded: boolean,
    public ignored: boolean,
    public discounts: Discount[],
    public analyticAccount: AnalyticAccount|null,
    public schoolYear: SchoolYear,
    public waiting: boolean,
    public unconfirmed: boolean
  ) {
  }

  public creationTime() {
    return moment(this.createdOn).format('HH:mm')
  }

  public analyticInfo() {
    const analytic = this.analyticAccount ? this.analyticAccount.name : 'non défini'
    return analytic + ' ' + this.schoolYear.name
  }
}

export function makeSale(jsonData: any = null): Sale {
  if (!jsonData) {
    jsonData = {}
  }
  const discounts = jsonData.discounts || []
  const analyticAccount = jsonData.analytic_account ? makeAnalyticAccount(jsonData.analytic_account) : null
  return new Sale(
    jsonData.id || 0,
    jsonData.created_on,
    jsonData.apply_on || jsonData.created_on,
    jsonData.created_by || '',
    +jsonData.base_price || 0,
    +jsonData.price || 0,
    jsonData.label || '',
    jsonData.short_label || '',
    makeSaleType(jsonData.sale_type),
    makeSaleGroup(jsonData.group),
    !!jsonData.trying,
    !!jsonData.cancellation,
    !!jsonData.cancellation_confirmed,
    jsonData.cancelled_on || null,
    jsonData.cancelled_amount || 0,
    jsonData.discount_on || null,
    !!jsonData.refunded,
    !!jsonData.ignored,
    discounts.map((elt: any) => makeDiscount(elt)),
    analyticAccount,
    makeSchoolYear(jsonData.school_year),
    !!jsonData.waiting,
    !!jsonData.unconfirmed
  )
}

export class SaleWithInvoice extends Sale {
  constructor(
    public id: number,
    public createdOn: Date,
    public applyOn: Date,
    public createdBy: string,
    public basePrice: number,
    public price: number,
    public label: string,
    public shortLabel: string,
    public saleType: SaleType,
    public group: SaleGroup,
    public trying: boolean,
    public cancellation: boolean,
    public cancellationConfirmed: boolean,
    public cancelledOn: Date | null,
    public cancelledAmount: number,
    public discountOn: Date | null,
    public refunded: boolean,
    public ignored: boolean,
    public discounts: Discount[],
    public analyticAccount: AnalyticAccount|null,
    public schoolYear: SchoolYear,
    public waiting: boolean,
    public unconfirmed: boolean,
    public invoice: Invoice|null
  ) {
    super(
      id, createdOn, applyOn, createdBy, basePrice, price, label, shortLabel, saleType, group, trying,
      cancellation, cancellationConfirmed, cancelledOn, cancelledAmount, discountOn, refunded, ignored, discounts,
      analyticAccount, schoolYear, waiting, unconfirmed
    )
  }

  public showInvoice() {
    return ((this.invoice !== null) || (!this.ignored && this.price))
  }
}

export function makeSaleWithInvoice(jsonData: any = null): SaleWithInvoice {
  if (!jsonData) {
    jsonData = {}
  }
  const discounts = jsonData.discounts || []
  return new SaleWithInvoice(
    jsonData.id || 0,
    jsonData.created_on,
    jsonData.apply_on || jsonData.created_on,
    jsonData.created_by || '',
    +jsonData.base_price || 0,
    +jsonData.price || 0,
    jsonData.label || '',
    jsonData.short_label || '',
    makeSaleType(jsonData.sale_type),
    makeSaleGroup(jsonData.group),
    !!jsonData.trying,
    !!jsonData.cancellation,
    !!jsonData.cancellation_confirmed,
    jsonData.cancelled_on || null,
    jsonData.cancelled_amount || 0,
    jsonData.discount_on || null,
    !!jsonData.refunded,
    !!jsonData.ignored,
    discounts.map((elt: any) => makeDiscount(elt)),
    jsonData.analytic_account ? makeAnalyticAccount(jsonData.analytic_account) : null,
    makeSchoolYear(jsonData.school_year),
    !!jsonData.waiting,
    !!jsonData.unconfirmed,
    jsonData.invoice ? makeInvoice(jsonData.invoice) : null
  )
}

export function calculatePrice(basePrice: number, discounts: Discount[]): number {
  let price = basePrice
  for (const discount of discounts) {
    if (discount.amount) {
      price -= discount.amount
    } else {
      price -= discount.percentage * basePrice / 100
    }
  }
  return Math.round(price * 100) / 100
}

export function calculateSoldPrice(sales: Sale[]): number {
  let price = 0
  for (const sale of sales) {
    price += sale.basePrice
    for (const discount of sale.discounts) {
      if (discount.amount) {
        price -= discount.amount
      } else {
        price -= discount.percentage * sale.basePrice / 100
      }
    }
  }
  return Math.round(price * 100) / 100
}

export class PaymentMode {
  constructor(
    public id: number,
    public name: string,
    public bank: boolean,
    public order: number,
    public isNumberRequired: boolean,
    public hasDeposit: boolean,
    public showEmitter: boolean,
    public canRefund: boolean,
    public isCash: boolean,
    public notStrict: boolean,
    public noDefaultDate: boolean,
    public refuseFuturePayments: boolean
  ) {
  }
}

export function makePaymentMode(jsonData: any = null): PaymentMode {
  if (!jsonData) {
    jsonData = {}
  }
  return new PaymentMode(
    jsonData.id || 0,
    jsonData.name || '',
    !!jsonData.bank,
    jsonData.order || 0,
    !!jsonData.is_number_required,
    !!jsonData.has_deposit,
    !!jsonData.show_emitter,
    !!jsonData.can_refund,
    !!jsonData.is_cash,
    !!jsonData.not_strict,
    !!jsonData.no_default_date,
    !!jsonData.refuse_future_payments
  )
}

export class AnalyticDetail {
  constructor(
    public analyticAccount: AnalyticAccount,
    public analyticGroup: AnalyticGroup,
    public generalAccount: GeneralAccount,
    public schoolYear: SchoolYear,
    public amount: number, // Ventes facturées
    public createdOn: Date|null = null,
    public cancelledAmount: number = 0, // Ventes annulées
    public noInvoiceAmount: number = 0, // Ventes à facturer
    public toBePaidAmount: number = 0, // Montant à payer
    public paymentContributions: number = 0,
    public futurePaymentContributions: number = 0,
    public creditContributions: number = 0,
    public cancellationCredits: number = 0,
    public overpaidCredits: number = 0,
    public remainingCredits: number = 0,
    public refundCredits: number = 0,
    public expensesAmount: number = 0,
    public usedCredits: number = 0,
    public periodUsedCredits: number = 0,
    public periodRefundCredits: number = 0
  ) {
  }

  public getKey(includeGeneral: boolean = true): string {
    const ids = [this.schoolYear.id, this.analyticAccount.id]
    if (includeGeneral) {
      ids.push(this.generalAccount.id)
    }
    return ids.join(':')
  }

  public getLabel(): string {
    return this.analyticAccount.getLabel() + ' ' + this.schoolYear.name
  }

  public getFullLabel(): string {
    return this.generalAccount.name + ' ' + this.analyticAccount.getLabel() + ' ' + this.schoolYear.name
  }

  public getPaid() {
    return this.paymentContributions + this.creditContributions
  }

  public getToBePaid() {
    return this.toBePaidAmount
  }

  public calcToBePaid() {
    const sold = this.amount - this.cancelledAmount
    const paid = this.getPaid() + this.futurePaymentContributions
    const overpaid = this.getCreditOrigin()
    return sold + overpaid - paid
  }

  public getMoneyPayments(): number {
    return this.paymentContributions + this.overpaidCredits
  }

  public getFutureMoneyPayments(): number {
    return this.futurePaymentContributions
  }

  public delta(): number {
    const sold = this.amount - this.cancelledAmount
    const paid = this.getPaid() + this.futurePaymentContributions + this.getToBePaid()
    const overpaid = this.getCreditOrigin()
    const delta = sold + overpaid - paid
    return Math.round(delta * 100) / 100
  }

  public getCreditPayments(): number {
    return this.creditContributions
  }

  public getAllPayments(): number {
    return this.getToBePaid() + this.getMoneyPayments() + this.getCreditPayments()
  }

  public getCreditOrigin(): number {
    return this.cancellationCredits + this.overpaidCredits
  }

  public getAvailableCredits(): number {
    return this.remainingCredits
  }

  public getUsedCredits(): number {
    return this.usedCredits
  }

  public getRefund(): number {
    return this.refundCredits
  }

  public getPeriodUsedCredits(): number {
    return this.periodUsedCredits
  }

  public getPeriodRefund(): number {
    return this.periodRefundCredits
  }

  public clone(): AnalyticDetail {
    return new AnalyticDetail(
      this.analyticAccount,
      this.analyticGroup,
      this.generalAccount,
      this.schoolYear,
      this.amount,
      this.createdOn
    )
  }

  public initGroup(): AnalyticDetail {
    return new AnalyticDetail(
      makeAnalyticAccount({ id: -this.analyticGroup.id, name: this.analyticGroup.name, }),
      makeAnalyticGroup(),
      makeGeneralAccount(),
      this.schoolYear,
      this.amount,
      null,
      this.cancelledAmount,
      this.noInvoiceAmount,
      this.toBePaidAmount,
      this.paymentContributions,
      this.futurePaymentContributions,
      this.creditContributions,
      this.cancellationCredits,
      this.overpaidCredits,
      this.remainingCredits,
      this.refundCredits,
      this.expensesAmount,
      this.usedCredits,
      this.periodUsedCredits,
      this.periodRefundCredits
    )
  }

  public add(elt: AnalyticDetail) {
    this.amount += elt.amount
    this.cancelledAmount += elt.cancelledAmount
    this.noInvoiceAmount += elt.noInvoiceAmount
    this.toBePaidAmount += elt.toBePaidAmount
    this.paymentContributions += elt.paymentContributions
    this.futurePaymentContributions += elt.futurePaymentContributions
    this.creditContributions += elt.creditContributions
    this.cancellationCredits += elt.cancellationCredits
    this.overpaidCredits += elt.overpaidCredits
    this.remainingCredits += elt.remainingCredits
    this.refundCredits += elt.refundCredits
    this.expensesAmount += elt.expensesAmount
    this.usedCredits += elt.usedCredits
    this.periodUsedCredits += elt.periodUsedCredits
    this.periodRefundCredits += elt.periodRefundCredits
  }

  public getYear(): string {
    switch (this.analyticAccount.showYear) {
      case AnalyticAccountShowYear.StartYear:
        return '' + this.schoolYear.startYear
      case AnalyticAccountShowYear.EndYear:
        return '' + (this.schoolYear.startYear + 1)
      default:
        return this.schoolYear.name
    }
  }
}

export function makeAnalyticDetail(jsonData: any = null): AnalyticDetail {
  if (!jsonData) {
    jsonData = {}
  }
  return new AnalyticDetail(
    makeAnalyticAccount(jsonData.analytic_account),
    makeAnalyticGroup(jsonData.analytic_account__group || null),
    makeGeneralAccount(jsonData.general_account),
    makeSchoolYear(jsonData.school_year),
    +(jsonData.amount || jsonData.sales_amount || 0),
    jsonData.created_on ? jsonData.created_on : null,
    +(jsonData.cancelled_sales_amount || 0),
    +(jsonData.no_invoice_amount || 0),
    +(jsonData.to_be_paid_amount || 0),
    +(jsonData.payment_contributions || 0),
    +(jsonData.future_payment_contributions || 0),
    +(jsonData.credit_contributions || 0),
    +(jsonData.cancellation_credits || 0),
    +(jsonData.overpaid_credits || 0),
    +(jsonData.remaining_credits || 0),
    +(jsonData.refund_credits || 0),
    +(jsonData.expenses_amount || 0),
    +(jsonData.used_credits || 0),
    +(jsonData.period_used_credits || 0),
    +(jsonData.period_refund_credits || 0)
  )
}

export class Refund {
  constructor(
    public id: number,
    public createdOn: Date,
    public createdBy: string,
    public comments: string,
    public refundMode: PaymentMode,
    public bankName: string,
    public bankNumber: string,
    public amount: number,
    public analytics: AnalyticDetail[],
    public entity: Entity
  ) {
  }

  public creationTime() {
    return moment(this.createdOn).format('HH:mm')
  }

  public sourceAnalytics(): AnalyticDetail[] {
    return this.analytics.filter((elt: AnalyticDetail) => elt.amount > 0)
  }

  public destAnalytics(): AnalyticDetail[] {
    return this.analytics.filter(
      (elt: AnalyticDetail) => elt.amount < 0
    ).map(
      (elt: AnalyticDetail) => {
        const dest = elt.clone()
        dest.amount = -dest.amount
        return dest
      }
    )
  }

  public asAnalyticDetail(): AnalyticDetail {
    return new AnalyticDetail(
      makeAnalyticAccount({ id: -99999999, name: 'Remboursement', }),
      makeAnalyticGroup(),
      makeGeneralAccount(),
      makeSchoolYear(),
      this.amount,
      this.createdOn
    )
  }
}

export function makeRefund(jsonData: any = null): Refund {
  if (!jsonData) {
    jsonData = {}
  }
  let analytics = []
  if (jsonData.analytics) {
    analytics = jsonData.analytics.map((elt: any) => makeAnalyticDetail(elt))
  }
  return new Refund(
    jsonData.id || 0,
    jsonData.created_on,
    jsonData.created_by || '',
    jsonData.comments || '',
    makePaymentMode(jsonData.refund_mode),
    jsonData.bank_name || '',
    jsonData.bank_number || '',
    +jsonData.amount || 0,
    analytics,
    makeEntity(jsonData.entity)
  )
}

export class CreditContribution {
  constructor(
    public id: number,
    public createdOn: Date,
    public amount: number
  ) {
  }
}

export class Credit {
  constructor(
    public id: number,
    public createdOn: Date,
    public createdBy: string,
    public amount: number,
    public remainingAmount: number,
    public childrenAmount: number,
    public refundAmount: number,
    public source: string,
    public refund: Refund|null,
    public entity: Entity|null,
    public invoices: Invoice[],
    public comments: string,
    public ignored: boolean,
    public analytics: AnalyticDetail[],
    public fromAnalytics: AnalyticDetail[],
    public isOverpaid: boolean
  ) {
  }

  public creationTime() {
    return moment(this.createdOn).format('HH:mm')
  }

  public isAvailable(): boolean {
    return this.remainingAmount > 0
  }

  public getName(): string {
    return currency(this.remainingAmount) + ' du ' + dateToString(this.createdOn)
  }
}

export function makeCredit(jsonData: any = null): Credit {
  if (!jsonData) {
    jsonData = {}
  }
  let analytics = []
  if (jsonData.analytics) {
    analytics = jsonData.analytics.map((elt: any) => makeAnalyticDetail(elt))
  }
  let fromAnalytics = []
  if (jsonData.from_analytics) {
    fromAnalytics = jsonData.from_analytics.map((elt: any) => makeAnalyticDetail(elt))
  }
  let invoices = []
  if (jsonData.invoices) {
    invoices = jsonData.invoices.map((elt: any) => makeInvoice(elt))
  }
  let contributions = []
  if (jsonData.contributions) {
    contributions = jsonData.contributions.map(
      (elt: any) => new CreditContribution(elt.id || 0, elt.created_on, +elt.amount || 0)
    )
  }
  return new Credit(
    jsonData.id || 0,
    jsonData.created_on,
    jsonData.created_by || '',
    +jsonData.amount || 0,
    +jsonData.remaining_amount || 0,
    +jsonData.children_amount || 0,
    +jsonData.refund_amount || 0,
    jsonData.source || '',
    jsonData.refund ? makeRefund(jsonData.refund) : null,
    makeEntity(jsonData.entity),
    invoices,
    jsonData.comments || '',
    !!jsonData.ignored,
    analytics,
    fromAnalytics,
    !!jsonData.is_overpaid
  )
}

export class Deposit {
  constructor(
    public id: number,
    public number: string,
    public depositOn: Date,
    public comments: string,
    public paymentModes: PaymentMode[],
    public paymentsAmount: number,
    public paymentsCount: number,
    public expensesAmount: number,
    public expensesCount: number,
    public depositExportUrl: string
  ) {
  }

  public label(): string {
    let label: string = ''
    if (this.number) {
      label = 'N°' + this.number
    }
    if (this.depositOn) {
      if (label) {
        label += ' '
      }
      label += dateToString(this.depositOn, 'DD/MM/YYYY')
    }
    return label
  }
}

export function makeDeposit(jsonData: any = null): Deposit {
  if (!jsonData) {
    jsonData = {}
  }
  const paymentModes = jsonData.payment_modes || []
  return new Deposit(
    jsonData.id || 0,
    jsonData.number || '',
    jsonData.deposit_on,
    jsonData.comments || '',
    paymentModes.map((elt: any) => makePaymentMode(elt)),
    +(jsonData.payments_amount || 0),
    +(jsonData.payments_count || 0),
    +(jsonData.expenses_amount || 0),
    +(jsonData.expenses_count || 0),
    jsonData.deposit_export_url || ''
  )
}

export class Payment {
  constructor(
    public id: number,
    public createdOn: Date,
    public createdBy: string,
    public amount: number,
    public comments: string,
    public paymentMode: PaymentMode,
    public emitter: string,
    public bankName: string,
    public bankNumber: string,
    public paymentDate: Date|null,
    public verificationDate: Date,
    public operationCode: string,
    public credits: Credit[],
    public invoices: Invoice[],
    public isVerified: boolean,
    public entity: Entity,
    public deposit: Deposit,
    public analytics: AnalyticDetail[],
    public contributions: Contribution[],
    public returned: boolean,
    public isVisible: boolean
  ) {
  }

  public paidOn(): Date {
    return this.paymentDate ? this.paymentDate : this.createdOn
  }

  public delayedPayement(): boolean {
    return this.paymentDate !== this.createdOn
  }

  public getLabel(): string {
    const text = '' + this.paymentMode.name + ' ' + this.bankName + ' ' + this.bankNumber
    return text.trim()
  }

  public getReturnedStatus(): string {
    if (this.returned) {
      if (this.deposit && this.deposit.id) {
        return 'refusé par la banque'
      } else {
        return 'rendu'
      }
    } else {
      return ''
    }
  }

  public isValid(): boolean {
    if (!this.paymentDate) {
      return false
    }
    if (this.paymentMode.id === 0) {
      return false
    }
    if (+this.amount === 0) {
      return false
    } else {
      let isOk: boolean = true
      if (this.paymentMode.bank) {
        isOk = this.bankName.length > 0
      }
      if (isOk && this.paymentMode.isNumberRequired) {
        isOk = this.bankNumber.length > 0
      }
      if (isOk && this.paymentMode.refuseFuturePayments) {
        const today = moment().format('YYYY-MM-DD')
        let diff = moment(today).diff(moment(this.paymentDate), 'days')
        if (diff < 0) {
          isOk = false
        }
      }
      if (!isOk) {
        return false
      }
    }
    return true
  }
}

export function makePayment(jsonData: any = null): Payment {
  if (!jsonData) {
    jsonData = {}
  }
  const credits = jsonData.credits || []
  let analytics = []
  if (jsonData.analytics) {
    analytics = jsonData.analytics.map((elt: any) => makeAnalyticDetail(elt))
  }
  let invoices = []
  if (jsonData.invoices) {
    invoices = jsonData.invoices.map((elt: any) => makeInvoice(elt))
  }
  let contributions = []
  if (jsonData.contributions) {
    contributions = jsonData.contributions.map((elt: any) => makeContribution(elt))
  }
  return new Payment(
    jsonData.id || 0,
    jsonData.created_on,
    jsonData.created_by || '',
    +jsonData.amount || 0,
    jsonData.comments || '',
    makePaymentMode(jsonData.payment_mode),
    jsonData.emitter || '',
    jsonData.bank_name || '',
    jsonData.bank_number || '',
    jsonData.payment_date || null,
    jsonData.verification_date,
    jsonData.operation_code || '',
    credits.map((elt: any) => makeCredit(elt)),
    invoices,
    !!jsonData.is_verified,
    makeEntity(jsonData.entity),
    makeDeposit(jsonData.deposit),
    analytics,
    contributions,
    !!jsonData.returned,
    !!jsonData.is_visible
  )
}

export function sumPayments(payments: Payment[]) {
  return payments.reduce((sum, payment) => sum + (+payment.amount), 0)
}

export class Contribution {
  constructor(
    public id: number,
    public createdOn: Date,
    public createdBy: string,
    public amount: number,
    public payment: Payment|null,
    public credit: Credit|null,
    public invoice: Invoice|null
  ) {
  }
}

export function makeContribution(jsonData: any = null): Contribution {
  if (!jsonData) {
    jsonData = {}
  }
  return new Contribution(
    jsonData.id || 0,
    jsonData.created_on,
    jsonData.created_by || '',
    +jsonData.amount || 0,
    jsonData.payment ? makePayment(jsonData.payment) : null,
    jsonData.credit ? makeCredit(jsonData.credit) : null,
    jsonData.invoice ? makeInvoice(jsonData.invoice) : null
  )
}

export class Cancellation {
  constructor(
    public id: number,
    public createdOn: Date,
    public createdBy: string,
    public amount: number,
    public sale: Sale,
    public credit: Credit | null
  ) {
  }
}

export function makeCancellation(jsonData: any = null): Cancellation {
  if (!jsonData) {
    jsonData = {}
  }
  return new Cancellation(
    jsonData.id || 0,
    jsonData.created_on,
    jsonData.created_by || '',
    +jsonData.amount || 0,
    makeSale(jsonData.sale),
    jsonData.credit ? makeCredit(jsonData.credit) : null
  )
}

export class InvoiceFreezer {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export function makeInvoiceFreezer(jsonData: any = null): InvoiceFreezer {
  if (!jsonData) {
    jsonData = {}
  }
  return new InvoiceFreezer(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

export class Invoice {
  constructor(
    public id: number,
    public prefix: string,
    public invoiceNumber: number,
    public createdOn: Date,
    public createdBy: string,
    public status: string,
    public totalPrice: number,
    public paidPrice: number,
    public isSent: boolean,
    public isPaid: boolean,
    public isVerified: boolean,
    public isCancelled: boolean,
    public entity: Entity|null,
    public sales: Sale[],
    public payments: Payment[],
    public credits: Credit[],
    public webPaymentUrl: string,
    public fakeUuid: string,
    public contributions: Contribution[],
    public cancellations: Cancellation[],
    public analytics: AnalyticDetail[],
    public lastPaymentOn: Date|null,
    public frozen: number,
    public freezers: InvoiceFreezer[],
    public running: boolean // pré-facturation
  ) {
  }

  public clone(): Invoice {
    return new Invoice(
      this.id,
      this.prefix,
      this.invoiceNumber,
      this.createdOn,
      this.createdBy,
      this.status,
      this.totalPrice,
      this.paidPrice,
      this.isSent,
      this.isPaid,
      this.isVerified,
      this.isCancelled,
      this.entity,
      this.sales,
      this.payments,
      this.credits,
      this.webPaymentUrl,
      this.fakeUuid,
      this.contributions,
      this.cancellations,
      this.analytics,
      this.lastPaymentOn,
      this.frozen,
      this.freezers,
      this.running
    )
  }

  public toBePaidPrice(): number {
    if (this.frozen) {
      return 0
    }
    return this.totalPrice - this.paidPrice
  }

  public showFrozen(): boolean {
    return !!(this.frozen || ((this.freezers.length > 0) && this.toBePaidPrice()))
  }

  public getStyle(): string {
    if (this.isCancelled) {
      return 'cancelled-invoice'
    }
    if (this.running) {
      return 'running-invoice'
    }
    if (this.frozen) {
      return 'frozen-invoice'
    }
    if (this.isVerified) {
      return 'verified-invoice'
    }
    if (this.paidPrice > this.totalPrice) {
      return 'credit-invoice'
    }
    if (this.isPaid) {
      return 'paid-invoice'
    }
    if (this.isSent) {
      return 'sent-invoice'
    }
    if (this.totalPrice) {
      return 'new-invoice'
    }
    return 'send-invoice'
  }

  public totalCredits() {
    let totalCredits = 0
    for (const cancellation of this.cancellations) {
      totalCredits += cancellation.credit ? cancellation.credit.amount : 0
    }
    return totalCredits
  }

  public totalPayments() {
    let total = 0
    for (const contribution of this.contributions) {
      total += contribution.amount
    }
    return total
  }

  public creationTime() {
    return moment(this.createdOn).format('HH:mm')
  }

  public lastChange() {
    let lastChange = this.createdOn
    for (const sale of this.sales) {
      const cancelledOn = sale.cancelledOn || sale.discountOn
      if (cancelledOn && moment(cancelledOn) > moment(lastChange)) {
        lastChange = cancelledOn
      }
    }
    return lastChange
  }

  public getValidWebPayementUrl(uuid: string): string {
    return this.webPaymentUrl.replace(this.fakeUuid, uuid)
  }

  public getLabel(): string {
    let label = this.status
    const toBePaid = this.toBePaidPrice()
    if (toBePaid) {
      label += ' ' + currency(Math.abs(toBePaid))
    }
    return label
  }

  public getName(): string {
    return this.getNumber() + ' À Payer ' + currency(Math.abs(this.toBePaidPrice()))
  }

  public getNumber(): string {
    if (this.running) {
      return ''
    }
    return 'N°' + this.prefix + this.invoiceNumber
  }
}

export function makeInvoice(jsonData: any = null): Invoice {
  if (!jsonData) {
    jsonData = {}
  }
  const entity = jsonData.entity || null
  const sales = jsonData.sales || []
  const payments = jsonData.payments || []
  const credits = jsonData.credits || []
  let analytics = []
  if (jsonData.analytics) {
    analytics = jsonData.analytics.map((elt: any) => makeAnalyticDetail(elt))
  }
  const contributions = jsonData.contributions || []
  const cancellations = jsonData.cancellations || []
  const freezers = jsonData.freezers || []
  return new Invoice(
    jsonData.id || 0,
    jsonData.prefix || '',
    jsonData.number || 0,
    jsonData.created_on,
    jsonData.created_by || '',
    jsonData.status || '',
    Math.round(100 * (+jsonData.total_price || 0)) / 100,
    Math.round(100 * (+jsonData.paid_price || 0)) / 100,
    !!jsonData.is_sent,
    !!jsonData.is_paid,
    !!jsonData.is_verified,
    !!jsonData.is_cancelled,
    entity !== null ? makeEntity(entity) : null,
    sales.map((elt: any) => makeSale(elt)),
    payments.map((elt: any) => makePayment(elt)),
    credits.map((elt: any) => makeCredit(elt)),
    jsonData.web_payment_url || '',
    jsonData.fake_uuid || '',
    contributions.map((elt: any) => makeContribution(elt)),
    cancellations.map((elt: any) => makeCancellation(elt)),
    analytics,
    jsonData.last_payment || null,
    jsonData.frozen || 0,
    freezers.map((elt: any) => makeInvoiceFreezer(elt)),
    !!jsonData.running
  )
}

export class PaymentDate {
  constructor(
    public value: string,
    public label: string,
    public date: Date|null = null
  ) {
  }

  public getLabel(): string {
    if (this.date) {
      return dateToString(this.date, 'DD MMMM YYYY')
    } else {
      return this.label
    }
  }
}

export function makePaymentDate(jsonData: any = null): PaymentDate {
  if (!jsonData) {
    jsonData = {}
  }
  return new PaymentDate(
    jsonData.value || '',
    jsonData.label || '',
    jsonData.date || null
  )
}

class InvoicingLine {
  constructor(
    public entity: Entity,
    public count: number,
    public sum: number
  ) {
  }
}

export function makeInvoicingLine(jsonData: any = null): InvoicingLine {
  if (!jsonData) {
    jsonData = {}
  }
  return new InvoicingLine(
    makeEntity(jsonData.entity),
    jsonData.count,
    jsonData.sum
  )
}

export class Reward {
  constructor(
    public id: number,
    public createdOn: Date,
    public applyOn: Date,
    public createdBy: string,
    public price: number,
    public label: string,
    public cancelled: boolean,
    public analyticAccount: AnalyticAccount|null,
    public schoolYear: SchoolYear
  ) {
  }

  public creationTime() {
    return moment(this.createdOn).format('HH:mm')
  }
}

export function makeReward(jsonData: any = null): Reward {
  if (!jsonData) {
    jsonData = {}
  }
  const analyticAccount = jsonData.analytic_account ? makeAnalyticAccount(jsonData.analytic_account) : null
  return new Reward(
    jsonData.id || 0,
    jsonData.created_on,
    jsonData.apply_on || jsonData.created_on,
    jsonData.created_by || '',
    +jsonData.price || 0,
    jsonData.label || '',
    !!jsonData.cancelled,
    analyticAccount,
    makeSchoolYear(jsonData.school_year)
  )
}

export class PaymentSynthesisItem {
  constructor(
    public analyticAccount: AnalyticAccount,
    public schoolYear: SchoolYear,
    public sold: number = 0,
    public invoicing: number = 0,
    public paid: number = 0,
    public paidByCredit: number = 0,
    public cancelled: number = 0,
    public refunded: number = 0,
    public rewards: number = 0
  ) {
  }

  public key() {
    const analyticAccountId = this.analyticAccount ? this.analyticAccount.id : 0
    return '' + analyticAccountId + '-' + this.schoolYear.id
  }

  public cancelledTotal(): number {
    return this.cancelled - this.refunded
  }

  public due(): number {
    return (this.sold + this.invoicing + this.cancelled) - (this.paid + this.paidByCredit)
  }
}

export class PaymentSynthesis {
  constructor(
    public items: PaymentSynthesisItem[],
    public remainingCredits: number,
    public dueTotalSinceStartDate: number,
    public startDate: Date,
    public sums: PaymentSynthesisItem,
    public schoolYears: SchoolYear[]
  ) {

  }

  public dueTotal(): number {
    return Math.round(100 * sum(this.items.map(elt => elt.due()))) / 100
  }

  public refundedTotal(): number {
    return Math.round(100 * sum(this.items.map(elt => elt.refunded))) / 100
  }

  public hasRewards(): boolean {
    return this.items.filter(elt => elt.rewards).length > 0
  }

  public getSchoolYears(): string {
    return this.schoolYears.map(elt => elt.name).join(' - ')
  }
}

export function makePaymentSynthesis(jsonData: any = null): PaymentSynthesis {
  if (!jsonData) {
    jsonData = {}
  }
  const analyticAccounts = jsonData.analytic_accounts.map((elt: any) => makeAnalyticAccount(elt))
  const schoolYears = jsonData.school_years.map((elt: any) => makeSchoolYear(elt))

  const analyticAccountsMap = new Map()
  analyticAccountsMap.set(0, makeAnalyticAccount())
  for (const analyticAccount of analyticAccounts) {
    analyticAccountsMap.set(analyticAccount.id, analyticAccount)
  }

  const schoolYearsMap = new Map()
  for (const schoolYear of schoolYears) {
    schoolYearsMap.set(schoolYear.id, schoolYear)
  }

  const itemsMap = new Map()
  const items: PaymentSynthesisItem[] = []
  const sums: any = new PaymentSynthesisItem(
    makeAnalyticAccount(),
    makeSchoolYear()
  )
  const fields = ['sales', 'contributions', 'cancellations', 'rewards']
  const subFields = [
    { 'total': 'sold', 'invoicing': 'invoicing', },
    { 'total': 'paid', 'credits': 'paidByCredit', },
    { 'total': 'cancelled', 'refund': 'refunded', },
    { 'total': 'rewards', }
  ]
  for (let index = 0; index < fields.length; index++) {
    const field = fields[index]
    for (const elt of jsonData[field]) {
      const key = '' + (elt.analytic_account || 0) + '-' + (elt.school_year || 0)
      let item = null
      if (itemsMap.has(key)) {
        item = itemsMap.get(key)
      } else {
        item = new PaymentSynthesisItem(
          analyticAccountsMap.get(elt.analytic_account) || makeAnalyticAccount(),
          schoolYearsMap.get(elt.school_year) || makeSchoolYear()
        )
        itemsMap.set(key, item)
        items.push(item)
      }
      const subFieldsObj: any = subFields[index]
      for (const fromField of Object.keys(subFieldsObj)) {
        const toField = subFieldsObj[fromField]
        item[toField] += elt[fromField]
        sums[toField] += elt[fromField]
      }
    }
  }

  return new PaymentSynthesis(
    items,
    jsonData.credits_remaining_amount || 0,
    jsonData.due_total_since_start_date || 0,
    jsonData.start_date,
    sums,
    (jsonData.active_school_years || []).map(makeSchoolYear)
  )
}

export class MultiInvoicingActionField {
  constructor(
    public id: number,
    public name: string,
    public label: string,
    public type: string,
    public help: string,
    public order: number
  ) {
  }
}

export class MultiInvoicingAction {
  constructor(
    public id: number,
    public label: string,
    public url: string,
    public icon: string,
    public fields: MultiInvoicingActionField[]
  ) {
  }
}

export function makeMultiInvoicingAction(jsonData: any = null): MultiInvoicingAction {
  if (!jsonData) {
    jsonData = {}
  }
  const fields = jsonData.fields || []
  return new MultiInvoicingAction(
    jsonData.id || 0,
    jsonData.label || '',
    jsonData.url || '',
    jsonData.icon || '',
    fields.map(
      (subElt: any) => {
        return new MultiInvoicingActionField(
          subElt.id,
          subElt.field_name,
          subElt.field_label,
          subElt.field_type,
          subElt.field_help,
          subElt.order
        )
      }
    )
  )
}

export enum MultiInvoicingTable {
   All = 0,
   Todo = 1,
   Invoices = 2
}

export class MultiInvoicingFilter {
  constructor(
    public id: number,
    public label: string,
    public table: MultiInvoicingTable,
    public field: Field
  ) {
  }

  public forTodo(): boolean {
    return ((this.table === MultiInvoicingTable.Todo) || (this.table === MultiInvoicingTable.All))
  }

  public forInvoices(): boolean {
    return ((this.table === MultiInvoicingTable.Invoices) || (this.table === MultiInvoicingTable.All))
  }
}

export function makeMultiInvoicingFilter(jsonData: any = null): MultiInvoicingFilter {
  if (!jsonData) {
    jsonData = {}
  }
  return new MultiInvoicingFilter(
    jsonData.id || 0,
    jsonData.label || '',
    jsonData.table || MultiInvoicingTable.All,
    makeField(jsonData.field)
  )
}
