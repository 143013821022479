<template>
  <div class="add-shortcut-modal" v-if="canView">
    <b-modal
      id="bv-edit-shortcut-modal"
      cancel-title="Annuler"
      :ok-disabled="!isValid"
      @ok="onSave"
      @show="onShow"
      ok-variant="primary"
      ok-title="Ajouter"
      size="lg"
    >
      <template v-slot:modal-title>
        <b v-if="obj">
          <i class="fa fa-cog"></i>
          Modifier {{ obj.name }}
        </b>
      </template>
      <div class="form-group">
        <b-row>
          <b-col>
            <b-form-group label-for="name" label="Titre">
              <b-form-input type="text" v-model="name"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <color-select
              :background="backgroundColor"
              :color="textColor"
              @selected="getSelectedColor"
            ></color-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="kind === 'block'">
            <b-form-group label-for="page" label="Page">
              <b-form-select v-model="page">
                <b-form-select-option
                  v-for="elt of pages"
                  :key="elt.id"
                  :value="elt.id"
                >
                  {{ elt.name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col v-if="kind === 'block'">
            <b-form-group label-for="column" label="Colonne">
              <b-form-select v-model="column">
                <b-form-select-option
                  v-for="elt of columns"
                  :key="elt.id"
                  :value="elt.id"
                >
                  {{ elt.name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col v-if="kind === 'shortcut'">
            <b-form-group label-for="block" label="Bloc">
              <b-form-select v-model="block">
                <b-form-select-option
                  v-for="elt of blocks"
                  :key="elt.id"
                  :value="elt.id"
                >
                  {{ elt.name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label-for="order" label="Position">
              <b-form-select v-model="order">
                <b-form-select-option
                  v-for="elt of orders"
                  :key="elt.id"
                  :value="elt.id"
                >
                  {{ elt.name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-textarea v-model="description" id="description"></b-textarea>
            <div class="help-text">un texte additionnel</div>
          </b-col>
        </b-row>
      </div>
      <template v-slot:modal-footer>
        <div class="text-right edit-shortcut-footer">
          <b-button
            class="btn-md"
            variant="danger"
            @click="onDelete"
          >
            Supprimer
          </b-button>
          <b-button
            class="btn-md"
            variant="secondary"
            @click.prevent="onCancel()"
          >
            Annuler
          </b-button>
          <b-button
            class="btn-md"
            variant="primary"
            @click.prevent="onSave"
          >
            Enregistrer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ColorSelect from '@/components/Controls/ColorSelect.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { max } from '@/utils/math'

export default {
  name: 'edit-shortcut-object-modal',
  components: { ColorSelect, },
  mixins: [BackendMixin],
  props: {
    blocks: Array,
    siblings: Array,
    obj: Object,
    kind: String,
  },
  data() {
    return {
      name: '',
      icon: '',
      path: '',
      description: '',
      backgroundColor: '',
      textColor: '',
      order: 0,
      page: 0,
      column: 0,
      block: 0,
    }
  },
  computed: {
    canView() {
      if (this.kind === 'shortcut') {
        return this.hasPerm('home.change_shortcut')
      } else {
        return this.hasPerm('home.change_shortcutblock')
      }
    },
    isValid() {
      return !!this.name
    },
    orders() {
      if (this.obj) {
        let siblings = []
        const blockId = this.block
        const pageId = this.page
        const columnId = this.column
        let extra = 0
        if (this.kind === 'shortcut') {
          const blocks = this.blocks.filter(elt => elt.id === blockId)
          if (this.block !== this.obj.block) {
            extra = 1
          }
          if (blocks.length) {
            const block = blocks[0]
            siblings = block.shortcuts.length
          }
        } else {
          const blocks = this.blocks.filter(elt => (elt.page === pageId) && (elt.column === columnId))
          const changed = ((pageId !== this.obj.page) || (columnId !== this.obj.column))
          if (changed) {
            extra = 1
          }
          siblings = blocks.length
        }
        siblings += extra
        return Array.from(Array(siblings).keys()).map(
          elt => {
            return { id: elt, name: '' + (elt + 1), }
          }
        )
      }
      return []
    },
    pages() {
      let maxPage = 0
      if (this.blocks.length) {
        maxPage = max(this.blocks.map(elt => (elt.page + 1)))
      }
      maxPage += 1
      return Array.from(Array(maxPage).keys()).map(
        elt => { return { id: elt, name: '' + (elt + 1), } }
      )
    },
    columns() {
      return [0, 1, 2, 3, 4, 5].map(elt => { return { id: elt, name: '' + ('Colonne ' + (elt + 1)), } })
    },
  },
  mounted() {
  },
  watch: {
    obj: function() {
      this.onShow()
    },
    orders: function() {
      if (this.orders.length) {
        this.order = this.orders[this.orders.length - 1].id
      }
    },
    block: function() {},
    page: function() {},
    column: function() {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    onShow() {
      if (this.obj) {
        this.name = this.obj.name
        this.order = this.obj.order
        this.backgroundColor = this.obj.backgroundColor
        this.textColor = this.obj.textColor
        this.description = this.obj.description
        if (this.kind === 'block') {
          this.page = this.obj.page
          this.column = this.obj.column
        } else {
          this.block = this.obj.block
        }
      }
    },
    async onSave() {
      if (this.isValid) {
        let url = ''
        let text = ''
        const data = {
          name: this.name,
          background_color: this.backgroundColor,
          text_color: this.textColor,
          order: this.order,
          description: this.description,
        }
        if (this.kind === 'shortcut') {
          url = '/api/home/shortcuts/' + this.obj.id + '/'
          text = 'Le raccourci a été modifié'
          data.block = this.block
        } else {
          url = '/api/home/shortcuts-blocks/' + this.obj.id + '/'
          text = 'Le bloc a été modifié'
          data.page = this.page
          data.column = this.column
        }
        const backendApi = new BackendApi('patch', url)
        try {
          await backendApi.callApi(data)
          await this.addSuccess(text)
          this.$emit('done')
          this.$bvModal.hide('bv-edit-shortcut-modal')
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async onDelete() {
      let url = ''
      let text = ''
      if (this.kind === 'shortcut') {
        url = '/api/home/shortcuts/' + this.obj.id + '/'
        text = 'Le raccourci a été supprimé'
      } else {
        url = '/api/home/shortcuts-blocks/' + this.obj.id + '/'
        text = 'Le bloc a été supprimé'
      }
      const backendApi = new BackendApi('delete', url)
      try {
        await backendApi.callApi()
        await this.addSuccess(text)
        this.$emit('done')
        this.$bvModal.hide('bv-edit-shortcut-modal')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getSelectedColor(event) {
      this.backgroundColor = event.color.background
      this.textColor = event.color.text
    },
    onCancel() {
      this.$bvModal.hide('bv-edit-shortcut-modal')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.edit-shortcut-footer button {
  margin-left: 4px;
}
</style>
