<template>
  <div class="home">
    <page-header title="Accueil" icon="fa fa-home"></page-header>
    <b-row>
      <b-col cols="3">
        <div class="home1">
          <ul class="app-menu">
            <li>
              <b>version {{ version }}</b>
              <a @click.prevent="reloadApp"><i class="fa fa-redo"></i></a>
            </li>
            <li v-if="hasPerm('families.view_familyentity')">
              <router-link :to="{ name: 'families-list', }"><i class="fa-solid fa-people-roof"></i> Familles</router-link>
            </li>
            <li v-if="hasPerm('people.view_contact')">
              <router-link :to="{ name: 'entities-list', }"><i class="fa fa-address-book"></i> Annuaire</router-link>
            </li>
            <li v-if="activityMenus.length === 0 && hasPerm('activities.view_coreactivity')">
              <router-link :to="{ name: 'activities-list', }">
                <i class="fas fa-chess-knight"></i> Activités
              </router-link>
            </li>
            <li
              v-for="menu of activityMenus"
              :key="menu.id"
            >
              <router-link :to="{ name: 'activities-list', query: { category: menu.category, }, }">
                <i :class="menu.icon"></i> {{ menu.label }}
              </router-link>
            </li>
            <li v-if="hasPerm('youth.view_seance')">
              <router-link :to="{ name: 'youth-homes-list', }">
                <i :class="youthHomeIcon"></i> {{youthHomeLabel}}
              </router-link>
            </li>
            <li v-if="isPortalActive && hasPerm('portal')">
              <router-link :to="{ name: 'family-portal-home', }">
                <i class="fa-solid fa-house-signal"></i> Portail Familles
              </router-link>
            </li>
            <li v-if="nursery && hasPerm('nursery.view_')">
              <router-link :to="{ name: 'nursery-home', }">
                <i class="fa fa-baby-carriage"></i> Crèche
              </router-link>
            </li>
            <li v-if="isStoreActive && hasPerm('store.view_article')">
              <router-link :to="{ name: 'store-articles', }">
                <i class="fa fa-dice"></i> Ludothèque
              </router-link>
            </li>
            <li v-if="isPublicationActive && hasPerm('publications.view_publication')">
              <router-link :to="{ name: 'publications-home', }">
                <i class="fa fa-book-open-reader"></i> {{ publicationTitle }}
              </router-link>
            </li>
            <li v-if="hasPerm('payments')">
              <router-link :to="{ name: 'invoices-synthesis', }">
                <i class="fa fa-euro-sign"></i> Suivi comptable
              </router-link>
            </li>
            <li v-if="hasOnePerm(['reports', 'stats'])">
              <router-link :to="{ name: 'stats-home', }">
                <i class="fas fa-chart-pie"></i> Statistiques
              </router-link>
            </li>
            <li v-if="hasOnePerm(['notes.view_notes', 'log_books.view_logbook'])">
              <router-link :to="{ name: 'notes-home', }">
                <i class="fa fa-bullhorn"></i> Communiquer
              </router-link>
            </li>
            <li v-if="hasPerm('reports.view_')">
              <router-link :to="{ name: 'reports-home', }">
                <i class="fa fa-folder-open"></i> Rapports
              </router-link>
            </li>
            <li v-if="hasPerm('home.view_structure')">
              <router-link :to="{ name: 'tools-home', }">
                <i class="fa fa-tools"></i> Outils
              </router-link>
            </li>
            <li v-if="hasPerm('home.view_structure')">
              <router-link :to="{ name: 'admin-home', }">
                <i class="fa fa-cogs"></i> Administration
              </router-link>
            </li>
          </ul>
        </div>
      </b-col>
      <b-col>
        <div class="home2">
          <ul class="app-menu">
            <li class="text-right">
              <a href @click.prevent="showContactSupport()" class="underline">
                <i class="fa fa-user-edit"></i> Contacter le support
              </a>
              &nbsp;
              <a href @click.prevent="showOnlineHelp()" class="underline show-online-help">
                <i class="fa fa-info-circle"></i> Aide en ligne
              </a>
            </li>
          </ul>
          <notes-summary></notes-summary>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/Layout/PageHeader'
import NotesSummary from '@/components/Notes/NotesSummary.vue'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'

export default {
  name: 'home',
  components: {
    NotesSummary,
    PageHeader,
  },
  mixins: [BackendMixin],
  mounted() {
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    }
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    nursery() {
      return store.getters.config.nursery
    },
    isStoreActive() {
      return store.getters.config.isStoreActive
    },
    isPublicationActive() {
      return store.getters.config.isPublicationActive
    },
    publicationTitle() {
      return store.getters.config.publicationTitle
    },
    isPortalActive() {
      return store.getters.config.isPortalActive
    },
    activityMenus() {
      if (this.hasPerm('activities.view_coreactivity')) {
        return store.getters.config.activityMenus
      }
      return []
    },
    youthHomeIcon() {
      return store.getters.youthHomeIcon
    },
    canViewNotes() {
      return this.hasPerm('notes.view_note')
    },
  },
  watch: {
  },
  methods: {
    reloadApp() {
      // force to reload the app after deleting the browser cache for this page
      // the forceReload flag is deprecated : so try without in case of error
      try {
        // tslint:disable-next-line:deprecation
        window.location.reload(true)
      } catch (e) {
        window.location.reload()
      }
    },
    showOnlineHelp() {
      this.$bvModal.show('bv-online-help')
    },
    showContactSupport() {
      this.$bvModal.show('bv-contact-support')
    },
  },
}
</script>

<style lang="less">
.show-online-help  {
  margin-right: 5px;
}
.home1 {
  padding: 9px 10px;
  height: 100vh;
}
.home2 {
  padding: 2px 10px;
  background: #eee;
  height: 100vh;
}
</style>
