<template>
  <div class="unread-notes-counters">
    <table class="tiny-table">
      <tr
        v-for="item of items"
        :key="item.user.id"
        @click.prevent="selectUser(item)"
        :class="{ selected: isSelected(item) }"
      >
        <td>{{ item.user .fullName() }}</td>
        <th>{{ item.count }}</th>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeStaffUser } from '@/types/users'

export default {
  name: 'unread-notes-counters',
  components: {},
  mixins: [BackendMixin],
  props: {
  },
  data() {
    return {
      items: [],
      selectedUser: null,
    }
  },
  computed: {
    canView() {
      return this.hasAllPerms(['notes.view_note', 'auth.view_user'])
    },
  },
  watch: {
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    init() {
      this.loadData()
    },
    async loadData() {
      let url = '/notes/api/unread-counts/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.items = resp.data.map(
          elt => {
            return {
              user: makeStaffUser(elt.user),
              count: elt.count,
            }
          })
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    isSelected(item) {
      return (this.selectedUser === item.user)
    },
    selectUser(item) {
      if (this.isSelected(item)) {
        this.selectedUser = null
      } else {
        this.selectedUser = item.user
      }
      this.$emit('selected', { user: this.selectedUser, })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.tiny-table {
  width: 100%;
}
table.tiny-table tr td, table.tiny-table tr th {
  padding: 2px 5px;
  font-size: 12px;
  cursor: pointer;
  border: solid 1px #ccc;
}
table.tiny-table tr.selected td, table.tiny-table tr.selected th {
  background: #ccc;
}
</style>
