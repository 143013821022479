<template>
  <div class="search-screen" v-if="isAuthenticated && hasPerm('people.view_individual')">
    <a href @click.prevent="showScreen()">
      <i
        class="fa fa-search search-icon right-bar-icon"
        title="Recherche"
        v-b-tooltip.left
      ></i>
    </a>
    <div class="search-screen-content" v-show="isScreenShown" :style="windowsStyle">
      <b-row class="close-screen-line">
        <b-col>
          <h3><i class="fa fa-search"></i> Recherche de personnes</h3>
          <div class="close-screen-help">
            <i class="fa fa-info-circle">
            </i>&nbsp;Ctrl+Esc: cacher, Ctrl+Espace: afficher, Ctrl+Enter: voir la famille, Ctrl+Shift: focus
          </div>
        </b-col>
        <b-col cols="3" class="text-right">
          <a class="close-link" href @click.prevent="isScreenShown = false"><i class="fa fa-close"></i></a>
        </b-col>
      </b-row>
      <b-form class="search-form">
        <b-form-row>
          <b-col cols="12">
            <div class="intro-label">
              Recherchez une famille à partir de son nom, prénom, de son identifiant, d'un email ou d'un numéro de téléphone
            </div>
            <vue-bootstrap-typeahead
              v-model="searchText"
              :data="individuals"
              placeholder="Entrez le nom d'une personne"
              ref="individualsSearchTypeahead"
              :minMatchingChars="1"
              :disableFiltering="true"
              :serializer="serializeIndividual"
              @input="onTypeaheadInput"
              @hit="onTypeaheadHit"
              @enter="onTypeaheadEnter"
            >
              <template slot="append">
                <b-button type="submit" variant="secondary" @click.prevent="clear()">
                  <i class="fa fa-eraser"></i>
                </b-button>
              </template>
            </vue-bootstrap-typeahead>
          </b-col>
        </b-form-row>
        <b-row class="inline-boxes-line">
          <b-col class="inline-boxes">
            <b-form-checkbox v-model="includeAll">Tous</b-form-checkbox>
            <b-form-checkbox v-model="includeChildren" :disabled="includeAll">Enfants</b-form-checkbox>
            <b-form-checkbox v-model="includeAdults" :disabled="includeAll">Adultes</b-form-checkbox>
            <b-form-checkbox v-model="includeOthers" :disabled="includeAll">Intervenants</b-form-checkbox>
          </b-col>
          <b-col class="inline-boxes text-right" :cols="4">
            <b-form-checkbox v-model="onlyActiveMembers">Membres actifs</b-form-checkbox>
          </b-col>
        </b-row>
      </b-form>
      <div v-if="showNone && !selectedIndividual" class="no-results">
        Personne ne correspond à cette recherche
      </div>
      <b-row v-if="showList">
        <b-col cols="3">
          <div class="full-list" v-if="displayedIndividuals.length">
            <div v-for="individual of displayedIndividuals" :key="individual.id">
              <a
                href
                @click.prevent="onSelectIndividual(individual)"
                class="link"
                :class="{ highlight: isIndividualSelected(individual), }"
              >
                {{ individual.fullName() }}
              </a>
              <span v-if="isIndividualSelected(individual)">
                <span v-for="membership of memberships" :key="membership.id">
                  <a href class="link link-highlight" @click.prevent="viewEntity(membership)">
                    <span class="family-numbers small-family-numbers">{{ membership.entity.id }}</span>
                  </a>
                </span>
              </span>
            </div>
            <div style="margin-top: 10px" v-if="hasExactMatch">
              <a href @click.prevent="showExactMatch = false" class="link link-highlight">Voir plus</a>
            </div>
            <div style="margin-top: 10px" v-else-if="canViewMore">
              <a href @click.prevent="viewMore()" class="link link-highlight">Voir plus</a>
            </div>
          </div>
        </b-col>
        <b-col v-if="selectedIndividual" class="selected-individual">
          <div class="text-right" v-if="individuals.length">
            <a href @click.prevent="resetSelectedIndividual()" class="link">
              <i class="fa fa-close"></i>
            </a>
          </div>
          <div class="selected-individual-content">
            <b-row>
              <b-col>
                {{ selectedIndividual.firstAndLastName() }}
                <div v-if="selectedIndividual.birthDate">
                  <span v-if="selectedIndividual.gender.id === 1">né le</span>
                  <span v-else-if="selectedIndividual.gender.id === 2">née le</span>
                  <span v-else>né.e le</span>
                  {{ selectedIndividual.birthDate | dateToString }}
                </div>
              </b-col>
              <b-col>
                <email-link :email="selectedIndividual.email" light block></email-link>
                <phone-link :phone="selectedIndividual.cellPhone" light block></phone-link>
                <phone-link :phone="selectedIndividual.proPhone" light block></phone-link>
              </b-col>
              <b-col>
                <div v-for="membership of memberships" :key="membership.id" class="entity-item">
                  <div>
                    <span class="family-numbers">{{ membership.entity.id }}</span>
                    <span class="family-name">
                      <span v-if="membership.role.familyRole">Famille</span>
                      {{ membership.entity.name }}
                    </span>
                    <span class="badge badge-light">{{ membership.role.name }}</span>
                  </div>
                  <div></div>
                  <div v-for="line of membership.entity.addressLines()" :key="line">
                    {{ line }}
                  </div>
                  <div>
                    {{ membership.entity.zipCode }}
                    <span v-if="membership.entity.city">{{ membership.entity.city.name }}</span>
                    {{ membership.entity.cedex }}
                  </div>
                  <a
                    :href="getEntityLinkUrl(membership)"
                    class="link link-highlight"
                    @click.prevent="viewEntity(membership)"
                  >
                    Voir la fiche
                  </a>
                  <a
                    :href="getEntityInvoicesUrl(membership)"
                    class="link link-highlight"
                    @click.prevent="viewEntityInvoices(membership)"
                  >
                    Facturation
                  </a>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import EmailLink from '@/components/Controls/Links/EmailLink'
import PhoneLink from '@/components/Controls/Links/PhoneLink'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import store from '@/store'
import { makeIndividual, makeIndividualEntityMembership } from '@/types/people'
import { BackendApi } from '@/utils/http'

export default {
  name: 'SearchScreen',
  components: { PhoneLink, EmailLink, },
  mixins: [BackendMixin],
  data() {
    return {
      searchText: '',
      isScreenShown: false,
      individuals: [],
      selectedIndividual: null,
      memberships: [],
      showList: false,
      showExactMatch: true,
      showNone: false,
      includeAll: false,
      includeChildren: true,
      includeAdults: true,
      includeOthers: false,
      onlyActiveMembers: true,
      length: 10,
    }
  },
  created() {
    this.onlyActiveMembers = this.loadParameter('onlyActiveMembers', false)
    this.includeAdults = this.loadParameter('includeAdults', true)
    this.includeChildren = this.loadParameter('includeChildren', true)
    this.includeOthers = this.loadParameter('includeOthers', false)
    this.includeAll = this.loadParameter('includeAll', false)
    window.addEventListener('keydown', this.keyDown)
  },
  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated
    },
    isHome() {
      return window.location.pathname === '/'
    },
    windowsStyle() {
      return { 'min-height': '100vw', }
    },
    showSearch() {
      return store.getters.showSearch
    },
    canViewMore() {
      return this.individuals.length === this.length
    },
    exactMatch() {
      return this.individuals.filter(elt => elt.firstAndLastName() === this.searchText)
    },
    hasExactMatch() {
      return this.showExactMatch && this.exactMatch.length === 1
    },
    displayedIndividuals() {
      if (this.showExactMatch && (this.exactMatch.length === 1)) {
        return this.exactMatch
      } else {
        return this.individuals
      }
    },
  },
  watch: {
    showSearch() {
      if (this.showSearch) {
        this.showScreen()
        store.commit('showSearch', false)
      }
    },
    isScreenShown: function() {
      if (this.isScreenShown && this.selectedIndividual) {
        this.$refs.individualsSearchTypeahead.inputValue = this.selectedIndividual.firstAndLastName()
      }
    },
    includeAll: function() {
      if (this.includeAll) {
        this.includeChildren = true
        this.includeAdults = true
        this.includeOthers = true
      }
      this.saveParameter('includeAll', this.includeAll)
    },
    includeChildren: function() {
      this.saveParameter('includeChildren', this.includeChildren)
    },
    includeAdults: function() {
      this.saveParameter('includeAdults', this.includeAdults)
    },
    includeOthers: function() {
      this.saveParameter('includeOthers', this.includeOthers)
    },
    onlyActiveMembers: function() {
      this.saveParameter('onlyActiveMembers', this.onlyActiveMembers)
    },
  },
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['setSearchedIndividuals']),
    keyDown(event) {
      if (this.isScreenShown && (event.keyCode === 27) && event.ctrlKey) {
        // ESC
        this.isScreenShown = false
      }
      if ((event.keyCode === 32) && event.ctrlKey) {
        // Space bar
        this.showScreen()
      }
      if (this.isScreenShown && (event.keyCode === 16) && event.ctrlKey) {
        // Shift
        if (this.$refs.individualsSearchTypeahead) {
          this.$refs.individualsSearchTypeahead.focus()
        }
      }
      if (this.isScreenShown && (event.keyCode === 13) && event.ctrlKey) {
        // Enter
        this.showFamily()
      }
    },
    onSearchChange() {
      this.clear()
    },
    clear() {
      this.selectedIndividual = null
      this.memberships = []
      this.searchText = ''
      this.individuals = []
      this.showExactMatch = true
      this.$refs.individualsSearchTypeahead.inputValue = ''
    },
    async searchIndividuals(text) {
      this.individuals = []
      this.showNone = false
      this.showList = false
      if (text) {
        try {
          let url = '/search/api/search-individual/?text=' + text + '&length=' + this.length
          if (!this.includeAll) {
            url += '&children=' + (this.includeChildren ? '1' : '0')
            url += '&adults=' + (this.includeAdults ? '1' : '0')
            url += '&others=' + (this.includeOthers ? '1' : '0')
          }
          if (this.onlyActiveMembers) {
            url += '&only_active=1'
          }
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          this.individuals = await resp.data.map(elt => makeIndividual(elt))
          if (this.individuals.length === 0) {
            this.showNone = true
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    onTypeaheadInput(evt) {
      this.length = 10
      this.searchIndividuals(evt)
    },
    onTypeaheadHit(evt) {
      this.selectedIndividual = evt
      this.showList = true
      this.showExactMatch = true
      this.loadEntities()
    },
    onTypeaheadEnter(event) {
      this.selectedIndividual = null
      this.showList = true
      this.showExactMatch = true
      if (this.individuals.length) {
        this.selectedIndividual = this.individuals[0]
        this.loadEntities()
      }
    },
    showScreen() {
      this.isScreenShown = true
      const that = this
      setTimeout(function() {
        that.$refs.individualsSearchTypeahead.focus()
      }, 100)
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth', })
    },
    serializeIndividual(individual) {
      return individual.firstAndLastName()
    },
    viewEntity(membership) {
      router.push(this.getEntityLink(membership))
      this.clear()
      this.isScreenShown = false
    },
    viewEntityInvoices(membership) {
      router.push(this.getEntityInvoicesLink(membership))
      this.clear()
      this.isScreenShown = false
    },
    getEntityLink(membership) {
      const params = { entityId: '' + membership.entity.id, }
      const query = { individual: '' + this.selectedIndividual.id, }
      if (membership.role.familyRole) {
        return { name: 'families-detail', params: params, query: query, }
      } else {
        return { name: 'entities-detail', params: params, query: query, }
      }
    },
    getEntityLinkUrl(membership) {
      return router.resolve(this.getEntityLink(membership)).href
    },
    getEntityInvoicesLink(membership) {
      const params = { entityId: '' + membership.entity.id, }
      const query = { tab: 'invoices', }
      if (membership.role.familyRole) {
        return { name: 'families-detail', params: params, query: query, }
      } else {
        return { name: 'entities-detail', params: params, query: query, }
      }
    },
    getEntityInvoicesUrl(membership) {
      return router.resolve(this.getEntityInvoicesLink(membership)).href
    },
    async loadEntities() {
      this.memberships = []
      if (this.selectedIndividual.id) {
        const url = '/api/people/individual-entities/' + this.selectedIndividual.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.memberships = resp.data.map(
            elt => makeIndividualEntityMembership(elt)
          )
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    saveParameter(arg, value) {
      window.localStorage.setItem('search-individual:' + arg, value ? '1' : '0')
    },
    loadParameter(arg, defaultValue) {
      let value = window.localStorage.getItem('search-individual:' + arg)
      if (value) {
        value = +value
      } else {
        value = defaultValue
      }
      return !!value
    },
    resetSelectedIndividual() {
      this.selectedIndividual = null
      this.showList = true
    },
    showFamily() {
      if (this.selectedIndividual && this.memberships.length) {
        this.viewEntity(this.memberships[0])
      }
    },
    viewMore() {
      this.length += 10
      this.searchIndividuals(this.searchText)
      this.showList = true
    },
    onSelectIndividual(individual) {
      this.selectedIndividual = individual
      this.loadEntities()
    },
    isIndividualSelected(individual) {
      return this.selectedIndividual && (individual.id === this.selectedIndividual.id)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.search-screen {
  .search-icon {
    top: 160px;
  }

  a.link {
    color: #fff !important;
  }

  a.link-highlight {
    text-decoration: underline;
  }

  a.link.highlight {
    color: #f3d250 !important;
  }

  a.close-link {
    color: #fff !important;
  }

  .search-screen-content {
    width: 100vw;
    top: 0;
    left: 0;
    min-height: 100vmax;
    position: absolute;
    background: #4d85af;
    color: #fff;
    opacity: 1;
    z-index: 50;
    padding: 30px 50px;
  }

  .close-screen-line {
    font-size: 32px;
    margin-bottom: 10px;
  }

  .close-screen-help {
    font-size: 14px;
  }

  .selected-individual {
    margin-top: 10px;
  }

  .selected-individual-content {
    padding: 10px;
    border: solid 1px #fff;
    border-radius: 2px;
  }

  .search-form {
    color: #000;
    background: #fff;
    padding: 10px;
    border-radius: 8px;
  }

  .inline-boxes > div {
    display: inline-block;
    margin-right: 20px;
  }
  .inline-boxes.text-right > div {
    display: inline-block;
    margin-right: 0;
    margin-left: 20px;
  }
  .no-results {
    margin-top: 20px;
  }
  .full-list {
    margin-top: 30px;
    padding: 10px;
    border: solid 1px #fff;
    border-radius: 2px;
  }
  .intro-label {
    color: #888;
  }
  .entity-item {
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: solid 1px #fff;
    font-size: 12px;
  }
  .entity-item a {
    margin-right: 5px;
  }
  .entity-item:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}
</style>
