<template>
  <div class="notes-summary" v-if="canViewNotes">
    <b-row v-if="!editNote">
      <b-col cols="6">
        <span class="tabs-bar-prefix">
          <i class="fa fa fa-map-pin"></i>
          Notes épinglées
        </span>
        <tabs-bar
          enabled
          :tabs="tabs"
          :active="activeTab"
          secondary
          inline
          @change="onTabChanged($event)"
        >
        </tabs-bar>
      </b-col>
      <b-col cols="6" class="text-right">
        <router-link :to="{ name: 'notes', }" class="underline">
          <i class="fa fa-th-list"></i> Toutes les notes
        </router-link>
        &nbsp;
        <a href class="underline" @click.prevent="addNewNote()" v-if="canAddNote">
          <i class="fa fa-file-pen"></i> Nouvelle note
        </a>
      </b-col>
    </b-row>
    <div v-if="editNote">
      <note-editor
        :note="editedNote"
        @note-added="onNoteAdded"
        @note-saved="onNoteSaved"
        @cancelled="onNoteCancelled"
      >
      </note-editor>
    </div>
    <div v-else>
      <loading-gif :loading-name="notesLoading"></loading-gif>
      <note-items
        :items="notes"
        :home="true"
        v-if="!isLoading(notesLoading)"
        @item-pin="onItemClosed($event)"
        @item-delete="onItemDelete($event)"
        @item-edit="onEditNote($event)"
        @update="refresh"
      ></note-items>
    </div>
    <confirm-modal
      name="hide-note"
      icon="fa fa-map-pin"
      title="Ne plus voir la note sur l'accueil"
      :text="hideNoteText"
      :object="selectedNote"
      @confirmed="onHideNote"
    >
    </confirm-modal>
    <confirm-modal
      name="delete-note"
      icon="fa fa-trash"
      title="Suppression d'une note"
      :text="deleteNoteText"
      :object="selectedNote"
      @confirmed="onDeleteNote"
      ok-variant="danger"
    >
    </confirm-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import LoadingGif from '@/components/Controls/LoadingGif'
import TabsBar from '@/components/Controls/TabsBar.vue'
import NoteItems from '@/components/Notes/NoteItems'
import NoteEditor from '@/components/Notes/NoteEditor'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import { makeNote } from '@/types/notes'
import { TabItem } from '@/types/tabs'
import { BackendApi } from '@/utils/http'

export default {
  name: 'notes-summary',
  components: {
    TabsBar,
    LoadingGif,
    NoteItems,
    NoteEditor,
    ConfirmModal,
  },
  mixins: [BackendMixin],
  mounted() {
    this.activeTab = this.tabs[0]
    this.loadNotes()
  },
  data() {
    return {
      notesLoading: 'notes-loading',
      notes: [],
      selectedNote: null,
      editedNote: null,
      editNote: false,
      activeTab: null,
    }
  },
  computed: {
    selectedNoteTitle() {
      return this.selectedNote ? this.selectedNote.title : ''
    },
    deleteNoteText() {
      if (this.selectedNoteTitle) {
        return (
          '"' + this.selectedNoteTitle + '"\n\n' +
          'Voulez-vous archiver cette note pour tous les destinataires?'
        )
      }
      return ''
    },
    hideNoteText() {
      if (this.selectedNoteTitle) {
        return (
          '"' + this.selectedNoteTitle + '"\n\n' +
          'Voulez-vous désépingler cette note de votre page d\'accueil?'
        )
      }
      return ''
    },
    canViewNotes() {
      return this.hasPerm('notes.view_note')
    },
    canAddNote() {
      return this.hasPerm('notes.add_note')
    },
    notesUpdated() {
      return store.getters.notesUpdated
    },
    tabs() {
      return [
        new TabItem('all', 'Toutes', ''),
        new TabItem('home', 'de l\'accueil', ''),
        new TabItem('pages', 'des pages', '')
      ]
    },
  },
  watch: {
    notesUpdated: function() {
      if (this.notesUpdated) {
        this.loadNotes()
      }
    },
  },
  methods: {
    async loadNotes() {
      if (this.canViewNotes) {
        let url = '/notes/api/notes/?home=home'
        if (this.activeTab) {
          if (this.activeTab.name === 'pages') {
            url += '&linked=1'
          } else if (this.activeTab.name === 'home') {
            url += '&linked=2'
          }
        }
        this.startLoading(this.notesLoading)
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.notes = resp.data.results.map(elt => makeNote(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.notesLoading)
      }
    },
    onNoteAdded(note) {
      this.editNote = false
      this.notes = [note].concat(this.notes)
    },
    onItemClosed(event) {
      this.selectedNote = event
      this.$bvModal.show('bv-confirm-modal:hide-note')
    },
    onItemDelete(event) {
      this.selectedNote = event
      this.$bvModal.show('bv-confirm-modal:delete-note')
    },
    async onHideNote() {
      if (this.selectedNote) {
        this.startLoading(this.loadingName)
        let url = '/notes/api/pin-note/' + this.selectedNote.id + '/'
        let backendApi = new BackendApi('post', url)
        try {
          await backendApi.callApi({ 'pin_note': false, })
          this.selectedNote = null
          await this.loadNotes()
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    async onDeleteNote() {
      if (this.selectedNote) {
        this.startLoading(this.loadingName)
        const url = '/notes/api/notes/' + this.selectedNote.id + '/'
        const backendApi = new BackendApi('delete', url)
        try {
          await backendApi.callApi({})
          await this.loadNotes()
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.selectedNote = null
        this.endLoading(this.loadingName)
      }
    },
    addNewNote() {
      this.editNote = true
      this.editedNote = null
    },
    onNoteCancelled() {
      this.editNote = false
      this.editedNote = null
    },
    onNoteSaved(editedNote) {
      const index = this.notes.map(elt => elt.id).indexOf(editedNote.id)
      if (index >= 0) {
        this.notes[index] = editedNote
      }
      this.notes = [].concat(this.notes)
      this.editNote = false
      this.editedNote = null
    },
    onEditNote(event) {
      this.editedNote = event
      this.editNote = true
    },
    onTabChanged(tab) {
      this.activeTab = tab
      this.loadNotes()
    },
    async refresh(event) {
      const index = this.notes.map(elt => elt.id).indexOf(event.note.id)
      if (index >= 0) {
        this.notes[index] = event.note
        this.notes = [].concat(this.notes)
      }
    },
  },
}
</script>

<style lang="less">
.tabs-bar-prefix {
  font-weight: bold;
  padding: 5px;
}
</style>
