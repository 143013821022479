<template>
  <span class="edit-street" v-if="hasPerm('people.change_street')">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onSave"
      :ok-disabled="canChange && formInvalid"
      ok-variant="primary"
      cancel-title="Annuler"
      @shown="init()"
      :ok-title="canChange ? 'Enregistrer' : 'Fermer'"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-pencil-alt"></i> Modifier une rue</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <div class="small-text">
            <loading-gif :loading-name="loadingName"></loading-gif>
            <div v-if="!isLoading(loadingName)">
              <div class="bold-text">
                <b>
                  <counter-label label="famille" :counter="entities.length"></counter-label> à cette adresse
                </b>
              </div>
              <div v-if="entities.length === 0">
                Aucune famille n'est associée à cette rue. Voulez-vous l'archiver?<br />
                <a href @click.prevent="hideStreet()" class="btn btn-danger">
                  Archiver la rue
                </a>
              </div>
              <div class="field-line" v-for="entity of entities" :key="entity.id">
                <a href @click.prevent="showEntitySidebar(entity)">
                  <span class="family-numbers">{{ entity.id }}</span> {{ entity.name }}
                </a>
              </div>
            </div>
          </div>
        </b-col>
        <b-col v-if="canChange">
          <b-form-group label="Nouveau nom" label-for="newName">
            <b-input type="text" v-model="newName" id="newName"></b-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeChoice } from '@/types/base'
import { makeEntity } from '@/types/people'

export default {
  name: 'ModifyStreetModal',
  components: { LoadingGif, CounterLabel, },
  mixins: [BackendMixin],
  props: {
    street: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      errorText: '',
      newName: '',
      entities: [],
      loadingName: 'street-entities',
    }
  },
  watch: {
    street: function() {},
  },
  mounted() {
  },
  computed: {
    canChange() {
      return this.hasPerm('people.change_street')
    },
    formInvalid() {
      return (this.street === null) || (!this.newName)
    },
    modalId() {
      return 'bv-modal-edit-street'
    },
  },
  methods: {
    ...mapMutations(['startLoading', 'endLoading']),
    ...mapActions(['addSuccess']),
    init() {
      this.errorText = ''
      if (this.street) {
        this.loadEntities()
        this.newName = this.street.name
      } else {
        this.newName = ''
        this.entities = []
      }
    },
    async loadEntities() {
      this.startLoading(this.loadingName)
      let url = '/api/people/street-entities/' + this.street.id + '/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.entities = resp.data.map(makeEntity)
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
      this.endLoading(this.loadingName)
    },
    async hideStreet() {
      this.errorText = ''
      if (!this.formInvalid) {
        let url = '/api/people/hide-street/' + this.street.id + '/'
        const backendApi = new BackendApi('post', url)
        try {
          await backendApi.callApi({})
          const text = 'La rue a été archivée'
          await this.addSuccess(text)
          this.$bvModal.hide(this.modalId)
          this.$emit('done')
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
    async onSave() {
      this.errorText = ''
      if (!this.formInvalid) {
        let url = '/api/people/rename-street/' + this.street.id + '/'
        const backendApi = new BackendApi('post', url)
        try {
          const data = {
            'name': this.newName,
          }
          const resp = await backendApi.callApi(data)
          const text = 'La rue a été modifiée'
          await this.addSuccess(text)
          this.$bvModal.hide(this.modalId)
          this.$emit('done', { street: makeChoice(resp.data), })
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
  },
}
</script>

<style scoped>
</style>
