<template>
  <div class="notes"  v-if="hasPerm('notes.view_noteexternalrecipient')">
    <page-header title="Destinataires externes des notes" icon="fa fa-paper-plane" :links="getLinks()"></page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div class="empty-text" v-if="recipients.length === 0">
      Aucun destinataire
    </div>
    <div ref="excelTable" v-else>
      <x-table class="small-table" id="externalrecipients" :items="items" :columns="columns"></x-table>
    </div>
    <confirm-modal
      name="delete-external-recipient"
      :object="selectedRecipient"
      title="Supprimer un destinataire"
      icon="fa fa-trash"
      :text="deleteModalText"
      @confirmed="onConfirmDeleteRecipient"
    ></confirm-modal>
    <add-external-recipient-modal
      modal-id="bv-add-external-recipient-modal"
      @done="onAdded()"
    ></add-external-recipient-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import LoadingGif from '@/components/Controls/LoadingGif'
import XTable from '@/components/Controls/Table/Table.vue'
import PageHeader from '@/components/Layout/PageHeader'
import AddExternalRecipientModal from '@/components/Notes/AddExternalRecipientModal.vue'
import { BackendApi, openDocument } from '@/utils/http'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'
import { loadConfig } from '@/apis/config'

export default {
  name: 'NoteExternalRecipients',
  mixins: [BackendMixin],
  components: {
    AddExternalRecipientModal,
    ConfirmModal,
    XTable,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'NoteExternalRecipients',
      recipients: [],
      columns: [],
      selectedRecipient: null,
      needReload: false,
    }
  },
  watch: {
  },
  computed: {
    canAddItem() {
      return this.hasPerm('notes.add_noteexternalrecipient')
    },
    canDeleteItem() {
      return this.hasPerm('notes.change_noteexternalrecipient')
    },
    items() {
      return this.recipients.map(this.makeItem)
    },
    deleteModalText() {
      if (this.selectedRecipient) {
        return 'Supprimer ' + this.selectedRecipient.email + '?'
      }
      return ''
    },
  },
  mounted() {
    this.loadRecipients()
    this.columns = this.getColumns()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    getColumns() {
      const columns = [
        {
          name: 'email',
          label: 'Email',
        }
      ]
      if (this.canDeleteItem) {
        columns.push(
          {
            name: 'delete',
            hideFilter: true,
            label: '',
            title: 'Supprimer',
            maxWidth: '30px',
            alignCenter: true,
            onClick: this.onDeleteRecipient,
          }
        )
      }
      return columns
    },
    makeItem(recipient) {
      return {
        id: recipient.id,
        email: recipient.email,
        delete: '<i class="fas fa-trash"></i>',
      }
    },
    async loadRecipients() {
      this.recipients = []
      let backendApi = new BackendApi('get', '/notes/api/note-external-recipients/')
      try {
        let resp = await backendApi.callApi()
        this.recipients = resp.data
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onDeleteRecipient(item) {
      this.selectedRecipient = item
      this.$bvModal.show('bv-confirm-modal:delete-external-recipient')
    },
    async onConfirmDeleteRecipient(item) {
      const url = '/notes/api/note-external-recipients/' + item.object.id + '/'
      let backendApi = new BackendApi('patch', url)
      try {
        await backendApi.callApi({ archived: true, })
        await this.addSuccess('Le destinataire a été supprimé')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      await this.loadRecipients()
      if (this.recipients.length === 0) {
        await loadConfig()
      }
    },
    getLinks() {
      const isActive = !this.isLoading(this.loadingName) && this.recipients.length
      const isActive2 = !this.isLoading(this.loadingName)
      const links = [
        {
          id: 1,
          label: 'Excel',
          callback: this.toExcel,
          icon: 'fa fa-file-excel',
          cssClass: isActive ? 'btn-secondary' : 'btn-secondary disabled',
        },
        {
          id: 2,
          label: 'PDF',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: isActive ? 'btn-secondary' : 'btn-secondary disabled',
        }
      ]
      if (this.canAddItem) {
        links.push(
          {
            id: 3,
            label: 'Ajouter',
            callback: () => {
              this.$bvModal.show('bv-add-external-recipient-modal')
            },
            icon: 'fa fa-plus',
            cssClass: isActive2 ? 'btn-primary' : 'btn-primary disabled',
          }
        )
      }
      return links
    },
    async toExcel() {
      const isActive = !this.isLoading(this.loadingName) && this.recipients.length
      if (isActive) {
        const docUrl = '/documents/table-to-excel/<key>/'
        const docSlug = 'destinataires-externes'
        const docContent = this.$refs.excelTable.innerHTML.toString()
        try {
          await openDocument(docUrl, docSlug, docContent)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async printMe() {
      const isActive = !this.isLoading(this.loadingName) && this.recipients.length
      if (isActive) {
        const docUrl = '/documents/standard/<key>/pdf/' // '?landscape=1'
        const docContent = this.$refs.excelTable.innerHTML.toString()
        const docSlug = 'destinataires-externes'
        try {
          await openDocument(docUrl, docSlug, docContent)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async onAdded() {
      if (this.recipients.length === 0) {
        await loadConfig()
      }
      await this.loadRecipients()
    },
  },
}
</script>

<style scoped lang="less">
</style>
