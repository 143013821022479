<template>
  <div class="right-bar" id='right-bar' @click.prevent="handleScroll">
  </div>
</template>

<script>

export default {
  name: 'RightBar',
  props: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    handleScroll(event) {
      if (window.innerHeight) {
        const ratio = event.clientY * document.body.scrollHeight / window.innerHeight
        window.scrollTo({ top: ratio, left: 0, behavior: 'smooth', })
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.right-bar {
  position: absolute;
  right: 0;
  width: 24px;
  height: calc(100% - 12px);
  min-height: calc(100vh - 12px);
  background: #e7f3ff;
}
</style>
