<template>
  <span v-if="canViewNotes" class="notes-icon-holder">
    <span style="position: fixed">
      <span
        v-if="notesOnPage"
        title="nombre de notes associées à la page"
        v-b-tooltip.top
        class="mini-counter top-mini-counter"
      >
        {{ notesOnPage }}
      </span>
    </span>
    <i
      class="fa notes-icon right-bar-icon"
      title="Notes"
      :class="cssClass"
      v-b-tooltip.left
      v-b-toggle.sidebar-notes
    ></i>
    <span style="position: fixed">
      <span
        v-if="unreadNotes"
        title="nombre de notes non lues"
        v-b-tooltip.bottom
        class="mini-counter bottom-mini-counter"
      >
        {{ unreadNotes }}
      </span>
    </span>
  </span>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'
import store from '@/store'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'

export default {
  name: 'notes-button',
  mixins: [BackendMixin],
  props: {
    updated: Boolean,
  },
  components: {
  },
  data() {
    return {
      notesOnPage: 0,
      unreadNotes: 0,
      pinnedNotes: 0,
      timeout: null,
      loading: false,
    }
  },
  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated
    },
    canViewNotes() {
      return this.isAuthenticated && this.hasPerm('notes.view_note')
    },
    cssClass() {
      return {
        loading: this.loading,
        'fa-sticky-note': this.notesOnPage >= 0,
      }
    },
  },
  mounted() {
    this.init()
  },
  watch: {
    $route() {
      this.init()
    },
    updated() {
      if (this.updated) {
        this.loadNotesCount()
      }
    },
  },
  methods: {
    ...mapMutations(['setNotes', 'resetNotes']),
    ...mapActions(['addError']),
    init() {
      this.notesCount = 0
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      const that = this
      this.loading = true
      this.notesOnPage = 0
      this.unreadNotes = 0
      this.pinnedNotes = 0
      this.resetNotes()
      this.timeout = setTimeout(
        function() {
          that.loadNotesCount()
        },
        1000
      )
    },
    async loadNotesCount() {
      this.notesCount = 0
      if (this.isAuthenticated && this.canViewNotes) {
        const url = '/notes/api/notes/count/?path=' + window.location.pathname
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.notesOnPage = resp.data.page
          this.unreadNotes = resp.data.unread
          this.pinnedNotes = resp.data.pinned
          this.loading = false
          this.$emit('loaded', { count: this.notesCount, })
          this.setNotes(this.notesOnPage)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.notes-icon-holder {
  position: absolute;
  top: 100px;
  right: 0;
}
.loading {
  background: #ccc;
}
.mini-counter {
  position: absolute;
  background: #ccc;
  font-size: 11px;
  color: #222;
  border-radius: 50%;
  padding: 2px 4px;
  display: inline-block;
  right: 5px;
  min-width: 22px;
  text-align: center;
  z-index: 200;
}
.mini-counter.top-mini-counter {
  background: #b83375;
  color: #fff;
  top: -15px;
}
.mini-counter.bottom-mini-counter {
  background: #7bcbff;
  top: 30px;
}
</style>
