<template>
  <div class="activity-editor" v-if="canEdit && editedActivity">
    <loading-gif :loading-name="loadingName"></loading-gif>
    <b-form @submit.prevent="onSave">
      <b-row>
        <b-col cols="5">
          <b-form-group label-for="name" label="Nom">
            <b-form-input type="text" v-model="editedActivity.name" id="name"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <ColorSelect
            :background="editedActivity.backgroundColor"
            :color="editedActivity.textColor"
            @selected="getColor($event)"
          />
        </b-col>
        <b-col>
          <b-form-group label-for="schoolYear" label="Saison">
            <b-select v-model="selectedSchoolYear" :disabled="activity.id > 0">
              <b-select-option v-for="schoolYear of schoolYears" :key="schoolYear.id" :value="schoolYear">
                {{ schoolYear.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-for="category" label="Catégorie">
            <b-select v-model="editedActivity.technicalCategory">
              <b-select-option v-for="cat of categories" :key="cat.id" :value="cat.id">
                {{ cat.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div v-if="isEvent" class="small-info">
            Les évènements permettent d'avoir plusieurs inscriptions et pour chacune
            un nombre de places
          </div></b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label-for="analytics" label="Compte analytique">
            <b-input-group>
              <b-select v-model="selectedAnalytic" @change="onAnalyticChanged">
                <b-select-option v-for="analytic of analytics" :key="analytic.id" :value="analytic">
                  {{ analytic.getLabel() }}
                </b-select-option>
              </b-select>
              <b-input-group-append v-if="canAddAnalytic">
                <b-button
                  variant="secondary"
                  @click.prevent="showAddAnalyticModal"
                  title="Ajouter un compte analytique"
                  v-b-tooltip
                >
                  <i class="fa fa-plus"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group
            description="Le compte analytique des ventes existantes sera modifié"
            v-if="activity.id"
          >
            <b-checkbox
              :disabled="!analyticChanged"
              v-model="forceSalesAnalyticUpdate"
            >
              MAJ des ventes existantes ({{ activity.analyticAccount ? activity.analyticAccount.name : '' }})<br />
            </b-checkbox>
          </b-form-group>
          <div v-if="forceSalesAnalyticUpdate" class="warning">
            <i class="fa fa-warning"></i> Attention! Les ventes, paiements et avoirs seront mis à jour.
              Votre état des ventes et votre comptabilité analytique seront modifiés
          </div>
        </b-col>
        <b-col>
          <b-form-group label-for="general-accounts" label="Compte général">
            <b-select v-model="selectedGeneralAccount" @change="onGeneralChanged">
              <b-select-option v-for="account of generalAccounts" :key="account.id" :value="account">
                {{ account.getFullLabel() }}
              </b-select-option>
            </b-select>
          </b-form-group>
          <div v-if="isGeneralAccountRequired" class="required-field">
            Un compte général est requis pour les activités payantes
          </div>
          <b-form-group
            v-if="activity.id"
            description="Le compte général des ventes existantes sera modifié"
          >
            <b-checkbox
              :disabled="!generalChanged"
              v-model="forceSalesGeneralUpdate"
            >
              MAJ des ventes existantes ({{ activity.generalAccount ? activity.generalAccount.name : '' }})
            </b-checkbox>
          </b-form-group>
          <div v-if="forceSalesGeneralUpdate" class="warning">
            <i class="fa fa-warning"></i> Attention! Les ventes, paiements et avoirs seront mis à jour.
              Votre état des ventes et votre comptabilité analytique seront modifiés
          </div>
        </b-col>
        <b-col>
          <b-form-group
            label-for="activityTypes"
            label="Type d'activité"
            description="Utilisé pour les statistiques"
          >
            <b-input-group>
              <b-select v-model="editedActivity.activityType" id="activityTypes">
                <b-select-option :value="0"></b-select-option>
                <b-select-option v-for="activityType of activityTypes" :key="activityType.id" :value="activityType">
                  {{ activityType.name }}
                </b-select-option>
              </b-select>
              <b-input-group-append>
                <b-button
                  variant="secondary"
                  @click.prevent="showAddActivityTypeModal"
                  title="Ajouter un type d'activité"
                  v-b-tooltip
                >
                  <i class="fa fa-plus"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label-for="startTime" label="Heure de début">
            <b-form-input type="time" v-model="editedActivity.startTime" id="startTime"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-for="endTime" label="Heure de fin">
            <b-form-input type="time" v-model="editedActivity.endTime" id="endTime"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-for="day" label="Jour">
            <b-form-input type="text" v-model="editedActivity.day" id="day"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-for="startDate" label="Date de début">
            <b-form-input type="date" v-model="editedActivity.startDate" id="startDate"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label-for="duration" label="Durée" description="en heure">
            <b-form-input type="number" step="0.01" min="0" v-model="editedActivity.duration" id="duration">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-for="place" label="Lieu">
            <b-input-group>
              <b-form-select v-model="editedActivity.place" id="place" :disabled="!showPlacesSelect">
                <b-select-option
                  v-for="place of places"
                  :value="place"
                  :key="place.id"
                >
                  {{ place.name }}
                </b-select-option>
              </b-form-select>
              <b-input-group-append>
                <b-button
                  variant="secondary"
                  @click.prevent="showAddPlaceModal"
                  title="Ajouter un lieu"
                  v-b-tooltip
                >
                  <i class="fa fa-plus"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-for="seancesCount" label="Nb. de séances">
            <b-form-input type="number" step="1" min="0" v-model="editedActivity.seancesCount" id="seancesCount">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-for="minimumParticipants" label="Mini" description="participants">
            <b-form-input type="number" step="1" min="0" v-model="editedActivity.minimumParticipants" id="minimumParticipants">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-for="maximumParticipants" label="Maxi" description="participants">
            <b-form-input type="number" step="1" min="0" v-model="editedActivity.maximumParticipants" id="maximumParticipants">
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label-for="keywords"
            label="Mots-clefs"
            description="Pour chercher plus facilement les activités"
          >
            <b-form-input type="text" v-model="editedActivity.keywords" id="keywords">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            v-if="!youthHome"
            label-for="allowAllMembers"
            label="Inscriptions"
            description="Si coché, vous pouvez inscrire les intervenants d'une famille"
          >
            <b-form-checkbox id="allowAllMembers" v-model="editedActivity.allowAllMembers">
              Autorise les intervenants
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            v-if="!youthHome"
            label-for="allowPresence"
            label="Pointage"
            description="Si coché, la fiche de présence permettra de pointer les présences des membres"
          >
            <b-form-checkbox id="allowPresence" v-model="editedActivity.allowPresence">
              Pointage des présences
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
            v-if="!youthHome"
            label-for="weeklyPresence"
            label="Présences"
            description="Si coché, la semaine plutôt que le jour est affichée sur le listing des présence"
          >
            <b-form-checkbox id="weeklyPresence" v-model="editedActivity.weeklyPresence">
              Affichage hebdomadaire
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label-for="about" label="À propos">
            <b-textarea v-model="editedActivity.about" id="about" style="min-height: 120px"></b-textarea>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-for="conditions" label="Conditions">
            <b-textarea v-model="editedActivity.conditions" id="conditions" style="min-height: 120px"></b-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="reminder-info">
        <b-row>
          <b-col>
            <b-form-group
              label-for="sendReminder"
              description="Un email sera envoyé à chaque inscrit ou à ses parents avant la date de début de l'activité et/ou avant une de ses dates associées"
            >
              <b-form-checkbox id="sendReminder" v-model="editedActivity.sendReminder">
                <i class="fa fa-bell"></i>
                Envoyer un rappel par e-mail aux inscrits
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <div v-if="editedActivity.sendReminder">
          <b-row>
            <b-col cols="3">
              <b-form-group
                label-for="reminderDays" label="Nombre de jours avant la date"
                :description="reminderDaysDescription"
              >
                <b-input id="reminderDays" type="number" step="1" min="0" v-model="editedActivity.reminderDays">
                </b-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-for="reminderInfo"
                label="Informations à inclure dans le rappel"
                description="informations liées à cette activité"
              >
                <b-textarea v-model="editedActivity.reminderInfo" id="reminderInfo" style="min-height: 120px">
                </b-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col :cols="3">
              <b-form-group
                label-for="showStructureReminderInfo"
                :description="structureReminderInfo ? '' : 'Aucune données globales ne sont configurés'"
              >
                <b-form-checkbox
                  id="showStructureReminderInfo" v-model="editedActivity.showStructureReminderInfo"
                  :disabled="!structureReminderInfo"
                >
                  Inclure dans l'email les informations sur la structure
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                v-if="editedActivity.showStructureReminderInfo"
                label-for="structureReminderInfo"
                label="Informations globales incluses dans le rappel"
                description="informations communes entre toutes les activités"
              >
                <b-textarea v-model="structureReminderInfo" id="structureReminderInfo" style="min-height: 120px" disabled>
                </b-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
      <b-row>
        <b-col>
          <b-form-group label-for="prices" label="Prix">
            <b-select v-model="selectedPricingRuleId">
              <b-select-option v-for="elt of pricingRules" :key="elt.id" :value="elt.id">
                {{ elt.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col cols="9">
          <div v-if="isPercentagePrice || isFreePrice">
            <b-row>
              <b-col>
                <b-form-group label-for="ratio" label="Pourcentage de QF" v-if="isPercentagePrice">
                  <decimal-input
                    v-model="familyLevelRatioBase100"
                    id="ratio"
                    precision="3"
                  >
                  </decimal-input>
                  <div class="small-text">
                    Pour un QF de 1000: {{ familyLevelRatioBase100 * 10 | currency }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label-for="fixedPrice" label="Montant fixe">
                  <decimal-input
                    v-model="editedActivity.fixedPrice"
                    id="fixedPrice"
                    allow-null
                  >
                  </decimal-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label-for="minPrice" label="Prix minimal">
                  <decimal-input
                    v-model="editedActivity.minPrice"
                    id="minPrice"
                    allow-null
                  >
                  </decimal-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label-for="maxPrice" label="Prix maximal">
                  <decimal-input
                    v-model="editedActivity.maxPrice"
                    id="maxPrice"
                    allow-null
                  >
                  </decimal-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div v-if="isFlatPrice">
            <div v-if="editedActivity.flatPrices.length === 0" class="no-price-label">
              Gratuit
            </div>
            <b-row v-for="flatPrice of editedActivity.flatPrices" :key="flatPrice.index">
              <b-col>
                <b-form-group :label-for="'flatPriceName' + flatPrice.id" label="Nom">
                  <b-form-input
                    type="text"
                    v-model="flatPrice.name"
                    :id="'flatPriceName' + flatPrice.id"
                    :name="'flatPriceName' + flatPrice.id"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group :label-for="'flatPrice' + flatPrice.id" label="Prix">
                  <decimal-input
                    v-model="flatPrice.price"
                    :id="'flatPrice' + flatPrice.id"
                  >
                  </decimal-input>
                </b-form-group>
              </b-col>
              <b-col class="text-right">
                <b-form-group label-for="removeFlatPrice">
                  <br />
                  <a class="btn btn-secondary btn-sm" href @click.prevent="removeFlatPrice(flatPrice)">
                    Supprimer
                  </a>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col></b-col>
              <b-col cols="3" class="text-right">
                <a class="btn btn-secondary btn-sm" href @click.prevent="addFlatPrice()">
                  Ajouter un prix
                </a>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <div v-if="isGridPrice">
        <b-row>
          <b-col>
            <b-form-group
              label-for="scale"
              label="Échelle de QF"
              description="Pour l'année sélectionnée"
            >
              <b-select
                v-model="selectedScale"
                :disabled="familyLevelScales.length === 0 || editedActivity.gridPrices.length > 0"
                name="scale"
              >
                <b-select-option v-for="scale of familyLevelScales" :key="scale.id" :value="scale">
                  {{ scale.name }} {{ scale.schoolYear.name }}
                  <span v-if="scale.index > 0"> - Attention : QF{{ scale.index + 1 }}</span>
                </b-select-option>
              </b-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label-for="grid" label="Tarif">
              <b-select
                v-model="selectedGrid"
                :disabled="tariffGrids.length === 0"
                description="Pour l'année sélectionnée"
              >
                <b-select-option v-for="elt of tariffGrids" :key="elt.id" :value="elt">
                  {{ elt.name }}
                </b-select-option>
              </b-select>
            </b-form-group>
          </b-col>
          <b-col class="text-right">
            <br />
            <a
              :class="{ disabled: selectedGrid === null, }"
              class="btn btn-secondary btn-sm"
              href
              @click.prevent="addTariffGrid()"
            >
              Ajouter la grille
            </a>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-for="gridPrice of editedActivity.gridPrices" :key="gridPrice.id" class="grid">
            <b-row cols-lg="grid-header">
              <b-col cols="9">
                <b-form-group :label-for="'gridPriceName' + gridPrice.id" label="Nom">
                  <b-form-input
                    type="text"
                    v-model="gridPrice.name"
                    :id="'gridPriceName' + gridPrice.id"
                    :name="'gridPriceName' + gridPrice.id"
                    placeholder="Nom de la grille"
                    :disabled="gridPrice.id > 0"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="text-right" cols="3">
                <br />
                <a
                  class="btn btn-secondary btn-sm"
                  href
                  @click.prevent="removeGridPrice(gridPrice)"
                  v-b-tooltip="'Supprimer la grille'"
                >
                  <i class="fa fa-close"></i>
                </a>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label-for="'gridPrice0' + gridPrice.id + '-' + getSliceIndex(gridPrice, null)"
                  :label="getSliceName(null)"
                >
                  <decimal-input
                    :id="'gridPrice' + gridPrice.id + '-' + getSliceIndex(gridPrice, null)"
                    :value="getSlicePrice(gridPrice, null)"
                    @change="setSlicePrice(gridPrice, null, $event)"
                    :placeholder="'Prix pour les familles de ' + getSliceName(null)"
                    :disabled="gridPrice.id > 0"
                  >
                  </decimal-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-for="slice of scaleSlices" :key="slice.index">
              <b-col>
                <b-form-group
                  :label-for="'gridPrice' + gridPrice.id + '-' + + getSliceIndex(gridPrice, slice)"
                  :label="getSliceName(slice)"
                >
                  <decimal-input
                    :id="'gridPrice' + gridPrice.id + '-' + getSliceIndex(gridPrice, slice)"
                    :value="getSlicePrice(gridPrice, slice)"
                    @change="setSlicePrice(gridPrice, slice, $event)"
                    :placeholder="'Prix pour les familles de ' + getSliceName(slice)"
                    :disabled="gridPrice.id > 0"
                  >
                  </decimal-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div class="bottom-bar">
        <b-row>
          <b-col cols="8">
            <a
              class="btn btn-danger"
              href
              @click.prevent="onArchive"
              v-if="showArchive"
              :disabled="isLoading(loadingName)"
            >
              Archiver
            </a>
          </b-col>
          <b-col class="text-right">
            <a
              class="btn btn-secondary cancel-button"
              href
              @click.prevent="onCancel"
              :disabled="isLoading(loadingName)"
            >
              Annuler
            </a>
            &nbsp;
            <b-button
              type="submit"
              class="e2et-submit-button"
              variant="primary"
              :disabled="isLoading(loadingName) || !isValid()"
            >
              Enregistrer
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-form>
    <confirm-modal
      v-if="showArchive"
      name="archive-activity"
      title="Archivage de l'activité"
      :text="archiveActivityConfirmText"
      @confirmed="archiveActivity()"
    ></confirm-modal>
    <add-account-modal
      model-name="analytic"
      :modal-id="addAnalyticModal"
      @done="onAnalyticAdded($event)"
    >
    </add-account-modal>
    <add-activity-type-modal
      :modal-id="addActivityTypeModal"
      @done="onActivityTypeAdded($event)"
    ></add-activity-type-modal>
    <add-place-modal
      :modal-id="addPlaceModal"
      @done="onPlaceAdded($event)"
    ></add-place-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import router from '@/router'
import { mapMutations, mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import AddAccountModal from '@/components/Accounting/AddAccountModal'
import AddActivityTypeModal from '@/components/Activities/AddActivityTypeModal'
import DecimalInput from '@/components/Controls/DecimalInput'
import ColorSelect from '@/components/Controls/ColorSelect'
import LoadingGif from '@/components/Controls/LoadingGif'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import AddPlaceModal from '@/components/Base/AddPlaceModal'
import store from '@/store'
import {
  makeActivity, ActivityPricingRule, makeActivityPrice, getActivityPricingRuleName, makeActivityGridPrice,
  ActivityCategory, getActivityCategoryName, makeActivityType, getReminderDetail
} from '@/types/activities'
import { makeChoice, makePlace } from '@/types/base'
import { makeAnalyticAccount, makeGeneralAccount } from '@/types/payments'
import { makeSchoolYear } from '@/types/schools'
import { makeFamilyLevelScale, makeTariffGrid } from '@/types/tariffs'

export default {
  name: 'activity-editor',
  props: {
    activity: Object,
    youthHome: Number,
    category: Number,
  },
  mixins: [BackendMixin],
  components: {
    AddActivityTypeModal,
    AddAccountModal,
    AddPlaceModal,
    ColorSelect,
    DecimalInput,
    LoadingGif,
    ConfirmModal,
  },
  data() {
    return {
      loadingName: 'activity-editor',
      selectedSchoolYear: null,
      schoolYears: [],
      analytics: [],
      generalAccounts: [],
      editedActivity: null,
      selectedAnalytic: null,
      selectedGeneralAccount: null,
      selectedPricingRuleId: 0,
      removedFlatPrice: new Map(),
      allFamilyLevelScales: [],
      selectedScale: null,
      forceSalesAnalyticUpdate: false,
      analyticChanged: false,
      forceSalesGeneralUpdate: false,
      generalChanged: false,
      selectedGrid: null,
      allTariffsGrid: [],
      activityTypes: [],
      addAnalyticModal: 'bv-add-analytic-modal',
      addActivityTypeModal: 'bv-add-activity-type-modal',
      addPlaceModal: 'bv-add-place-modal',
      familyLevelRatioBase100: 0,
      places: [],
      showPlacesSelect: false,
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    activity: function() {
      this.init()
    },
  },
  computed: {
    canEdit() {
      if (this.youthHome) {
        return this.hasPerm('youth.change_youthhomeactivity')
      } else {
        return this.hasPerm('activities.change_coreactivity')
      }
    },
    canAddAnalytic() {
      return this.hasPerm('payments.add_analyticaccount')
    },
    pricingRules() {
      return [
        ActivityPricingRule.Flat, ActivityPricingRule.Percentage, ActivityPricingRule.Grid, ActivityPricingRule.Free
      ].map(
        elt => makeChoice(
          {
            id: elt,
            name: getActivityPricingRuleName(elt, true),
          }
        )
      )
    },
    isFlatPrice() {
      return this.selectedPricingRuleId === ActivityPricingRule.Flat
    },
    isGridPrice() {
      return this.selectedPricingRuleId === ActivityPricingRule.Grid
    },
    isPercentagePrice() {
      return this.selectedPricingRuleId === ActivityPricingRule.Percentage
    },
    isFreePrice() {
      return this.selectedPricingRuleId === ActivityPricingRule.Free
    },
    isFreeOfCharge() {
      return this.isFlatPrice && (this.activity.flatPrices.length === 0)
    },
    isGeneralAccountRequired() {
      if (!this.isFreeOfCharge) {
        return (this.selectedGeneralAccount === null) || (this.selectedGeneralAccount.id === 0)
      }
      return false
    },
    scaleSlices() {
      if (this.selectedScale) {
        return this.selectedScale.slices.filter(elt => elt > 0)
      }
      return []
    },
    familyLevelScales() {
      return this.allFamilyLevelScales.filter(
        elt => (this.selectedSchoolYear && (elt.schoolYear.id === this.selectedSchoolYear.id))
      )
    },
    tariffGrids() {
      if (this.selectedSchoolYear) {
        return [
          makeTariffGrid({ name: 'Nouveau tarif', })
        ].concat(
          this.allTariffsGrid.filter(
            elt => elt.schoolYears.indexOf(this.selectedSchoolYear.id) >= 0
          ).filter(
            elt => this.youthHome ? elt.forYouthHomes : elt.forActivities
          )
        )
      } else {
        return []
      }
    },
    categories() {
      let ids = []
      if (this.youthHome) {
        ids = [ActivityCategory.YouthHome, ActivityCategory.AdultsYouthHome]
      } else {
        ids = [ActivityCategory.Simple, ActivityCategory.Event]
        // ignore ActivityCategory.None, ActivityCategory.Workshop,
      }
      return ids.map(id => makeChoice({ id: id, name: getActivityCategoryName(id), }))
    },
    isEvent() {
      return this.editedActivity && (this.editedActivity.technicalCategory === ActivityCategory.Event)
    },
    archiveActivityConfirmText() {
      return 'L\'activité ' + this.activity.name + ' sera archivée et ne sera donc plus accessible.' +
      '\nÊtes-vous sur de continuer?'
    },
    showArchive() {
      return !this.youthHome && this.activity && this.activity.id
    },
    reminderDaysDescription() {
      return getReminderDetail(this.editedActivity)
    },
    structureReminderInfo() {
      return store.getters.config.structureReminderInfo
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async init() {
      this.cloneActivityForEdition()
      this.editedActivity.flatPrices = [].concat(this.activity.flatPrices)
      this.editedActivity.gridPrices = [].concat(this.activity.gridPrices)
      await this.loadSchoolYears()
      await this.loadAnalytics()
      await this.loadGeneralAccounts()
      await this.loadActivityTypes()
      await this.loadPlaces()
      const currentValue = makeChoice({ id: this.editedActivity.pricingRule, })
      const initialPricingRule = this.getInitialValue(this.pricingRules, currentValue)
      this.selectedPricingRuleId = initialPricingRule ? initialPricingRule.id : 0
      await this.loadFamilyLevelScale()
      await this.loadGridPrices()
      this.familyLevelRatioBase100 = 100 * this.editedActivity.familyLevelRatio
    },
    getColor(event) {
      this.backgroundColor = event.color.background
      this.textColor = event.color.text
    },
    cloneActivityForEdition() {
      this.editedActivity = { ...this.activity, }
      if (this.activity.id === 0) {
        this.editedActivity.technicalCategory = ActivityCategory.Simple
      }
    },
    onAnalyticChanged() {
      const account = this.activity.analyticAccount
      const editedValue = this.selectedAnalytic
      if (account !== null) {
        this.analyticChanged = (editedValue.id !== account.id)
      } else {
        this.analyticChanged = editedValue !== null
      }
      if (!this.analyticChanged) {
        this.forceSalesAnalyticUpdate = false
      }
    },
    onGeneralChanged() {
      const account = this.activity.generalAccount
      const editedValue = this.selectedGeneralAccount
      if (account !== null) {
        this.generalChanged = (editedValue.id !== account.id)
      } else {
        this.generalChanged = editedValue !== null
      }
      if (!this.generalChanged) {
        this.forceSalesGeneralUpdate = false
      }
    },
    isValid() {
      if (!this.editedActivity.name) {
        return false
      }

      if (!this.editedActivity.schoolYear) {
        return false
      }

      if (!this.selectedPricingRuleId) {
        return false
      }

      if (this.isGeneralAccountRequired) {
        return false
      }

      if (this.selectedPricingRuleId === ActivityPricingRule.Percentage) {
        if (this.familyLevelRatioBase100 === null) {
          return false
        }
      }

      if (this.selectedPricingRuleId === ActivityPricingRule.Flat) {
        for (const flatPrice of this.editedActivity.flatPrices) {
          if ((flatPrice.name === '') || (flatPrice.price === null) || (flatPrice.price === '')) {
            return false
          }
        }
      }

      if (this.selectedPricingRuleId === ActivityPricingRule.Grid) {
        if (this.editedActivity.selectedScale === null) {
          return false
        }
        if (this.editedActivity.gridPrices.length === 0) {
          return false
        }
        for (const gridPrice of this.editedActivity.gridPrices) {
          if (gridPrice.name === '') {
            return false
          }
          for (const sliceElt of gridPrice.slices) {
            if ((sliceElt === null) || (sliceElt === '')) {
              return false
            }
          }
        }
      }
      return true
    },
    async onSave() {
      this.startLoading(this.loadingName)
      try {
        let startDate = null
        if (this.editedActivity.startDate) {
          startDate = moment(this.editedActivity.startDate).format('YYYY-MM-DD')
        }
        const schoolYearId = this.editedActivity.id > 0 ? this.editedActivity.schoolYear.id : this.selectedSchoolYear.id
        const data = {
          name: this.editedActivity.name,
          'school_year': schoolYearId,
          'start_time': this.editedActivity.startTime || null,
          'end_time': this.editedActivity.endTime || null,
          day: this.editedActivity.day,
          'start_date': startDate,
          'duration': this.editedActivity.duration,
          'place': this.editedActivity.place.id ? this.editedActivity.place.id : null,
          'seances_count': this.editedActivity.seancesCount,
          'minimum_participants': this.editedActivity.minimumParticipants,
          'maximum_participants': this.editedActivity.maximumParticipants,
          about: this.editedActivity.about,
          conditions: this.editedActivity.conditions,
          'analytic_account': this.selectedAnalytic.id || null,
          'general_account': this.selectedGeneralAccount.id || null,
          'pricing_rule': this.selectedPricingRuleId,
          'min_price': null,
          'max_price': null,
          'family_level_ratio': null,
          'flat_prices': [],
          'scale': null,
          'grid_prices': [],
          keywords: this.editedActivity.keywords,
          'technical_category': this.editedActivity.technicalCategory,
          'background_color': this.backgroundColor,
          'text_color': this.textColor,
          'activity_type': this.editedActivity.activityType.id || null,
          'allow_all_members': this.editedActivity.allowAllMembers,
          'allow_presence': this.editedActivity.allowPresence,
          'weekly_presence': this.editedActivity.weeklyPresence,
        }
        if (this.activity.id) {
          data['force_analytic_sales'] = this.forceSalesAnalyticUpdate
          data['force_general_sales'] = this.forceSalesGeneralUpdate
        } else {
          data.category = this.category || null
        }
        if (this.selectedPricingRuleId === ActivityPricingRule.Free) {
          data['min_price'] = this.editedActivity.minPrice
          data['max_price'] = this.editedActivity.maxPrice
        }
        if (this.selectedPricingRuleId === ActivityPricingRule.Percentage) {
          data['min_price'] = this.editedActivity.minPrice
          data['max_price'] = this.editedActivity.maxPrice
          data['fixed_price'] = this.editedActivity.fixedPrice
          data['family_level_ratio'] = (Math.round(1000 * this.familyLevelRatioBase100) / 100000)
        }
        if (this.selectedPricingRuleId === ActivityPricingRule.Flat) {
          data['flat_prices'] = this.editedActivity.flatPrices.map(
            elt => { return { name: elt.name, price: (+elt.price) || 0, } }
          )
        }
        if (this.selectedPricingRuleId === ActivityPricingRule.Grid) {
          data['scale'] = this.selectedScale.id
          data['grid_prices'] = this.editedActivity.gridPrices.map(
            elt => {
              return {
                id: elt.id,
                name: elt.name,
                prices: elt.slices.filter(slice => slice !== null),
              }
            }
          )
        }
        data['send_reminder'] = this.editedActivity.sendReminder
        if (this.editedActivity.sendReminder) {
          data['reminder_days'] = this.editedActivity.reminderDays
          data['reminder_info'] = this.editedActivity.reminderInfo
          data['show_structure_reminder_info'] = this.editedActivity.showStructureReminderInfo
        } else {
          data['reminder_days'] = 0
          data['reminder_info'] = ''
          data['show_structure_reminder_info'] = false
        }
        let url = '/api/activities/activities/'
        let method = 'post'
        let message = 'L\'activité a été ajoutée'
        if (this.youthHome) {
          url = '/api/youth/activities/'
        }
        if (this.activity.id) {
          url += this.activity.id + '/'
          method = 'patch'
          message = 'L\'activité a été mise à jour'
        }
        let backendApi = new BackendApi(method, url)
        let resp = await backendApi.callApi(data)
        this.$emit('done', { activity: makeActivity(resp.data), })
        await this.addSuccess(message)
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    getInitialValue(array, expected) {
      const expectedId = (expected === null) ? 0 : expected.id
      for (let elt of array) {
        if (elt.id === expectedId) {
          return elt
        }
      }
      return null
    },
    async loadSchoolYears() {
      const backendApi = new BackendApi('get', '/api/active-school-years/')
      try {
        let resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(elt => makeSchoolYear(elt))
        if (this.editedActivity.id) {
          this.selectedSchoolYear = this.getInitialValue(this.schoolYears, this.editedActivity.schoolYear)
        } else if (this.schoolYears.length > 0) {
          this.selectedSchoolYear = this.schoolYears[0]
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadPlaces() {
      const backendApi = new BackendApi('get', '/api/home/places/')
      try {
        let resp = await backendApi.callApi()
        this.places = [makePlace()].concat(resp.data.map(elt => makePlace(elt)))
        this.showPlacesSelect = this.places.length > 1
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadAnalytics() {
      let url = '/api/analytic-accounts/'
      const backendApi = new BackendApi('get', url)
      this.analytics = []
      try {
        const resp = await backendApi.callApi()
        this.analytics = [makeAnalyticAccount({ id: 0, name: 'sans code', })].concat(
          resp.data.map(elt => makeAnalyticAccount(elt))
        )
        this.selectedAnalytic = this.getInitialValue(this.analytics, this.editedActivity.analyticAccount)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    showAddAnalyticModal() {
      this.$bvModal.show(this.addAnalyticModal)
    },
    async onAnalyticAdded(event) {
      const newAnalytic = makeAnalyticAccount(event.account)
      this.selectedAnalytic = newAnalytic
      this.analytics.push(newAnalytic)
      this.onAnalyticChanged()
    },
    showAddActivityTypeModal() {
      this.$bvModal.show(this.addActivityTypeModal)
    },
    async onActivityTypeAdded(event) {
      const newActivityType = makeActivityType(event)
      this.editedActivity.activityType = newActivityType
      this.activityTypes.push(newActivityType)
    },
    showAddPlaceModal() {
      this.$bvModal.show(this.addPlaceModal)
    },
    async onPlaceAdded(event) {
      const newPlace = makePlace(event)
      this.editedActivity.place = newPlace
      this.places.push(newPlace)
    },
    async loadActivityTypes() {
      let url = '/api/activities/activity-type/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.activityTypes = resp.data.map(elt => makeActivityType(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadGeneralAccounts() {
      let url = '/api/general-accounts/'
      const backendApi = new BackendApi('get', url)
      this.generalAccounts = []
      try {
        const resp = await backendApi.callApi()
        this.generalAccounts = [makeGeneralAccount({ id: 0, name: '', })].concat(
          resp.data.map(elt => makeGeneralAccount(elt)).filter(
            elt => elt.isPayment
          )
        )
        this.selectedGeneralAccount = this.getInitialValue(this.generalAccounts, this.editedActivity.generalAccount)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onArchive() {
      if (!this.isLoading(this.loadingName) && this.activity && this.activity.id) {
        this.$bvModal.show('bv-confirm-modal:archive-activity')
      }
    },
    async archiveActivity() {
      if (this.activity.id && !this.youthHome) {
        this.startLoading(this.loadingName)
        try {
          const url = '/api/activities/activities/' + this.activity.id + '/'
          let backendApi = new BackendApi('delete', url)
          await backendApi.callApi()
          await this.addSuccess('L\'activité ' + this.activity.name + ' a été archivée')
          await router.push({ name: 'activities-list', })
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    onCancel() {
      if (!this.isLoading(this.loadingName)) {
        this.$emit('cancel')
      }
    },
    removeFlatPrice(flatPrice) {
      this.editedActivity.flatPrices.splice(flatPrice.index, 1)
      for (let index = 0; index < this.editedActivity.flatPrices.length; index++) {
        this.editedActivity.flatPrices[index].index = index
      }
    },
    addFlatPrice() {
      this.editedActivity.flatPrices.push(
        makeActivityPrice(this.editedActivity.flatPrices.length)
      )
    },
    addTariffGrid() {
      if (this.selectedGrid) {
        if (this.selectedGrid.id === 0) {
          this.editedActivity.gridPrices.push(
            makeActivityGridPrice(
              {
                id: -this.editedActivity.gridPrices.length,
                slices: Array(14).map(elt => {
                  return { index: elt, }
                }),
              }
            )
          )
        } else {
          this.editedActivity.gridPrices.push(
            makeActivityGridPrice(
              {
                id: this.selectedGrid.id,
                name: this.selectedGrid.name,
                slices: this.selectedGrid.slices,
              }
            )
          )
        }
      }
      this.selectedGrid = null
    },
    removeGridPrice(grid) {
      const index = this.editedActivity.gridPrices.map(elt => elt.id).indexOf(grid.id)
      if (index >= 0) {
        this.editedActivity.gridPrices.splice(index, 1)
      }
    },
    getSliceName(slice) {
      if (!slice) {
        return 'QF le plus haut'
      } else {
        return 'QF < ' + slice
      }
    },
    getSliceIndex(grid, slice) {
      let index = 0
      if (slice) {
        index = this.scaleSlices.indexOf(slice) + 1
      }
      return index
    },
    getSlicePrice(grid, slice) {
      const index = this.getSliceIndex(grid, slice)
      return grid.slices[index]
    },
    setSlicePrice(grid, slice, value) {
      const index = this.getSliceIndex(grid, slice)
      grid.slices[index] = value
      grid.slices = [].concat(grid.slices)
    },
    async loadFamilyLevelScale() {
      this.allFamilyLevelScales = []
      this.selectedScale = null
      if (this.hasPerm('tariffs.view_familylevelscale')) {
        let url = '/api/tariffs/family-level-scale/'
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.allFamilyLevelScales = resp.data.map(elt => makeFamilyLevelScale(elt)).filter(
            elt => (elt.slices.length > 0 && elt.slices[0])
          )
          if (this.editedActivity.id) {
            for (const familyLevelScale of this.familyLevelScales) {
              if (familyLevelScale.id === this.editedActivity.scale) {
                this.selectedScale = familyLevelScale
                break
              }
            }
          } else if (this.familyLevelScales.length === 1) {
            this.selectedScale = this.familyLevelScales[0]
          }
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
      }
    },
    async loadGridPrices() {
      if (this.hasPerm('tariffs.view_tariffgrid')) {
        let url = '/api/tariffs/tariff-grid/'
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.allTariffsGrid = resp.data.map(elt => makeTariffGrid(elt))
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
      }
    },
  },
}
</script>

<style lang="less">
.bottom-bar {
  margin-top: 10px;
  padding-top: 10px;
  border-top: solid 1px #222;
}
.grid {
  border: solid 1px #f0f0f0;
  padding: 2px;
  border-radius: 4px;
  margin-bottom: 10px;
}
.grid-header {
  border-bottom: solid 1px #f0f0f0;
}
.warning {
  background: #ffca22;
  padding: 4px;
  margin-bottom: 5px;
  color: #222222;
  font-weight: bold;
}
.small-info {
  font-size: 12px;
  background: #ffca22;
}
.no-price-label {
  font-weight: bold;
  margin-top: 35px;
  margin-bottom: 12px;
  padding: 5px 10px;
  border-radius: 4px;
  background: #ddd;
}
.required-field {
  color: #c9413c;
  font-weight: bold;
}
.reminder-info {
  background: #ccc;
  padding: 10px;
  margin: 10px 0;
}
</style>
