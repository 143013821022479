<template>
  <div class="tools" v-if="hasPerm('home')">
    <page-header title="Outils" icon="fas fa-tools"></page-header>
    <b-row>
      <b-col>
        <div>
          <ul class="app-menu">
            <li v-if="hasPerm('reports')">
              <router-link :to="{ name: 'stats-youth-home', }">
                <i class="fas fa-chart-bar"></i> Déclaration CAF - Par types de séances
              </router-link>
            </li>
            <li v-if="hasPerm('reports')">
              <router-link :to="{ name: 'stats-youth-home-detail', }">
                <i class="fas fa-chart-bar"></i> Déclaration CAF - Paramétrage personnalisé
              </router-link>
            </li>
            <li v-if="hasAllPerms(['youth.view_seance', 'documents.view_taxyearlycertificaterule'])">
              <router-link :to="{ name: 'yearly-tax-certificates', }">
                <i class="fas fa-money-bill-alt"></i> Attestation fiscale de frais de garde
              </router-link>
            </li>
            <li v-if="hasPerm('reports')">
              <router-link :to="{ name: 'stats-youth-report', }">
                <i class="fas fa-chart-bar"></i> Déclaration Conseil Départemental
              </router-link>
            </li>
            <li v-if="hasPerm('reports')">
              <router-link :to="{ name: 'stats-city-report', }">
                <i class="fas fa-chart-bar"></i> Répartition CAF par communes
              </router-link>
            </li>
            <li v-if="hasPerm('reports')">
              <router-link :to="{ name: 'stats-handicap-report', }">
                <i class="fas fa-table-cells"></i> Liste des inscriptions AEEH
              </router-link>
            </li>
            <li v-if="hasPerm('families.change_family')">
              <router-link :to="{ name: 'archive-families', }">
                <i class="fa fa-users-slash"></i> Familles à archiver
              </router-link>
            </li>
            <li v-if="hasPerm('families.view_family')">
              <router-link :to="{ name: 'single-individuals', }">
                <i class="fa fa-th-list"></i> Liste des individus hors famille et structures
              </router-link>
            </li>
            <li v-if="hasPerm('home.view_place')">
              <router-link :to="{ name: 'tools-places', }">
                <i class="fas fa-list"></i> Liste des lieux
              </router-link>
            </li>
            <li v-if="hasPerm('agenda.view_activitydate')">
              <router-link :to="{ name: 'agenda-activity-dates', }">
                <i class="fa fa-location-dot"></i> Occupation des lieux
              </router-link>
            </li>
            <li v-if="hasPerm('agenda.view_activitydate')">
              <router-link :to="{ name: 'agenda-day-dates', }">
                <i class="fa fa-calendar-day"></i> Agenda du jour
              </router-link>
            </li>
            <li
              v-if="hasOnePerm(['activity.change_activityinscription', 'youth.change_youthhomeactivityinscription'])"
            >
              <router-link :to="{ name: 'renew-activity-inscriptions', }">
                <i class="fa fa-person-walking-dashed-line-arrow-right"></i>
                Renouvellement/Transfert d'inscriptions aux activités
              </router-link>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/Layout/PageHeader'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'tools-home',
  mixins: [BackendMixin],
  components: {
    PageHeader,
  },
}
</script>

<style lang="less">
</style>
