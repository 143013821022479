<template>
  <span v-if="hasPerm('payments.change_invoice')">
    <b-modal dialog-class="modal-md"
      :id="id"
      :cancel-disabled="isLoading(loadingName)"
      :ok-disabled="isLoading(loadingName)"
      @ok.prevent="onConfirm"
      ok-title="Confirmer"
      cancel-title="Annuler"
    >
      <template v-slot:modal-title>
        <b v-if="action">
          <i v-if=action.icon :class="action.icon"></i>&nbsp;
          {{ action.label }}
        </b>
      </template>
      <div v-if="action">
        <div class="error-text" v-if="errorText">
          {{ errorText }}
        </div>
        <table class="table small">
          <tr>
            <th>Facture</th>
            <th>Famille</th>
            <th>Total</th>
            <th>À payer</th>
          </tr>
          <tr v-for="elt of invoices" :key="elt.invoice.id">
            <td>
              <span v-if="elt.invoiceNumber">N°{{ elt.invoiceNumber }}</span>
              <span v-else>Pré-facture</span>
            </td>
            <td>{{ elt.invoice.entity.name }}</td>
            <td>{{ elt.invoice.totalPrice | currency }}</td>
            <td>{{ elt.invoice.toBePaidPrice() | currency }}</td>
          </tr>
        </table>
        <div v-for="field of action.fields" :key="field.id">
          <b-form-group
            :label-for="field.name" :description="field.help"
            v-if="field.type === 'checkbox'"
          >
            <b-form-checkbox
              :name="field.name"
              v-model="fieldValues[field.name]"
              @change="updateData"
            >
              {{ field.label }}
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
            :label="field.label" :label-for="field.name" :description="field.help"
            v-else
          >
            <b-input
              :type="field.type"
              :name="field.name"
              v-model="fieldValues[field.name]"
              @change="updateData"
            >
            </b-input>
          </b-form-group>
        </div>
      </div>
    </b-modal>
  </span>
</template>

<script>

import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'

export default {
  name: 'multi-invoicing-action-modal',
  components: {
  },
  mixins: [
    BackendMixin
  ],
  props: {
    invoices: Array,
    action: Object,
    id: String,
  },
  data() {
    return {
      fieldValues: {},
      errorText: '',
      loadingName: 'MultiInvoicingAction',
    }
  },
  computed: {
  },
  mounted() {
    this.init()
  },
  watch: {
    action: function() {
      this.init()
    },
  },
  methods: {
    ...mapActions(['addSuccess']),
    init() {
      if (this.action) {
        for (const field of this.action.fields) {
          if (field.type === 'checkbox') {
            this.fieldValues[field.name] = false
          } else {
            this.fieldValues[field.name] = ''
          }
        }
      }
    },
    async onConfirm() {
      this.errorText = ''
      try {
        this.startLoading(this.loadingName)
        const backendApi = new BackendApi('post', this.action.url)
        const data = { ...this.fieldValues, }
        data.invoices = this.invoices.map(elt => elt.id)
        await backendApi.callApi(data)
        this.$emit('done')
        this.endLoading(this.loadingName)
        this.$bvModal.hide(this.id)
        await this.addSuccess('L\'opération a été exécutée')
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    updateData() {
      this.fieldValues = { ...this.fieldValues, }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
