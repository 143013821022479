<template>
  <div v-if="isAuthenticated">
    <div v-b-tooltip.hover.right="'millibase.net - Accueil'" v-b-tooltip.hover.right.v-info class="app-icon">
      <router-link :to="{ name: 'home', }">
        <app-logo size="icon-bar"></app-logo>
      </router-link>
    </div>
    <ul class="icons-bar">
      <li v-b-tooltip.hover.right="'Retour'" v-b-tooltip.hover.right.v-info>
        <a href @click="onBack">
          <i class="fa fa-arrow-circle-left"></i>
        </a>
      </li>
      <li
        v-if="hasPerm('families.view_familyentity')"
        v-b-tooltip.hover.right="'Familles'"
        v-b-tooltip.hover.right.v-info
      >
        <router-link :to="{ name: 'families-list', }"><i class="fa-solid fa-people-roof"></i></router-link>
      </li>
      <li
        v-if="hasPerm('people.view_contact')"
        v-b-tooltip.hover.right="'Annuaire'"
        v-b-tooltip.hover.right.v-info
      >
        <router-link :to="{ name: 'entities-list', }"><i class="fa fa-address-book"></i></router-link>
      </li>
      <li
        v-if="activityMenus.length === 0 && hasPerm('activities.view_coreactivity')"
        v-b-tooltip.hover.right="'Activités'"
        v-b-tooltip.hover.right.v-info
      >
        <router-link :to="{ name: 'activities-list', }"><i class="fas fa-chess-knight"></i></router-link>
      </li>
      <li
        v-for="menu of activityMenus"
        v-b-tooltip.hover.right="menu.label"
        v-b-tooltip.hover.right.v-info
        :key="menu.id"
      >
        <router-link :to="{ name: 'activities-list', query: { category: menu.category, }, }">
          <i :class="menu.icon"></i>
        </router-link>
      </li>
      <li
        v-if="hasPerm('youth.view_seance')"
        v-b-tooltip.hover.right="youthHomeLabel"
        v-b-tooltip.hover.right.v-info
      >
        <router-link :to="{ name: 'youth-homes-list', }"><i :class="youthHomeIcon"></i></router-link>
      </li>
      <li
        v-if="isPortalActive && hasPerm('portal')"
        v-b-tooltip.hover.right="'Portail familles'"
        v-b-tooltip.hover.right.v-info
      >
        <router-link :to="{ name: 'family-portal-home', }"><i class="fa-solid fa-house-signal"></i></router-link>
      </li>
      <li
        v-if="nursery && hasPerm('nursery.view_')"
        v-b-tooltip.hover.right="'Crèche'"
        v-b-tooltip.hover.right.v-info
      >
        <router-link :to="{ name: 'nursery-home', }">
          <i class="fa fa-baby-carriage"></i>
        </router-link>
      </li>
      <li
        v-if="isStoreActive && hasPerm('store.view_article')"
        v-b-tooltip.hover.right="'Ludothèque'"
        v-b-tooltip.hover.right.v-info
      >
        <router-link :to="{ name: 'store-articles', }">
          <i class="fa fa-dice"></i>
        </router-link>
      </li>
      <li
        v-if="isPublicationActive && hasPerm('publications.view_publication')"
        v-b-tooltip.hover.right="publicationTitle"
        v-b-tooltip.hover.right.v-info
      >
        <router-link :to="{ name: 'publications-home', }">
          <i class="fa fa-book-open-reader"></i>
        </router-link>
      </li>
      <li
        v-if="hasPerm('payments')"
        v-b-tooltip.hover.right="'Suivi comptable'"
        v-b-tooltip.hover.right.v-info
      >
        <router-link :to="{ name: 'invoices-synthesis', }"><i class="fa fa-euro-sign"></i></router-link>
      </li>
      <li
        v-if="hasPerm('stats')"
        v-b-tooltip.hover.right="'Statistiques'"
        v-b-tooltip.hover.right.v-info
      >
        <router-link :to="{ name: 'stats-home', }"><i class="fas fa-chart-pie"></i></router-link>
      </li>
      <li
        v-if="hasOnePerm(['notes.view_notes', 'log_books.view_logbook'])"
        v-b-tooltip.hover.right="'Communiquer'"
        v-b-tooltip.hover.right.v-info
      >
        <router-link :to="{ name: 'notes-home', }">
          <i class="fa fa-bullhorn"></i>
        </router-link>
      </li>
      <li
        v-if="hasPerm('reports.view_')"
        v-b-tooltip.hover.right="'Rapports'"
        v-b-tooltip.hover.right.v-info
      >
        <router-link :to="{ name: 'reports-home', }"><i class="fa fa-folder-open"></i></router-link>
      </li>
      <li
        v-if="hasPerm('home.view_structure')"
        v-b-tooltip.hover.right="'Outils'"
        v-b-tooltip.hover.right.v-info
      >
        <router-link :to="{ name: 'tools-home', }"><i class="fa fa-tools"></i></router-link>
      </li>
      <li
        v-if="hasPerm('home.view_structure')"
        v-b-tooltip.hover.right="'Admin'"
        v-b-tooltip.hover.right.v-info
      >
        <router-link :to="{ name: 'admin-home', }"><i class="fa fa-cogs"></i></router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import store from '@/store'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import AppLogo from '@/components/Layout/AppLogo'

export default {
  name: 'IconBar',
  components: { AppLogo, },
  mixins: [BackendMixin],
  props: {
    version: String,
  },
  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated
    },
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    youthHomeIcon() {
      return store.getters.youthHomeIcon
    },
    nursery() {
      return store.getters.config.nursery
    },
    isStoreActive() {
      return store.getters.config.isStoreActive
    },
    isPublicationActive() {
      return store.getters.config.isPublicationActive
    },
    publicationTitle() {
      return store.getters.config.publicationTitle
    },
    isPortalActive() {
      return store.getters.config.isPortalActive
    },
    activityMenus() {
      if (this.hasPerm('activities.view_coreactivity')) {
        return store.getters.config.activityMenus
      }
      return []
    },
  },
  methods: {
    onBack(evt) {
      store.commit('setEditMode', '')
      evt.preventDefault()
      router.back()
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  ul.icons-bar {
    position: absolute;
    top: 88px;
    left: 15px;
    width: 60px;
    margin: 0;
    padding: 10px 0 20px 0;
    list-style: none;
    background: #4d85af;
    background: linear-gradient(180deg, #4d85af, #385e7d);
    background: linear-gradient(180deg, #4d85af, #416d90);
    color: #fff;
    border-radius: 4px;
  }

  ul.icons-bar li {
    padding: 5px 0;
    margin: 5px 0 5.5px 0;
    text-align: center;
  }

  ul.icons-bar li a{
    padding: 10px;
    font-size: 19px;
    color: #fff !important;
    cursor: pointer;
  }

  ul.icons-bar li a:hover {
    color: #ccc !important;
  }
  .app-icon {
    z-index: 20;
    position: absolute;
    width: 70px;
    height: 70px;
    left: 20px;
    text-align: center;
    top: 13px;
  }
</style>
