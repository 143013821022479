<template>
  <div class="log_books"  v-if="hasPerm('log_books.view_logbook')">
    <page-header :title="title" icon="fa fa-ship" :links="getLinks()"></page-header>
    <div>
      <div class="filter-block" v-if="!isLoading(loadingName)">
        <div v-if="logBooks.length === 0">
          Aucun journal de bord n'est configuré
        </div>
        <div class="filter-line" v-else>
          <b-row>
            <b-col cols="4" v-if="logBooks.length > 1">
              <b-form-group
                id="logbooks-group"
                label="Journaux"
                label-for="logbooks"
              >
                <b-form-select
                  id="logbooks"
                  v-model="selectedLogBook"
                  :disabled="logBooks.length === 1"
                >
                  <b-form-select-option
                    :value="item"
                    v-for="item in logBooks"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <div>
                <b-checkbox v-model="extended" id="extended">Affichage étendu</b-checkbox>
              </div>
              <div v-if="keywords.length">
                <b>Mot-clefs</b>
                <check-box-select
                  id="logbook-keywords"
                  inline
                  :choices="keywords"
                  :style-callback="keywordStyleCallback"
                  @changed="selectedKeywords = $event.choices"
                >
                </check-box-select>
              </div>
            </b-col>
            <b-col cols="4" class="text-right">
              <b-checkbox
                v-show="false"
                :checked="noPagination"
                :disabled="noPaginationDisabled"
                @change="reloadPagination"
                style="margin-bottom: 5px"
              >
                Tout voir
              </b-checkbox>
              <pagination
                v-show="selectedLogBook"
                :pages-count="pagesCount"
                :has-next="hasNext"
                :has-previous="hasPrevious"
                :selector="paginationSelector"
                @change="onPageChanged($event)"
                :disabled="isLoading(loadingName) && pagesCount === 1"
                small
                css-style="justify-content: flex-end;"
              >
              </pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div class="empty-text" v-if="selectedLogBook && !isLoading(loadingName) && items.length === 0">
      Aucune entrée
    </div>
    <div ref="excelTable" v-else>
      <div v-if="selectedLogBook" class="hide-here">{{ selectedLogBook.name }}</div>
      <x-table class="small-table" id="logbook" :items="items" :columns="columns"></x-table>
    </div>
    <add-logbook-item-modal
      v-if="selectedLogBook"
      :log-book="selectedLogBook"
      :keywords="keywords"
      @done="loadElements()"
    >
    </add-logbook-item-modal>
    <confirm-modal
      v-if="selectedItem"
      name="delete-logbook-item"
      icon="fa fa-trash"
      :object="selectedItem"
      title="Archivage d'une entrée"
      text="Voulez-vous archiver cette entrée"
      :text2="selectedItem.text"
      @confirmed="onConfirmDeleteItem"
    ></confirm-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import LoadingGif from '@/components/Controls/LoadingGif'
import Pagination from '@/components/Controls/Pagination'
import XTable from '@/components/Controls/Table/Table.vue'
import PageHeader from '@/components/Layout/PageHeader'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect.vue'
import AddLogbookItemModal from '@/components/LogBook/AddLogBookItemModal.vue'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'
import { makeLogBook, makeLogBookItem, makeLogbookKeyword } from '@/types/log_books'
import { BackendApi, openDocument } from '@/utils/http'
import { htmlText } from '@/utils/html'
import { slugify } from '@/utils/strings'
import { dateToString } from '@/filters/texts'

export default {
  name: 'LogBook',
  mixins: [BackendMixin],
  components: {
    ConfirmModal,
    CheckBoxSelect,
    AddLogbookItemModal,
    XTable,
    Pagination,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'logbook',
      logBooks: [],
      selectedLogBook: null,
      itemsCount: 0,
      page: 0,
      hasNext: false,
      hasPrevious: false,
      rawItems: [],
      columns: [],
      noPagination: false,
      extended: false,
      keywords: [],
      selectedKeywords: [],
      selectedItem: null,
    }
  },
  watch: {
    selectedLogBook() {
      this.columns = this.getColumns()
      this.loadElements()
    },
    extended() {
      this.columns = this.getColumns()
    },
    selectedKeywords() {
      this.loadElements()
    },
  },
  computed: {
    title() {
      if (this.logBooks.length === 1) {
        return 'Journal de bord'
      } else {
        return 'Journaux de bord'
      }
    },
    paginationSelector() {
      return ''
    },
    noPaginationDisabled() {
      return (
        this.isLoading(this.loadingName) ||
        ((this.pagesCount === 1 && (this.rawItems.length <= 100))) ||
        (this.pagesCount > 10)
      )
    },
    pagesCount() {
      if (this.noPagination) {
        return 1
      }
      return Math.ceil(this.itemsCount / 100)
    },
    canAddItem() {
      return this.hasPerm('log_books.add_logbookitem')
    },
    items() {
      return this.rawItems.map(this.makeItem)
    },
  },
  async mounted() {
    await this.loadLogBooks()
    await this.loadKeywords()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    getColumns() {
      const columns = [
        {
          name: 'date',
          label: 'Date',
          dateFormat: 'ddd D MMM YYYY',
        }
      ]
      if (this.selectedLogBook) {
        for (const col of this.selectedLogBook.columns) {
          columns.push(
            {
              name: 'col' + col.id,
              label: col.name,
              number: col.isNumeric,
            }
          )
        }
      }
      columns.push(
        {
          name: 'text',
          label: 'Commentaires',
        }
      )
      if (this.keywords) {
        columns.push(
          {
            name: 'keywords',
            label: 'Mots-clefs',
            contentCallback: (col, item) => {
              if (item.keywords.length) {
                const keywords = this.keywords.filter(elt => item.keywords.indexOf(elt.id) >= 0)
                return keywords.map(
                  elt => {
                    return '<span class="lb-keyword" ' + elt.getStyleText() + '>' + elt.name + '</span>'
                  }
                ).join(' ')
              }
              return ''
            },
          }
        )
      }
      if (this.extended) {
        columns.push(
          {
            name: 'time',
            label: 'Heure',
          },
          {
            name: 'author',
            label: 'Auteur',
          }
        )
      }
      if (this.hasPerm('log_books.delete_logbookitem')) {
        columns.push(
          {
            name: 'delete',
            label: '  ',
            hideFilter: true,
            alignRight: true,
            maxWidth: '25px',
            isLink: item => {
              return (item)
            },
            onClick: item => {
              this.onDeleteItem(item)
            },
          }
        )
      }
      return columns
    },
    makeItem(rawItem) {
      const item = {
        id: rawItem.id,
        date: rawItem.createdOn,
        time: dateToString(rawItem.createdOn, 'HH:mm'),
        author: rawItem.createdBy,
        text: htmlText(rawItem.text),
        keywords: rawItem.keywords,
        delete: '<i class="fa fa-trash"></i>',
      }
      for (const elt of rawItem.values) {
        item['col' + elt.column] = elt.value
      }
      return item
    },
    async loadLogBooks() {
      try {
        let url = '/log-books/api/log-books/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.logBooks = resp.data.map(makeLogBook)
        if (this.logBooks.length === 1) {
          this.selectedLogBook = this.logBooks[0]
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadKeywords() {
      try {
        let url = '/log-books/api/keywords/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.keywords = resp.data.map(makeLogbookKeyword)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onPageChanged(event) {
      this.page = event.page
      this.loadElements()
    },
    reloadPagination(value) {
      this.noPagination = value
      this.loadElements()
    },
    async loadElements() {
      this.rawItems = []
      this.selectedItem = null
      if (this.selectedLogBook) {
        this.startLoading(this.loadingName)
        let url = '/log-books/api/log-books/' + this.selectedLogBook.id + '/?'
        if (this.page) {
          url += 'page=' + this.page
        }
        if (this.selectedKeywords.length) {
          url += '&keywords=' + this.selectedKeywords.map(elt => '' + elt.id).join(',')
        }
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          if (this.noPagination) {
            this.itemsCount = resp.data.length
            this.hasNext = false
            this.hasPrevious = false
            this.rawItems = resp.data.map(makeLogBookItem)
          } else {
            this.itemsCount = resp.data.count
            this.hasNext = !!resp.data.next
            this.hasPrevious = !!resp.data.previous
            this.rawItems = resp.data.results.map(makeLogBookItem)
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    getLinks() {
      const isActive = !this.isLoading(this.loadingName) && this.selectedLogBook && this.rawItems.length
      const isActive2 = !this.isLoading(this.loadingName) && this.selectedLogBook
      const links = [
        {
          id: 1,
          label: 'Excel',
          callback: this.toExcel,
          icon: 'fa fa-file-excel',
          cssClass: isActive ? 'btn-secondary' : 'btn-secondary disabled',
        },
        {
          id: 2,
          label: 'PDF',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: isActive ? 'btn-secondary' : 'btn-secondary disabled',
        }
      ]
      if (this.canAddItem) {
        links.push(
          {
            id: 3,
            label: 'Ajouter',
            callback: () => {
              this.$bvModal.show('bv-modal-add-logbook-item')
            },
            icon: 'fa fa-plus',
            cssClass: isActive2 ? 'btn-primary' : 'btn-primary disabled',
          }
        )
      }
      return links
    },
    async toExcel() {
      const isActive = !this.isLoading(this.loadingName) && this.selectedLogBook && this.rawItems.length
      if (isActive) {
        const docUrl = '/documents/table-to-excel/<key>/'
        const docSlug = 'journal-de-bord-' + slugify(this.selectedLogBook.name)
        const docContent = this.$refs.excelTable.innerHTML.toString()
        try {
          await openDocument(docUrl, docSlug, docContent)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async printMe() {
      const isActive = !this.isLoading(this.loadingName) && this.selectedLogBook && this.rawItems.length
      if (isActive) {
        const docUrl = '/documents/standard/<key>/pdf/' // '?landscape=1'
        const docContent = this.$refs.excelTable.innerHTML.toString()
        const docSlug = 'journal-de-bord-' + slugify(this.selectedLogBook.name)
        try {
          await openDocument(docUrl, docSlug, docContent)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    keywordStyleCallback(keyword) {
      return keyword.getStyle()
    },
    onDeleteItem(item) {
      this.selectedItem = item
      const that = this
      this.$nextTick(
        () => that.$bvModal.show('bv-confirm-modal:delete-logbook-item')
      )
    },
    async onConfirmDeleteItem() {
      try {
        let url = '/log-books/api/delete-item/' + this.selectedItem.id + '/'
        let backendApi = new BackendApi('delete', url)
        await backendApi.callApi()
        await this.loadElements()
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style lang="less">
.lb-keyword {
  padding: 2px;
  font-size: 16px;
  margin: 1px;
  display: inline-block;
  border: solid 1px #ccc;
  border-radius: 4px;
}
</style>
