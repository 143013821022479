<template>
  <span class="hide-here">
    <span :style="getStyle()">{{ label }} {{ printHourValue }}</span>
  </span>
</template>
<script>
import moment from 'moment'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'print-hour',
  mixins: [BackendMixin],
  props: {
    print: Boolean,
    label: {
      type: String,
      default: '',
    },
    style: {
      type: Object,
      default: null,
    },
  },
  components: {
  },
  computed: {
  },
  data() {
    return {
      printHourValue: '',
    }
  },
  watch: {
    print: function() {
      if (this.print) {
        this.printHourValue = moment().format('DD/MM/YYYY HH:mm')
      }
    },
  },
  methods: {
    getStyle() {
      if (this.style) {
        return this.style
      }
      return {}
    },
  },
  mounted() {
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
