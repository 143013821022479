<template>
  <div class="note-reply-editor">
    <div class="form-group">
      <b-row>
        <b-col>
          <b-form-group label-for="replyBody" label="Message">
            <b-textarea name="replyBody" v-model="replyBody"></b-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="buttons-bar">
        <b-col class="text-right">
          <a class="btn btn-secondary btn-sm" href @click.prevent="onCancel">Annuler</a>
          <a class="btn btn-primary btn-sm" href @click.prevent="onSave" :class="{ disabled: replyBody === '', }">
            {{ saveTitle }}
          </a>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeStaffUser } from '@/types/users'
import { BackendApi } from '@/utils/http'
import { makeNote } from '@/types/notes'

export default {
  name: 'note-reply-editor',
  components: {},
  mixins: [BackendMixin],
  props: {
    note: {
      type: Object,
    },
    reply: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      replyBody: '',
      staff: [],
    }
  },
  computed: {
    saveTitle() {
      return 'Enregistrer'
    },
  },
  mounted() {
    this.loadStaff()
    this.init()
  },
  watch: {
    note: function() {
      this.init()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    init() {
      if (this.reply) {
        this.replyBody = this.reply.body
      } else {
        this.replyBody = ''
      }
    },
    async onSave() {
      if (this.replyBody) {
        let url = ''
        let message = ''
        if (this.reply) {
          url = '/notes/api/change-reply/' + this.reply.id + '/'
          message = 'La réponse a été mise à jour'
        } else {
          url = '/notes/api/reply-note/' + this.note.id + '/'
          message = 'La réponse a été ajoutée'
        }
        const data = {
          body: this.replyBody,
        }
        const backendApi = new BackendApi(this.reply ? 'patch' : 'post', url)
        try {
          const resp = await backendApi.callApi(data)
          this.$emit('saved', makeNote(resp.data))
          await this.addSuccess(message)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadStaff() {
      this.staff = []
      let backendApi = new BackendApi('get', '/users/api/users/?include_superuser=1&notes=1')
      try {
        let resp = await backendApi.callApi()
        this.staff = resp.data.map(elt => makeStaffUser(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onCancel() {
      this.$emit('cancelled')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
