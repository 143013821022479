
export function escapeHtml(unsafe: string) {
  return unsafe.replace(
    /&/g, '&amp;'
  ).replace(
    /</g, '&lt;'
  ).replace(
    />/g, '&gt;'
  ).replace(
    /"/g, '&quot;'
  ).replace(
    /'/g, '&#039;'
  )
}

export function htmlText(text: string) {
  const escapedMessage = escapeHtml(text)
  return escapedMessage.split('\n').join('<br />')
}

export function replaceAll(text: string, search: string, replacement: string): string {
  return text.split(search).join(replacement)
}

export function enrichedHtmlText(text: string) {
  const escapedMessage = escapeHtml(text)
  let html: string = replaceAll(
    escapedMessage, '[b]', '<b>'
  )
  html = replaceAll(
    html, '[/b]', '</b>'
  )
  return html.split('\n').join('<br />')
}

/**
 * Convertit les URLs d'un texte en liens HTML avec target="_blank"
 * @param text Le texte contenant potentiellement des URLs
 * @returns Le texte avec les URLs converties en liens HTML
 */
export function convertUrlsToLinks(text: string): string {
  const escapedMessage = escapeHtml(text)
  // Regex pour détecter les URLs
  // Détecte les URLs commençant par http://, https://, ou www.
  const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+\.\w+\S*)/g
  // Remplace chaque URL trouvée par un lien HTML
  const safe = escapedMessage.replace(urlRegex, (url: string) => {
    // Ajoute http:// aux URLs commençant par www. si nécessaire
    let href = url.startsWith('www.') ? `https://${url}` : url
    href = href.replace('&amp;', '&')
    return `<a href="${href}" target="_blank">${url}</a>`
  })
  return safe.split('\n').join('<br />')
}
