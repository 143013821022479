<template>
  <div class="notes-list" v-if="canViewNotes">
    <div>
      <page-header title="Notes" icon="fa fa-sticky-note"></page-header>
      <div class="filter-bar">
        <b-row>
          <b-col cols="4">
            <b-form @submit.prevent="onSearchNotes()">
              <b-input-group prepend="Recherche" class="mt-3 input-group-primary sm">
                <b-form-input
                  id="search-notes"
                  type="search"
                  placeholder="Entrez un mot du sujet"
                  v-model="searchText"
                  autocomplete="off"
                  :class="{ filled: searchText !== '' }"
                >
                </b-form-input>
                <b-input-group-append>
                  <b-button type="submit" variant="primary">
                    <i class="fa fa-search"></i>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form>
          </b-col>
          <b-col cols="3">
            <b-form-checkbox id="unread" v-model="unread">
              <i class="fa fa-glasses"></i> non-lues
            </b-form-checkbox>
            <b-form-checkbox id="pinned" v-model="pinned">
              <i class="fa fa-map-pin"></i> épinglés
            </b-form-checkbox>
          </b-col>
          <b-col cols="3">
            <b-form-checkbox id="include-archived-notes2" v-model="includeArchives">
              Inclure les archives
            </b-form-checkbox>
            <b-form-checkbox id="viewBody" v-model="viewBody">
              Afficher le contenu
            </b-form-checkbox>
          </b-col>
          <b-col cols="2" class="text-right">
            <unread-notes-counters @selected="onUserSelected">
            </unread-notes-counters>
          </b-col>
        </b-row>
      </div>
      <div class="sub-header">
        <b-row>
          <b-col cols="8">
            <b>
              <counter-label :counter="notesCount" label="note"></counter-label>
            </b>
          </b-col>
          <b-col cols="4" class="text-right">
            <pagination
              :pages-count="pagesCount"
              :has-next="hasNext"
              :has-previous="hasPrevious"
              :selector="paginationSelector"
              @change="onPageChanged($event)"
              style="display: inline-block;"
            >
            </pagination>
          </b-col>
        </b-row>
      </div>
      <loading-gif :loading-name="loadingName"></loading-gif>
      <div v-if="editMode">
        <note-editor
          :note="selectedNote"
          @note-added="onNoteAdded"
          @note-saved="onNoteSaved"
          @cancelled="onEditCancelled()"
        >
        </note-editor>
      </div>
      <div v-else>
        <note-items
          :items="notes"
          v-if="!isLoading(loadingName)"
          @item-pin="onItemPin($event)"
          @item-delete="onItemDelete($event)"
          @item-edit="onEditNote($event)"
          @update="refresh($event)"
          :view-body="viewBody"
        ></note-items>
      </div>
    </div>
    <confirm-modal
      name="delete-the-note"
      title="Suppression d'une note"
      :text="confirmDeleteText"
      :object="selectedNote"
      @confirmed="onDeleteNote"
    >
    </confirm-modal>
    <confirm-modal
      name="pin-the-note"
      icon="fa fa-map-pin"
      :title="pinNoteTitle"
      :text="pinNoteText"
      :object="selectedNote"
      @confirmed="onPinNote"
    >
    </confirm-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import PageHeader from '@/components/Layout/PageHeader'
import LoadingGif from '@/components/Controls/LoadingGif'
import CounterLabel from '@/components/Controls/CounterLabel'
import Pagination from '@/components/Controls/Pagination'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import NoteItems from '@/components/Notes/NoteItems.vue'
import NoteEditor from '@/components/Notes/NoteEditor.vue'
import UnreadNotesCounters from '@/components/Notes/UnreadNotesCounters.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeNote } from '@/types/notes'

export default {
  name: 'notes',
  mixins: [BackendMixin],
  components: {
    UnreadNotesCounters,
    NoteEditor,
    PageHeader,
    LoadingGif,
    NoteItems,
    CounterLabel,
    Pagination,
    ConfirmModal,
  },
  props: {
  },
  data() {
    return {
      loadingName: 'notes-list',
      notes: [],
      page: 1,
      notesCount: 0,
      hasNext: false,
      hasPrevious: false,
      selectedNote: null,
      includeArchives: false,
      viewBody: false,
      editMode: false,
      searchText: '',
      pinned: false,
      unread: false,
      selectedUser: null,
    }
  },
  computed: {
    pinNoteTitle() {
      if (this.selectedNote) {
        if (this.selectedNote.isPinned) {
          return 'Ne plus épingler la note'
        } else {
          return 'Épingler la note'
        }
      }
      return ''
    },
    pinNoteText() {
      if (this.selectedNote) {
        if (this.selectedNote.isPinned) {
          return (
            '"' + this.selectedNote.title + '"\n\n' +
            'Voulez-vous désépingler cette note de votre page d\'accueil?'
          )
        } else {
          return (
            '"' + this.selectedNote.title + '"\n\n' +
            'Voulez-vous épingler cette note de votre page d\'accueil?'
          )
        }
      }
      return ''
    },
    pagesCount() {
      return Math.ceil(this.notesCount / 100)
    },
    paginationSelector() {
      return ''
    },
    canViewNotes() {
      return this.hasPerm('notes.view_note')
    },
    canDeleteNote() {
      return this.hasPerm('notes.delete_note')
    },
    confirmDeleteText() {
      if (this.selectedNote) {
        if (this.selectedNote.archived) {
          return 'La note ' + this.selectedNote.title + ' sera définitivement supprimé de millibase'
        } else {
          return 'La note ' + this.selectedNote.title + ' sera archivée'
        }
      }
      return ''
    },
  },
  watch: {
    includeArchives: function() {
      this.loadNotes(this.page, this.searchText)
    },
    pinned: function() {
      this.loadNotes(this.page, this.searchText)
    },
    unread: function() {
      this.loadNotes(this.page, this.searchText)
    },
  },
  mounted() {
    this.loadNotes(1)
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    onPageChanged(event) {
      this.page = event.page
      this.loadNotes(this.page)
    },
    async loadNotes(page, search) {
      let url = '/notes/api/notes/?page=' + page
      if (this.includeArchives) {
        url += '&include_archives=1'
      }
      if (search) {
        url += '&search=' + search
      }
      if (this.pinned) {
        url += '&pinned=1'
      }
      if (this.unread) {
        url += '&unread=1'
      }
      if (this.selectedUser) {
        url += '&unread_by=' + this.selectedUser.id
      }
      this.startLoading(this.loadingName)
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.notesCount = resp.data.count
        this.hasNext = !!resp.data.next
        this.hasPrevious = !!resp.data.previous
        this.notes = resp.data.results.map(elt => makeNote(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    onNoteAdded(note) {
      this.editMode = false
      this.notes = [note].concat(this.notes)
    },
    async refresh(event) {
      const index = this.notes.map(elt => elt.id).indexOf(event.note.id)
      if (index >= 0) {
        this.notes[index] = event.note
        this.notes = [].concat(this.notes)
      }
    },
    onItemDelete(event) {
      this.selectedNote = event
      this.$bvModal.show('bv-confirm-modal:delete-the-note')
    },
    async onDeleteNote() {
      if (this.selectedNote && this.canDeleteNote) {
        this.startLoading(this.loadingName)
        let url = '/notes/api/notes/' + this.selectedNote.id + '/'
        if (this.selectedNote.archived) {
          url += '?include_archives=1'
        }
        const backendApi = new BackendApi('delete', url)
        try {
          const resp = await backendApi.callApi()
          await this.loadNotes(this.page)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.selectedNote = null
        this.endLoading(this.loadingName)
      }
    },
    onItemPin(event) {
      this.selectedNote = event
      this.$bvModal.show('bv-confirm-modal:pin-the-note')
    },
    async onPinNote() {
      if (this.selectedNote) {
        this.startLoading(this.loadingName)
        let url = '/notes/api/pin-note/' + this.selectedNote.id + '/'
        let backendApi = new BackendApi('post', url)
        try {
          const resp = await backendApi.callApi({ 'pin_note': !this.selectedNote.isPinned, })
          this.refresh(
            {
              note: makeNote(resp.data),
            }
          )
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.selectedNote = null
        this.endLoading(this.loadingName)
      }
    },
    onEditNote(note) {
      this.selectedNote = note
      this.editMode = true
    },
    onNoteSaved(editedNote) {
      const index = this.notes.map(elt => elt.id).indexOf(editedNote.id)
      if (index >= 0) {
        this.notes[index] = editedNote
      }
      this.notes = [].concat(this.notes)
      this.editMode = false
      this.selectedNote = null
    },
    onEditCancelled() {
      this.editMode = false
      this.selectedNote = null
    },
    onSearchNotes() {
      this.loadNotes(1, this.searchText)
    },
    onUserSelected(event) {
      this.selectedUser = event.user
      this.loadNotes(1, this.searchText)
    },
  },
}
</script>

<style lang="less">
.filter-bar {
  background: #f0f0f0;
  padding: 5px;
}
</style>
