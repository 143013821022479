<template>
  <div class="invoices" v-if="hasPerm('payments.view_invoice')">
    <page-header title="Suivi comptable" icon="fa fa-file-invoice"></page-header>
    <b-row>
      <b-col>
        <div>
          <loading-gif :loading-name="loadingName"></loading-gif>
          <ul class="app-menu" v-if="!isLoading(loadingName) && loaded">
            <li>
              <b-row>
                <b-col cols="9">
                  <sales-summary></sales-summary>
                </b-col>
                <b-col cols="3">
                  <search-invoice></search-invoice>
                </b-col>
              </b-row>
            </li>
            <li v-if="hasPerm('payments.add_invoice')">
              <router-link :to="{ name: 'invoices-to-do', }">
               <i class="fa fa-calculator"></i>
               À facturer: <counter-label :counter="toDoCount" label="famille"></counter-label>
              </router-link>
            </li>
            <li v-if="hasInvoicing">
              <i class="fa fa-receipt"></i> Facturation:
              <span class="invoicing-link" v-if="hasAllPerms(['payments.add_invoice', 'youth.view_youthhomeactivity'])">
                <router-link :to="{ name: 'youth-invoicing', }">
                  {{youthHomeLabel}}
                </router-link>
              </span>
              <span class="invoicing-link" v-if="hasAllPerms(['payments.add_invoice', 'adhesions.view_adhesion'])">
                <router-link :to="{ name: 'adhesions-invoicing', }">
                  Adhésions
                </router-link>
              </span>
              <span class="invoicing-link" v-if="mainActivityInvoicing">
                <router-link :to="{ name: 'activities-invoicing', }">
                  Activités
                </router-link>
              </span>
              <span
                v-for="menu of activityMenus"
                :key="menu.id"
                class="invoicing-link"
              >
                <router-link
                  :to="{ name: 'activities-invoicing', query: { category: menu.category, }, }"
                >
                  {{ menu.label }}
                </router-link>
              </span>
            </li>
            <li>
              <router-link :to="{ name: 'invoices-to-pay', }">
                <i class="fa fa-file-invoice"></i>
                À payer: <counter-label :counter="unpaidCount" label="facture"></counter-label>
              </router-link>
            </li>
            <li v-if="hasPerm('payments.view_analyticaccount')">
              <router-link :to="{ name: 'unpaid-analytics', }">
                <i class="fa fa-file-invoice"></i> Synthèse des impayés
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'invoices-paid', }">
                <i class="fa fa-file-invoice"></i>
                Factures payées: <counter-label :counter="paidCount" label="facture"></counter-label>
              </router-link>
            </li>
            <li v-if="hasPerm('payments.view_payment')">
              <router-link :to="{ name: 'payments', }">
                <i class="fa fa-credit-card"></i> Paiements
              </router-link>
            </li>
            <li  v-if="hasPerm('payments.view_deposit')">
              <router-link :to="{ name: 'deposits', }">
                <i class="fas fa-money-bill-alt"></i> Dépôts
              </router-link>
            </li>
            <li v-if="hasPerm('payments.view_credit')">
              <router-link :to="{ name: 'credits', }">
                <i class="fa fa-hand-holding-usd"></i> Avoirs et remboursements
              </router-link>
            </li>
            <li v-if="hasPerm('payments.view_contribution')">
              <router-link :to="{ name: 'contributions', }">
                <i class="fa fa-list-numeric"></i> Extrait de compte
              </router-link>
            </li>
            <li v-if="hasPerm('cash.view_cashlog')">
              <router-link :to="{ name: 'cash-logs', }">
                <i class="fa fa-piggy-bank"></i> Caisse
              </router-link>
            </li>
            <li v-if="hasPerm('payments.view_analyticaccount')">
              <router-link :to="{ name: 'analytic-accounting', }">
                <i class="fa fa-exchange-alt"></i> Comptabilité Analytique
              </router-link>
            </li>
            <li v-if="hasPerm('payments.view_analyticaccount')">
              <router-link :to="{ name: 'analytic-accounting-credits', }">
                <i class="fa fa fa-magnifying-glass-chart"></i> Répartition analytique des avoirs
              </router-link>
            </li>
            <li v-if="hasPerm('payments.view_sale')">
              <router-link :to="{ name: 'stats-monthly-sales', }">
                <i class="fas fa-chart-pie"></i> État des ventes
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'invoices-exports', }">
                <i class="fa fa-file-excel"></i> Exports
              </router-link>
            </li>
            <pages-list apply-to="invoices"></pages-list>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import SalesSummary from '@/components/Invoices/SalesSummary'
import SearchInvoice from '@/components/Invoices/SearchInvoice'
import PageHeader from '@/components/Layout/PageHeader'
import PagesList from '@/components/Pages/PagesList'
import store from '@/store'
import { BackendApi } from '@/utils/http'

export default {
  name: 'invoices',
  mixins: [BackendMixin],
  components: {
    SearchInvoice,
    CounterLabel,
    SalesSummary,
    LoadingGif,
    PageHeader,
    PagesList,
  },
  data() {
    return {
      loadingName: 'invoices',
      toDoCount: 0,
      paidCount: 0,
      unpaidCount: 0,
      loaded: false,
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    hasInvoicing() {
      if (this.hasAllPerms(['payments.add_invoice', 'youth.view_youthhomeactivity'])) {
        return true
      }
      if (this.hasAllPerms(['payments.add_invoice', 'adhesions.view_adhesion'])) {
        return true
      }
      if (this.hasAllPerms(['payments.add_invoice', 'activities.view_coreactivity'])) {
        return true
      }
      return false
    },
    mainActivityInvoicing() {
      if (this.hasAllPerms(['payments.add_invoice', 'activities.view_coreactivity'])) {
        return store.getters.config.activityMenus.length === 0
      }
      return false
    },
    activityMenus() {
      if (this.hasAllPerms(['payments.add_invoice', 'activities.view_coreactivity'])) {
        return store.getters.config.activityMenus
      }
      return []
    },
  },
  mounted() {
    this.onLoaded()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    onLoaded() {
      this.loadSynthesis()
    },
    async loadSynthesis() {
      this.startLoading(this.loadingName)
      let url = '/api/invoices-synthesis/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.unpaidCount = resp.data.unpaid_invoices
        this.paidCount = resp.data.paid_invoices
        this.toDoCount = resp.data.todo_sales
        this.loaded = true
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
  },
}
</script>

<style lang="less" scoped>
.invoicing-link {
  font-size: 14px;
  padding: 4px 10px;
  background: #eee;
  margin: 0 5px;
  border-radius: 4px;
}
</style>
