
export class LogBookColumn {
  constructor(
    public id: number,
    public name: string,
    public isNumeric: boolean
  ) {
  }
}

export class LogBook {
  constructor(
    public id: number,
    public name: string,
    public columns: LogBookColumn[]
  ) {
  }
}

export function makeLogBookColumn(jsonData: any = null): LogBookColumn {
  if (!jsonData) {
    jsonData = {}
  }
  return new LogBookColumn(
    jsonData.id || 0,
    jsonData.name || '',
    !!jsonData.is_numeric
  )
}

export function makeLogBook(jsonData: any = null): LogBook {
  if (!jsonData) {
    jsonData = {}
  }
  const columns = jsonData.columns || []
  return new LogBook(
    jsonData.id || 0,
    jsonData.name || '',
    columns.map(makeLogBookColumn)
  )
}

export class LogBookValue {
  constructor(
    public id: number,
    public column: number,
    public value: string
  ) {
  }
}

export class LogBookItem {
  constructor(
    public id: number,
    public createdOn: Date,
    public createdBy: string,
    public text: string,
    public values: LogBookValue[],
    public keywords: number[]
  ) {
  }
}

export function makeLogBookValue(jsonData: any = null): LogBookValue {
  if (!jsonData) {
    jsonData = {}
  }
  return new LogBookValue(
    jsonData.id || 0,
    jsonData.column || 0,
    jsonData.value || ''
  )
}

export function makeLogBookItem(jsonData: any = null): LogBookItem {
  if (!jsonData) {
    jsonData = {}
  }
  const values = jsonData.values || []
  const keywords = jsonData.keywords || []
  return new LogBookItem(
    jsonData.id || 0,
    new Date(jsonData.created_on),
    jsonData.created_by || '',
    jsonData.text || '',
    values.map(makeLogBookValue),
    keywords
  )
}

export class LogbookKeyword {
  constructor(
    public id: number,
    public name: string,
    public backgroundColor: string,
    public textColor: string
  ) {
  }

  public getStyle(): any {
    const style: any = { padding: '2px', }
    if (this.backgroundColor) {
      style.background = this.backgroundColor
    }
    if (this.textColor) {
      style.color = this.textColor
    }
    return style
  }

  public getStyleText(): string {
    let style: string = ''
    if (this.backgroundColor) {
      style += 'background: ' + this.backgroundColor + '; '
    }
    if (this.textColor) {
      style += 'color: ' + this.textColor + '; '
    }
    if (style) {
      return 'style="' + style + '"'
    }
    return style
  }
}

export function makeLogbookKeyword(jsonData: any = null): LogbookKeyword {
  if (!jsonData) {
    jsonData = {}
  }
  return new LogbookKeyword(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.background_color || '',
    jsonData.text_color || ''
  )
}
