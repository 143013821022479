<template>
  <div class="logbooks-button" v-if="isAuthenticated && hasPerm('log_books.view_logbook') && logBooks > 0">
    <a href @click.prevent="showLogBooks()">
      <i
        class="fa fa-ship logbooks-icon right-bar-icon"
        :title="(logBooks === 1) ? 'Journal de bord' : 'Journaux de bord'"
        v-b-tooltip.left
      ></i>
    </a>
  </div>
</template>hasLogBooks

<script>
import router from '@/router'
import store from '@/store'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'logbooks-button',
  components: {},
  mixins: [BackendMixin],
  data() {
    return {
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated
    },
    logBooks() {
      return store.getters.config.logBooks
    },
  },
  methods: {
    showLogBooks() {
      router.push({ 'name': 'logbooks', })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.logbooks-button {
  .logbooks-icon {
    top: 340px;
  }
}
</style>
